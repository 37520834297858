import { Add, DeleteRounded, EditNoteRounded } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import { CommonPlayListAddIcon } from "../../Common/IconsAndComps";
import ToastMessage from "../../Common/Components/ToastMessage";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";
// import Switch from '@mui/material/Switch';

export const Combination = () => {
  const userId = parseInt(sessionStorage.getItem("userId"));
  const [activity, setActivity] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showIsPublic, setShowIsPublic] = useState("");
  const [filterOthers, setFilterOthers] = useState("All");
  const [filterByOwn, setFilterByOwn] = useState("All");
  const { toast, contextHolder } = ToastMessage();
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const handlePopupOpen = (index) => {
    setSelectedIndex(index);
    setPopupOpen(true);
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const navigate = useNavigate();

  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  async function handleCombinationActivityAdd() {
    try {
      const response = await api.get(
        `/warmUpCombination/createdBy/isPublic/${userId}`
      );
      setTableData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error", error);
    }
  }

  async function handleCombinationActivityDelete() {
    try {
      toast("loading", "Processing...");
      await api.delete(`/warmUpCombination/${selectedIndex}`);
      handlePopupClose();
      toast("success", "Combination Deleted Successfully");
      handleCombinationActivityAdd();
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function getActivity() {
    try {
      toast("loading", "Loading...");

      const response = await api.get(`/activity/createdBy/isPublic/${userId}`);
      setActivity(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }

  async function toggleCombinationStatus(id, currentStatus) {
    try {
      toast("loading", "Processing...");
    
      const detail = await api.get(`/warmUpCombination/${id}`);

      const activityId = detail.data.activityId

      const activityDetail = await api.get(`/activity/${activityId}`);

      const newStatus = !currentStatus;
     
      if(activityDetail.data.isPublic === false && detail.data.isPublic === false){
        toast('warning', 'You cannot change combination exercise in public when activity is in private')
        return
      }

      if (detail.data.warmUpCombinationId && (activityDetail.data.isPublic !== false || detail.data.isPublic !== false)) {
        const payload = {
          isPublic: newStatus,
          warmUpCombinationName: detail.data.warmUpCombinationName,
          activityId: detail.data.activityId,
          academyId: sessionStorage.getItem("academyId"),
          createdBy: detail.data.createdBy,
          createdDate: getCurrentSQLDate(),
          modifiedBy: sessionStorage.getItem("userId"),
          modifiedDate: getCurrentSQLDate(),
        };

        const response = await api.put(`/warmUpCombination/${id}`, payload);
        console.log('output',response);
        toast("success", "Combination Status Changed");
        handleCombinationActivityAdd();
      }
    } catch (error) {
      console.error("Error during API put:", error);
    }
  }

  async function handleOptionClick(option) {
    setFilterOthers(option);
    try {
      const response = await api.get(
        `/warmUpCombination/createdBy/isPublic/${userId}`
      );
      const data = response.data;
      if (option === "All") {
        setFilterByOwn("All");
        setTableData(data);
      } else if (option === "ByMe") {
        const sameUserId = data.filter(
          (item) => parseInt(item.createdBy) === userId
        );
        console.log(sameUserId);
        setTableData(sameUserId);
      } else if (option === "ByOthers") {
        const diffUserId = data.filter(
          (item) => parseInt(item.createdBy) !== userId
        );
        setTableData([...diffUserId]);
      } else {
        return null;
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  }

  const handleNavigate = () => {
    navigate("/CombinationAdd");
  };

  const rowsData = tableData.filter(
    (data) =>
      filterByOwn === "All" ||
      (filterByOwn === "Private" ? !data?.isPublic : data?.isPublic)
  );
  const FilterRows = activity.flatMap((user) => 
    rowsData.filter((row) => (row.activityId === user.activityId))
  );

  useEffect(() => {
    getActivity();
    handleCombinationActivityAdd();
  }, []);

  const handleEditIcon = async (item, value) => {

    if (!value) {
      const noOfViews =
        Number.isNaN(item.noOfViews) ||
        item.noOfViews == null ||
        !item.noOfViews
          ? 1
          : item.noOfViews + 1;

      const data = {
        ...item,
        noOfViews: noOfViews,
      };
      await api
        .put(`/warmUpCombination/${item.warmUpCombinationId}`, data)
        .then((res) => console.log(res.data))
        .catch((err) => console.log(err));
    }
  };

  const rows = FilterRows.map((item, index) => {
    const Active = activity.find(
      (act) => parseInt(act.activityId) === parseInt(item.activityId)
    );
    return {
      id: index + 1,
      createdBy:
        parseInt(item?.createdBy) === parseInt(userId)
          ? "Created by Me"
          : "Created by Others",
      combination: item.warmUpCombinationName,
      activity: Active?.activityName || "--",
      status: (
        <Button
          variant="contained"
          sx={{
            borderRadius: "10px",
            backgroundColor: item.isPublic ? "#c0dcf8" : "#a8a4e6",
            color: "#000000",
            fontSize: "var(--font-size)",
            fontFamily: "var(--font-family)",
            width: "70%",
            height: "40px",
          }}
          disabled={parseInt(item.createdBy) !== parseInt(userId)}
          // color={item.isPublic ? "info" : "secondary"}
          className={item.isPublic ? "collaborate" : "private"}
          onClick={() =>
            toggleCombinationStatus(item.warmUpCombinationId, item.isPublic)
          }
        >
          {item.isPublic ? "Collaborate" : "Private"}
        </Button>
      ),
      action: (
        <Box>
          <Link
            to={{
              pathname: `/CombinationEdit/${item.warmUpCombinationId}`,
            }}
          >
            <EditNoteRounded
              onClick={() =>
                handleEditIcon(
                  item,
                  parseInt(item.createdBy) === parseInt(userId)
                )
              }
              color="#5EE9F5"
              className="icon-button-style"
            />
          </Link>
          {parseInt(item.createdBy) === parseInt(userId) ? (
            <DeleteRounded
              className="icon-button-style"
              onClick={() => handlePopupOpen(item.warmUpCombinationId)}
              color="error"
            />
          ) : (
            <DeleteRounded
              sx={{ color: "var(--disable-del-button)" }}
              disabled={true}
            />
          )}
        </Box>
      ),
    };
  });

  const FILTER_ARRAY = [
    { name: "By Me", value: "ByMe" },
    { name: "By Others", value: "ByOthers" },
  ];

  const FILTER_BY_ME_ARRAY = [
    { name: "Private", value: "Private" },
    { name: "Collaborate", value: "Collaborate" },
  ];

  return (
    <Box className="common-wrapper">
      {contextHolder}
      <CommonAlertPopup
        handleYes={handleCombinationActivityDelete}
        handleNo={handlePopupClose}
        titleText={<p>Confirmation</p>}
        contentText={<p>Are you sure you want to delete the Combination?</p>}
        open={popupOpen}
        handleClose={handlePopupClose}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Yes"}
      />
      <Grid container spacing={3} columns={{ sm: 1, md: 3, lg: 3, xl: 3 }}>
        <Grid
          item
          xs={1}
          md={0.5}
          lg={0.5}
          xl={0.5}
          sx={{ display: "flex", alignItems: "end" }}
        >
          <CustomCommonButton
            onClick={handleNavigate}
            text={"Add Plan"}
            icon={CommonPlayListAddIcon}
          />
        </Grid>

        <Grid item xs={1} md={1} lg={1} xl={1}>
          <CommonDropdown
            firstOption={"All"}
            search={true}
            label={"Filter by Created By"}
            value={filterOthers}
            onChange={(e) => handleOptionClick(e.target.value)}
            array={FILTER_ARRAY}
            menuValue={"value"}
            display={"name"}
          />
        </Grid>

        {filterOthers === "ByMe" && (
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <CommonDropdown
              firstOption={"All"}
              search={true}
              label={"Filter by Created By"}
              value={filterByOwn}
              onChange={(e) => setFilterByOwn(e.target.value)}
              array={FILTER_BY_ME_ARRAY}
              menuValue={"value"}
              display={"name"}
            />
          </Grid>
        )}
      </Grid>
      <GridTable rows={rows} columns={GetData.combination} />
    </Box>
  );
};
