import {
  CalendarMonth,
  DeleteRounded,
  Download,
  MoreHorizTwoTone,
  SettingsAccessibility,
} from "@mui/icons-material";
import { Box, Grid, Popover, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Link, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import ToastMessage from "../../Common/Components/ToastMessage";
import { RadioBtnFilter } from "../../Common/Components/dateFilter/RadioBtnFilter";
import Extension from "../../Common/Extension";
import { CommonPlayListAddIcon } from "../../Common/IconsAndComps";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import { GridTable } from "../common/GridTable";
import logo from "../../assets/logo.png";

export const Preparation = () => {
  const userId = sessionStorage.getItem("userId");
  const [preparation, setPreparation] = useState([]);
  const [workoutHeaders, setWorkoutHeaders] = useState([]);
  const [player, setPlayer] = useState([]);
  const [name, setName] = useState("All");
  const [changes, setChanges] = useState(true);
  const [selectedOption, setSelectedOption] = useState("all");
  const { toast, contextHolder } = ToastMessage();

  const navigate = useNavigate();

  const fetchPlayerInfo = useCallback(async () => {
    try {
      const res = await api.get(`/users/coachId/${userId}`);
      return res.data; // Return the player details
    } catch (err) {
      console.log(err);
      return []; // Return an empty array in case of an error
    }
  }, [userId]);

  const [players, setPlayers] = useState([]);

  const getAllPlayers = async () => {
    await api
      .get(`users/all`)
      .then((res) => setPlayers(res.data))
      .catch((err) => {});
  };

  const handleDelete = async (id) => {
    try {
      toast("loading", "Processing...");
      await api.delete(`/warmUpWorkoutHeader/${id}`);
      // Filter out the deleted header from the state
      setPreparation((prevPreparation) =>
        prevPreparation.filter((item) => item.warmUpWorkoutHeaderId !== id)
      );
      // Also filter out the deleted details
      setWorkoutHeaders((prevWorkoutHeaders) =>
        prevWorkoutHeaders.filter((item) => item.warmUpWorkoutHeaderId !== id)
      );
      setChanges(!changes); // Trigger a re-render if necessary
      toast("success", "Exercise Deleted Successfully");
      handlePopupClose();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const handlePopupOpen = (index) => {
    setSelectedIndex(index);
    setPopupOpen(true);
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handleDeleteClick = (idList) => {
    handleDelete(selectedIndex);
  };

  // ------------------ Date Filter Start -----------------------

  const today = new Date();

  const currentDay = format(today, "yyyy-MM-dd");

  const monthly = format(
    new Date(today.getFullYear(), today.getMonth(), 1),
    "yyyy-MM-dd"
  );

  const yearly = format(new Date(today.getFullYear(), 0, 1), "yyyy-MM-dd");
  const weekly = format(
    new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 1
    ),
    "yyyy-MM-dd"
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [period, setPeriod] = useState("all");

  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(monthly),
    endDate: new Date(),
    key: "selection",
  });
  // console.log('selectedRange',selectedRange);
  const handleDateRangeChange = (dateRange) => {
    setSelectedRange(dateRange.selection);
    setPeriod("custom");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        toast("loading", "Loading...");
        const playersData = await fetchPlayerInfo();
        console.log(playersData);
        await getAllPlayers();

        // Update the player state with the fetched data
        setPlayer(playersData);

        // Fetch header list
        const headerListResponse =
          format(selectedRange.startDate, "yyyy-MM-dd") === "0000-00-00" ||
          !format(selectedRange.startDate, "yyyy-MM-dd")
            ? await api.get(`/warmUpWorkoutHeader/coachId/${userId}`)
            : await api.get(
                `warmUpWorkoutHeader/player/${userId}/${format(
                  selectedRange.startDate,
                  "yyyy-MM-dd"
                )}/${format(selectedRange.endDate, "yyyy-MM-dd")}/all`
              );

        const headerList = headerListResponse.data;
        setPreparation(headerList);
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
  }, [fetchPlayerInfo, userId, selectedRange.endDate, selectedRange.startDate]);

  useEffect(() => {
    console.log(period);
    if (period === "monthly") {
      setSelectedRange({
        startDate: new Date(monthly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "weekly") {
      setSelectedRange({
        startDate: new Date(weekly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "yearly") {
      setSelectedRange({
        startDate: new Date(yearly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "today") {
      setSelectedRange({
        startDate: new Date(currentDay),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "all") {
      setSelectedRange({
        startDate: new Date("2010-01-01"),
        endDate: new Date(currentDay),
        key: "selection",
      });
    }
  }, [currentDay, monthly, period, weekly, yearly]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // ------------------ Date Filter End -----------------------

  const handleDateFilterChange = (option) => {
    setSelectedOption(option);
  };

  const handlePlayer = (event) => {
    const playerId = event.target.value;
    setName(playerId);
  };

  const getWorkoutHeaders = useCallback(async () => {
    const detail = await api
      .get(`/warmUpWorkoutDetailsController/all`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setWorkoutHeaders(detail);
  }, []);

  useEffect(() => {
    getWorkoutHeaders();
  }, [getWorkoutHeaders]);

  const getPercentage = (id) => {
    const filteredHeaders = workoutHeaders.filter(
      (item) => item.warmUpWorkoutHeaderId === id
    );

    const actualTotal = filteredHeaders.reduce((total, data) => {
      return total + data.actualSet;
    }, 0);

    const assignTotal = filteredHeaders.reduce((total, data) => {
      return total + data.assignSet;
    }, 0);

    const percentage = (actualTotal / assignTotal) * 100;

    return {
      numeric: Number.isNaN(percentage) ? 0 : percentage,
      element: (
        <span
          style={{
            color:
              percentage >= 75
                ? "green"
                : percentage >= 50
                ? "olive"
                : percentage >= 25
                ? "orange"
                : "red",
          }}
        >
          {Number.isNaN(percentage) ? 0 : percentage.toFixed(0)}%
        </span>
      ),
    };
  };

  const filterData = preparation.filter(
    (item) => name === "All" || parseInt(name) === parseInt(item.playerId)
  );

  const rows = filterData.map((item, index) => {
    const Player = players.find(
      (data) => parseInt(data.userId) === parseInt(item.playerId)
    );
    const { numeric: percentageValue, element: percentageElement } =
      getPercentage(item.warmUpWorkoutHeaderId);

    return {
      ...item,
      id: index + 1,
      playerName: Player?.userName || "--",
      createdDate: Extension.convertDateFormatToDMY(item.createdDate),
      startTime: item.startTime || "--",
      endTime: item.endTime || "--",
      percentage: percentageElement,
      percentageValue: percentageValue,
      action: (
        <Box sx={{ display: "flex", gap: "15px" }}>
          <Link
            to={{
              pathname: `/preparationView/${item.warmUpWorkoutHeaderId}`,
            }}
          >
            <MoreHorizTwoTone />
          </Link>
          <DeleteRounded
            color="error"
            onClick={() => handlePopupOpen(item.warmUpWorkoutHeaderId)}
          />
        </Box>
      ),
    };
  });

  const hundredPercentRows = rows.filter((row) => row.percentageValue === 100);
  const numberOfHundredPercentRows = hundredPercentRows.length;

  const handleNavigate = () => {
    navigate("/assignPreparation");
  };

  useEffect(() => {
    handleDateFilterChange("all");
  }, []);

  // ------------------ Report Generate Start -----------------------

  const componentPdf = useRef();

  const [isVisible, setIsVisible] = useState(false);

  const handleBeforePrint = () => {
    setIsVisible(true);
  };

  const handleAfterPrint = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (isVisible) {
      generateInvoice();
    }
  }, [isVisible]);

  const generateInvoice = useReactToPrint({
    content: () => componentPdf.current,
    documentTitle: "Performance Data",
    onAfterPrint: handleAfterPrint,
  });

  const handlePrint = () => {
    handleBeforePrint();
    setTimeout(() => {
      generateInvoice();
    }, 100);
  };

  const [pdfPlayer, setPdfPlayer] = useState({});
  useEffect(() => {
    if (name !== "all") {
      const pdfData = player.find((user) => user.userId === name);
      setPdfPlayer(pdfData);
    }
  }, [name]);

  // ------------------ Report Generate End -----------------------

  const coachName = sessionStorage.getItem("userName");

  return (
    <Box className="common-wrapper">
      {contextHolder}
      <CommonAlertPopup
        handleYes={handleDeleteClick}
        handleNo={handlePopupClose}
        titleText={<p>Confirmation</p>}
        contentText={<p>Are you sure you want to delete the Exercise?</p>}
        open={popupOpen}
        handleClose={handlePopupClose}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Yes"}
      />
      <Grid container spacing={3} columns={{ sm: 1, md: 5, lg: 5, xl: 5 }}>
        <Grid item xs={1} md={2} lg={1} xl={1}>
          <CustomCommonButton
            onClick={handleNavigate}
            tooltipText="Assign Preparation"
            icon={CommonPlayListAddIcon}
          />
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          lg={2}
          xl={2}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
          }}
        >
          <RadioBtnFilter handleRadioChange={(data) => setPeriod(data)} />
          <Box sx={{ ml: 2 }}>
            <div role="button" onClick={handleClick} className="icon-button">
              <CalendarMonth />
            </div>
            {/* <CustomDateRangePicker /> */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DateRange
                editableDateInputs
                onChange={handleDateRangeChange}
                moveRangeOnFirstSelection={false}
                ranges={[selectedRange]}
              />
            </Popover>
          </Box>
        </Grid>
        <Grid item xs={1} md={1} lg={1} xl={1}>
          <CommonDropdown
            firstOption={"All"}
            search={true}
            label={"Filter by Player"}
            value={name}
            onChange={handlePlayer}
            array={player}
            menuValue={"userId"}
            display={"userName"}
          />
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          lg={1}
          xl={1}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Box sx={{ position: "relative", height: "100%" }}>
            <CustomCommonButton
              tooltipText="Preparation Report"
              icon={<SettingsAccessibility sx={{ opacity: 0.5 }} />}
              onClick={handlePrint}
            />
            <Box sx={{ position: "absolute", bottom: "-5px", right: "3px" }}>
              <Download
                sx={{
                  backgroundColor: "unset",
                  color: "white",
                  opacity: 0.5,
                  fontSize: "18px",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ mb: 10 }}>
        <GridTable rows={rows} columns={GetData.preparation.columns} />
      </Box>
      <div
        ref={componentPdf}
        style={{
          display: isVisible ? "block" : "none",
        }}
      >
        <Box sx={{ marginTop: "15px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexDirection: "row",
              fontSize: "var(--default-a4-font-size)",
            }}
          >
            <img
              src={logo}
              style={{
                width: "var(--default-a4-font-size)",
                height: "var(--default-a4-font-size)",
                marginRight: "8px",
                width: "40px",
                height: "40px",
              }}
              alt="logo"
            />{" "}
            <Typography
              sx={{
                // fontSize: "var(--default-a4-font-size)",
                display: "flex",
                alignItems: "center",
                marginTop: "2px",
                fontSize: "var(--default-a4-header-font-size)",
              }}
            >
              XI Performer
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {name !== "All" ? (
              <>
                <Grid item xs={6} sm={12} md={12} lg={4}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "var(--font-family)",
                        textDecoration: "underline",
                        fontSize: "var(--default-a4-header-font-size)",
                      }}
                    >
                      Player Info{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Player Name: &nbsp;{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {" "}
                      {pdfPlayer !== undefined ? pdfPlayer.userName : ""}{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Player ID: &nbsp;{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {" "}
                      {pdfPlayer !== undefined ? pdfPlayer.loginId : ""}{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Date Joined: &nbsp;{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {" "}
                      {pdfPlayer !== undefined
                        ? new Date(pdfPlayer.createDate).toLocaleString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            }
                          )
                        : ""}{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Coach Name: &nbsp;{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {" "}
                      {coachName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ borderBottom: "1px solid #dddddd" }}
                ></Grid>
              </>
            ) : (
              ""
            )}
            <Grid item xs={6} sm={12} md={12} lg={4}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "var(--font-family)",
                    textDecoration: "underline",
                    fontSize: "var(--default-a4-header-font-size)",
                  }}
                >
                  Preparation Data{" "}
                </Typography>
              </Box>
            </Grid>
            {/* <Box sx={{ display: "flex",flexDirection:'column' }}> */}
            <Grid item xs={6} sm={12} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date Generated: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {format(selectedRange.startDate, "dd-MMM-yyyy")} to{" "}
                  {format(selectedRange.endDate, "dd-MMM-yyyy")}{" "}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={6} sm={6} md={4} lg={4}>
             <Box sx={{ display: "flex" }}>
               <Typography
                 variant="h6"
                 sx={{ fontFamily: "var(--font-family)" }}
               >
                 Date Exiting :
               </Typography>
               <Typography
                 variant="h6"
                 sx={{ fontFamily: "var(--font-family)" }}
               >
                 {" "}
               </Typography>
             </Box>
           </Grid> */}
            <Grid item xs={6} sm={6} md={12} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  No. of preparation assigned: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {rows.length}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  No. of preparation done: &nbsp;{" "}
                </Typography>

                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {numberOfHundredPercentRows}
                </Typography>
              </Box>
            </Grid>
            {/* </Box> */}

            <Grid
              item
              xs={6}
              sm={12}
              md={4}
              lg={4}
              sx={{ borderBottom: "1px solid #dddddd" }}
            ></Grid>
          </Grid>
          <table style={{ width: "100%", marginTop: "10px" }}>
            <thead>
              <tr style={{ fontFamily: "var(--font-family)" }}>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  S.No
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Session
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  % of completed exercise
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Time taken to complete the exercise
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.length > 0 ? (
                rows.map((row) => {
                  const parseTimeString = (timeString) => {
                    if (!timeString || timeString === "--") return null;
                    const [time, modifier] = timeString.split(" ");
                    let [hours, minutes] = time.split(":");
                    if (hours === "12") {
                      hours = "00";
                    }
                    if (modifier === "PM") {
                      hours = parseInt(hours, 10) + 12;
                    }
                    const now = new Date();
                    return new Date(
                      now.getFullYear(),
                      now.getMonth(),
                      now.getDate(),
                      hours,
                      minutes
                    );
                  };

                  const startTime = parseTimeString(row.startTime);
                  const endTime = parseTimeString(row.endTime);

                  let timeTaken = "--";
                  if (startTime && endTime) {
                    // Calculate the difference in milliseconds
                    const timeDifferenceInMilliseconds = endTime - startTime;

                    // Calculate hours and minutes
                    const totalSeconds = Math.floor(
                      timeDifferenceInMilliseconds / 1000
                    );
                    const hours = Math.floor(totalSeconds / 3600);
                    const minutes = Math.floor((totalSeconds % 3600) / 60);

                    // Format the time taken string
                    timeTaken = `${hours}h ${minutes}m`;
                  }

                  return (
                    <tr
                      key={row.id}
                      style={{ fontFamily: "var(--font-family)" }}
                    >
                      <td
                        style={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--default-a4-font-size)",
                        }}
                      >
                        {row.id}
                      </td>
                      <td
                        style={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--default-a4-font-size)",
                        }}
                      >
                        {row.createdDate}
                      </td>
                      <td
                        style={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--default-a4-font-size)",
                        }}
                      >
                        {row.session}
                      </td>
                      <td
                        style={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--default-a4-font-size)",
                        }}
                      >
                        {row.percentage}
                      </td>
                      <td
                        style={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--default-a4-font-size)",
                        }}
                      >
                        {timeTaken}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    style={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                    colSpan={5}
                  >
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      </div>
    </Box>
  );
};
