import {
  DeleteRounded,
  EditNoteRounded,
  FilterList,
  Groups,
} from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";

import CancelIcon from "@mui/icons-material/Cancel";
import { TableCell } from "@mui/material";
import Modal from "@mui/material/Modal";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";
import Style from "../../Common/Components/Styles";
import ToastMessage from "../../Common/Components/ToastMessage";

export const PlayerGrouping = () => {
  const userId = sessionStorage.getItem("userId");
  const [groupingFilter, setGroupingFilter] = useState("");
  const [userGroup, setUserGroup] = useState([]);
  const { toast, contextHolder } = ToastMessage();

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [ids] = useState([]);
  const navigate = useNavigate();

  async function fetchData() {
    try {
      toast("loading", "Loading...");
      const response = await api.get("/userGroup/coach/" + userId);
      const userGroups = response.data;

      // Calculate player counts for each user group and add them as a property
      for (const group of userGroups) {
        const groupResponse = await api.get(
          "/groupingUser/userGroup/" + group.userGroupId
        );
        const groupData = groupResponse.data;
        group.playerCount = Array.isArray(groupData) ? groupData.length : 0;
      }

      setUserGroup(userGroups);
    } catch (error) {
      console.error("Error fetching user groups:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [userId]);

  const handleDelete = async () => {
    try {
      toast("loading", "Processing...");
      await api.delete(`/groupingUser/userGroup/${selectedData}`);
      console.log(`User group with ID ${selectedData} deleted successfully.`);
      await api.delete(`/userGroup/${selectedData}`);
      console.log(`User group with name deleted successfully.`);
      const updatedUserGroups = userGroup.filter(
        (group) => group.userGroupId !== parseInt(selectedData)
      );
      setUserGroup(updatedUserGroups);
      setPopupOpen(false);
      toast("success", "Group Deleted Successfully");
    } catch (error) {
      console.error("Error deleting user group:", error);
    }
  };

  const handleNavigate = () => {
    navigate("/Grouping");
  };

  const [open, setOpen] = React.useState(false);
  const [playerValue, setPlayerValue] = useState([]);

  const handlePopupOpen = (index) => {
    setSelectedData(index);
    setPopupOpen(true);
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  async function handleOpen(groupId) {
    setOpen(true);
    const response = await api.get(`/groupingUser/userGroup/${groupId}`);
    const playerIds = response.data.map((item) => item.userId);
    const player = playerIds.map(async (userId) => {
      try {
        const playerResponse = await api.get(`/users/` + userId);
        console.log(playerResponse);
        return playerResponse.data;
      } catch (err) {
        console.error("Error fetching player data:", err);
        return null;
      }
    });
    const playersData = await Promise.all(player);
    const filteredPlayersData = playersData.filter((player) => player !== null);
    setPlayerValue(filteredPlayersData);
    setOpen(true);
  }

  const handleClose = () => setOpen(false);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredRowsPlayer = playerValue.filter((item) =>
    item.userName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const row = filteredRowsPlayer.map((player, index) => {
    return {
      id: player.loginId,
      playerName: player.userName,
    };
  });

  const filteredRows = userGroup.filter(
    (item) =>
      item.groupName.toLowerCase().includes(groupingFilter.toLowerCase()) &&
      item.playerCount >= 0
  );
  const rows = filteredRows.map((item, index) => {
    return {
      id: index + 1,
      group_name: item.groupName
        .split(/\s+/)
        .map((word, i) => window.capitalizeFirstLetter(word, i))
        .join(" "),
      player_count: item.playerCount,
      players: (
        <TableCell align="center" sx={{ border: "none" }}>
          <MoreHorizIcon
            variant="outlined"
            style={{ cursor: "pointer" }}
            onClick={() => handleOpen(item.userGroupId)}
          />
        </TableCell>
      ),
      action: (
        <Box>
          <Link
            to={{
              pathname: `/GroupingEdit/${item.userGroupId}`,
            }}
          >
            <EditNoteRounded color="success" style={{ marginRight: "5px" }} />
          </Link>
          <DeleteRounded
            color="error"
            onClick={() => handlePopupOpen(item.userGroupId)}
          />
        </Box>
      ),
    };
  });

  return (
    <Box className="common-wrapper">
      {contextHolder}
      <CommonAlertPopup
        handleYes={handleDelete}
        handleNo={handlePopupClose}
        titleText={<p>Confirmation</p>}
        contentText={<p>Do you want to delete this Group?</p>}
        open={popupOpen}
        handleClose={handlePopupClose}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Yes"}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "30%" }}>
          <CommonTextField
            label={"Filter by Grouping"}
            value={groupingFilter}
            position="end"
            comboFixWord={<FilterList />}
            name="filter"
            onChange={(e) => setGroupingFilter(e.target.value)}
          />
        </div>
        <Box>
          <CustomCommonButton
            icon={<Groups sx={{ fontSize: "40px" }} />}
            onClick={handleNavigate}
            tooltipText="Add Grouping"
          />
        </Box>
      </div>
      <GridTable rows={rows} columns={GetData.player_grouping} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style.widthPopupStyle}>
          <div style={{ position: "absolute", top: "0px", right: "0px" }}>
            <IconButton
              sx={{ color: "var(--app-bar-color)" }}
              onClick={handleClose}
            >
              <CancelIcon />
            </IconButton>
          </div>
          <Typography
            sx={{
              flex: 1,
              mb: 1,
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
            variant="h6"
            component="div"
          >
            {/* Player's assigned to {post.userName} */}
          </Typography>
          {/* </Toolbar>
        </AppBar> */}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                sx={{
                  fontFamily: "var(--font-family)",
                  display: "flex",
                  alignItems: "center",
                }}
                InputProps={{
                  style: {
                    borderRadius: "40px",
                  },
                }}
                size="small"
                label="Search"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                inputProps={{
                  style: {
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "var(--font-color)", // Add your label style here
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "end" }}>
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  justifyContent: "end",
                  fontFamily: "var(--font-family)",
                  backgroundColor: "var(--app-bar-color)",
                  padding: "10px",
                  borderRadius: "10px",
                  color: "white",
                }}
                variant="h5"
                component="div"
              >
                {playerValue.length}
              </Typography>
            </Box>
          </Box>
          <GridTable rows={row} columns={GetData.view_player} height={400} />
        </Box>
      </Modal>
    </Box>
  );
};
