import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";
import ToastMessage from "../../Common/Components/ToastMessage";
import Extension from "../../Common/Extension";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import { GridTable } from "../common/GridTable";
import "./Styles/AcademyCoach.css";
import ViewPlayers from "./ViewPlayers";

export default function AcademyCoach({ query }) {
  const [posts, setPosts] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState("all");
  const [players, setPlayers] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState({});
  const [selectedCoachPlayers, setSelectedCoachPlayers] = useState([]);
  const navigate = useNavigate();
  const { toast, contextHolder } = ToastMessage();
  const academyId = parseInt(sessionStorage.getItem("academyId"));
  const userId = parseInt(sessionStorage.getItem("userId"));

  const getUsers = useCallback(async () => {
    // toast("loading", "Loading...");
    await api
      .get(`/users/academy/coach/${academyId}`)
      .then((response) => {
        setPosts(response.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
    await api
      .get(`/users/academy/player/${academyId}`)
      .then((response) => {
        setPlayers(response.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [academyId]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getPlayersForCoach = async (id) => {
    await api
      .get(`/users/coachId/${id}`)
      .then((response) => {
        setSelectedCoachPlayers(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const updateStatus = async () => {
    if (selectedCoachPlayers.length > 0 && selectedCoach.active === true) {
      navigate("/userMigration");
      return;
    }
    const selected_coach = await api
      .get(`users/${selectedCoach.userId}`)
      .then((res) => res.data)
      .catch((err) => {});
    const data = {
      ...selected_coach,
      active: !selectedCoach.active,
      modifyDate: Extension.getCurrentSQLDate(),
      exitingDate: selectedCoach.active ? Extension.getCurrentSQLDate() : null,
    };
    console.log(data);
    await api
      .put(`users/${data.userId}`, data)
      .catch((error) => console.error(error));

    if (selectedCoach.active) {
      const assignRes = await api
        .get(`/playerAssign/player/isactive/${data.userId}`)
        .then((res) => res.data)
        .catch((err) => console.error(err));
      const assignData = {
        ...assignRes,
        endDate: Extension.getCurrentSQLDate(),
        active: !selectedCoach.active,
        modifiedBy: userId,
        modifiedDate: Extension.getCurrentSQLDate(),
      };
      // console.log(assignData);

      await api
        .put(`/playerAssign/${assignData?.playerAssignDetailId}`, assignData)
        .catch((err) => console.error(err));
    }

    if (!selectedCoach.active) {
      const assignData = {
        playerId: selectedCoach.userId,
        coachId: 0,
        startDate: Extension.getCurrentSQLDate(),
        active: !selectedCoach.active,
        academyId: academyId,
        createdBy: userId,
        createdDate: Extension.getCurrentSQLDate(),
        modifiedBy: userId,
        modifiedDate: Extension.getCurrentSQLDate(),
      };
      await api
        .post(`/playerAssign/add`, assignData)
        .catch((err) => console.error(err));
    }
    handleClose();
    getUsers();
  };

  const makeStyle = (active) => {
    if (active === true) {
      return {
        cursor: "pointer",
        minWidth: 100,
        background: "rgb(145 254 159 / 47%)",
        color: "green",
      };
    } else {
      return {
        cursor: "pointer",
        minWidth: 100,
        background: "#ffadad8f",
        color: "red",
      };
    }
  };
  const handleAlertPopupOpen = (value) => {
    getPlayersForCoach(value?.userId);
    setSelectedCoach(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCoach({});
    setSelectedCoachPlayers([]);
  };

  const filteredRows = posts.filter(
    (item) =>
      item.userName.toLowerCase().includes(query.toLowerCase()) &&
      (status === "all" || item.active === status)
  );

  const coachRows = filteredRows.map((post, index) => {
    const findPlayers = players?.filter(
      (item) => parseInt(item.coachId) === parseInt(post.userId)
    );
    return {
      id: index + 1,
      loginId: post.loginId,
      name: post.userName,
      joinedDate: Extension.convertDateFormatToDMY(post?.createDate),
      noOfPlayersAssigned: findPlayers.length,
      playersAssigned: <ViewPlayers post={post} />,
      status: (
        <button
          className="status"
          onClick={() => handleAlertPopupOpen(post)}
          style={makeStyle(post.active)}
        >
          {post.active ? "Active" : "Terminated"}
        </button>
      ),
    };
  });

  const new_Column = {
    headerClassName: "thead",
    field: "status",
    headerName: (
      <div className="rowheader">
        <FormControl variant="standard" sx={{ minWidth: 40 }}>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            style={{
              color: "white",
              fontFamily: "var(--font-family)",
              fontWeight: "bold",
            }}
          >
            <MenuItem
              style={{ fontFamily: "var(--font-family)" }}
              value={"all"}
            >
              All Status
            </MenuItem>
            <MenuItem style={{ fontFamily: "var(--font-family)" }} value={true}>
              Active
            </MenuItem>
            <MenuItem
              style={{ fontFamily: "var(--font-family)" }}
              value={false}
            >
              Terminated
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    ),
    width: 180,
    renderCell: (param) => param.row.status,
  };

  const coachColumns = [...GetData.academyCoachData, new_Column];

  return (
    <>
      {contextHolder}
      <GridTable rows={coachRows} columns={coachColumns} />
      <CommonAlertPopup
        confirmButtonText={
          selectedCoachPlayers.length > 0 && selectedCoach.active === true
            ? "Navigate To Migration"
            : "Yes"
        }
        cancelButtonText={
          selectedCoachPlayers.length > 0 && selectedCoach.active === true
            ? "Cancel"
            : "No"
        }
        titleText={<p>Confirmation</p>}
        contentText={
          selectedCoachPlayers.length > 0 && selectedCoach.active === true ? (
            <p>
              The Coach <b>{selectedCoach?.userName}</b> have been assigned with{" "}
              <b>{selectedCoachPlayers.length}</b>{" "}
              {selectedCoachPlayers.length > 1 ? "Players" : "Player"},
              <br />
              Kindly Migrate the Players to Other Coach.
            </p>
          ) : (
            <p>
              Do You Want to{" "}
              <b>{!selectedCoach.active ? "active" : "terminate"}</b> the Coach{" "}
              <b>{selectedCoach.userName}</b>?
            </p>
          )
        }
        handleYes={updateStatus}
        handleNo={handleClose}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
}
