import { Box, Button, ButtonGroup, Grid } from "@mui/material";
import React, { useState } from "react";
import CommonTextField from "../../Common/Components/CommonTextField";
import ToastMessage from "../../Common/Components/ToastMessage";
import AcademyCoach from "./AcademyCoach";
import AcademyPlayers from "./AcademyPlayers";
import "./Styles/UserCreation.css";
import "./Styles/UserManipulation.css";
import { PlayerProfile } from "../common/PlayerProfile";

export default function UserManipulation() {
  const { contextHolder } = ToastMessage();

  const [value, setValue] = useState("coaches");
  const [query, setQuery] = useState("");
  const [profileShow, setProfileShow] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState({});

  const handleBackToManipulation = () => {
    setProfileShow(false);
    setSelectedPlayer("");
  };

  const handlePlayerProfile = (value, data) => {
    setProfileShow(value);
    setSelectedPlayer(data);
  };

  return (
    <>
      {profileShow ? (
        <PlayerProfile
          playerId={selectedPlayer.userId}
          handleClose={handleBackToManipulation}
          fromManipulation={true}
        />
      ) : (
        <Box className="common-wrapper">
          {contextHolder}
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <CommonTextField
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                  name="Search By Name"
                  label="Search By Name"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <ButtonGroup
                  sx={{ float: "right", height: "100%" }}
                  // color="primary"
                  value={value}
                  exclusive
                  aria-label="Platform"
                >
                  <Button
                    value="coaches"
                    onClick={() => setValue("coaches")}
                    sx={{
                      borderRadius: "9px",
                      fontFamily: "var(--font-family)",
                      background:
                        value === "coaches" ? "var(--app-bar-color)" : "white",
                      color:
                        value === "coaches" ? "white" : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    Coach
                  </Button>
                  <Button
                    value="players"
                    onClick={() => setValue("players")}
                    sx={{
                      borderRadius: "9px",
                      fontFamily: "var(--font-family)",
                      background:
                        value === "players" ? "var(--app-bar-color)" : "white",
                      color:
                        value === "players" ? "white" : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    Player
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            {value === "players" && (
              <AcademyPlayers
                query={query}
                handlePlayerProfile={handlePlayerProfile}
                handleBackToManipulation={handleBackToManipulation}
                value={value}
              />
            )}
            {value === "coaches" && <AcademyCoach query={query} />}
          </Box>
        </Box>
      )}
    </>
  );
}
