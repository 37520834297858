import moment from "moment";

class Extension {
  getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  timeDifference = (date1, date2) => {
    const startDate = moment(date2);
    const endDate = moment(date1);

    // Calculate the difference in days
    const totalDays = endDate.diff(startDate, "days") + 1;

    // Calculate the difference in months
    const months = endDate.diff(startDate, "months");

    // Calculate the remaining days
    const remainingDays = endDate.diff(startDate.add(months, "months"), "days");

    // Calculate the remaining weeks
    const weeks = Math.floor(remainingDays / 7);
    const remainingWeeks = weeks + (remainingDays % 7 > 0 ? 1 : 0);

    return { totalDays, months, remainingDays, remainingWeeks };
  };

  dayDifferFind = (date1, date2) => {
    const startDate = moment(date2);
    const endDate = moment(date1);

    if (!date2) {
      return null;
    }

    // Calculate the difference in days
    const totalDays = endDate.diff(startDate, "days");

    // Calculate the difference in months
    const months = endDate.diff(startDate, "months");
    const weeks = endDate.diff(startDate, "weeks");

    // Calculate the remaining days
    const remainingDays = endDate.diff(startDate.add(months, "months"), "days");

    // Calculate the remaining weeks
    // const weeks = Math.floor(remainingDays / 7);
    // const remainingWeeks = weeks + (remainingDays % 7 > 0 ? 1 : 0);

    console.log(totalDays, weeks, months);

    const value = totalDays > 30 ? months : totalDays > 7 ? weeks : totalDays;
    const format = totalDays > 30 ? "Months" : totalDays > 7 ? "Weeks" : "Days";

    return { value, format };
  };

  convertDateFormatToDMY(date) {
    const inDate = new Date(date);
    const year = inDate.getFullYear();
    const month = String(inDate.getMonth() + 1).padStart(2, "0");
    const day = String(inDate.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  }
}

export default new Extension();
