import { Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import api from "../../api/http-common";
import CoachBasedMigration from "./CoachBasedMigration";
import "./Styles/UserMigration.css";
import ToastMessage from "../../Common/Components/ToastMessage";

export default function UserMigration() {
  const [coaches, setCoaches] = useState([]);
  const [players, setPlayers] = useState([]);
  const [coachId, setCoachId] = useState(null);
  const [coachName, setCoachName] = useState(null);
  const [playerId, setPlayerId] = useState(null);
  const [playerName, setPlayerName] = useState(null);
  const { toast, contextHolder } = ToastMessage();

  const academyId = sessionStorage.getItem("academyId");
  const getPlayers = useCallback(async () => {
    // toast("loading", "Loading...");
    await api
      .get("users/academy/player/" + academyId)
      .then((response) => {
        const activePlayers = response.data.filter(
          (player) => player.active === true
        );
        setPlayers(activePlayers);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [academyId]);
  React.useEffect(() => {
    getPlayers();
    api
      .get("/users/academy/coach/active/" + academyId)
      .then((response) => {
        setCoaches(response.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [academyId, getPlayers]);

  const handleChangeCoach = (event) => {
    const selectedIndex = event.target.options.selectedIndex;
    const id = event.target.options[selectedIndex].getAttribute("data-key");
    if (event.target.value === "Kindly Select a Coach") setCoachId(null);
    setCoachId(id);
    if (event.target.value !== "Kindly Select a Coach")
      setCoachName(event.target.value);
    else setCoachName(null);
  };

  const handleChangePlayer = (event) => {
    const selectedIndex = event.target.options.selectedIndex;
    const id = event.target.options[selectedIndex].getAttribute("data-key");
    if (event.target.value === "Kindly Select a Player") setPlayerId(null);
    setPlayerId(id);
    if (event.target.value !== "Kindly Select a Player")
      setPlayerName(event.target.value);
    setPlayerName(null);
  };

  return (
    <Box className="common-wrapper">
      {contextHolder}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginLeft: 5,
            fontFamily: "var(--font-family)",
            display: "flex",
            justifyContent: "space-between",
            gap: 20,
          }}
        >
          <select
            onChange={handleChangeCoach}
            id="coachSelect"
            style={{
              width: 500,
              height: 53,
              fontFamily: "var(--font-family)",
            }}
          >
            <option
              value=""
              style={{ fontFamily: "var(--font-family)" }}
              defaultValue
            >
              Kindly Select a Coach
            </option>
            {coaches.map((coach) => {
              return (
                <option
                  style={{ fontFamily: "var(--font-family)" }}
                  key={coach.userId}
                  data-key={coach.userId}
                >
                  {coach.userName}
                </option>
              );
            })}
          </select>
          <select
            onChange={handleChangePlayer}
            id="coachSelect"
            style={{
              width: 500,
              height: 53,
              fontFamily: "var(--font-family)",
            }}
          >
            <option style={{ fontFamily: "var(--font-family)" }} disable>
              Kindly Select a Player
            </option>
            {players.map((player) => {
              return (
                <option
                  style={{ fontFamily: "var(--font-family)" }}
                  key={player.userId}
                  data-key={player.userId}
                >
                  {player.userName}
                </option>
              );
            })}
          </select>
        </div>
      </Box>
      <CoachBasedMigration
        players={players}
        coaches={coaches}
        playerId={playerId}
        coachId={coachId}
        coachName={coachName}
        getPlayers={getPlayers}
      />
    </Box>
  );
}
