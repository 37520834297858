import {
  ArrowLeft,
  DeleteRounded,
  VisibilityOff,
  VisibilitySharp,
} from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import HeightIcon from "@mui/icons-material/Height";
import HomeIcon from "@mui/icons-material/Home";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import PinDropIcon from "@mui/icons-material/PinDrop";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  FormHelperText,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  TableRow,
  Typography,
  Grid,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import format from "date-fns/format";
import React, { useEffect, useRef, useState } from "react";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import ToastMessage from "../../Common/Components/ToastMessage";
import api from "../../api/http-common";

const style = {
  formControl1: {
    width: "100%",
    // m: 1.5,
  },
  label: {
    color: "#000",
    fontWeight: 500,
    fontFamily: "var(--font-family)",
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "grey",
      fontSize: "15px",
      fontFamily: "var(--font-family)",
    },
  },
  inputFiled: {
    color: "#000",
    background: "#FFFFFF",
    fontSize: "18px",
    padding: "3px",
    fontFamily: "var(--font-family)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover > .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
};

const column = ["S.No", "Event", "Sub Event", "Action"];
const columns = column.map((item, index) => {
  return (
    <TableCell
      key={index}
      sx={{ color: "#FFFFFF", fontSize: "18px", fontWeight: 700 }}
      align="center"
    >
      {item}
    </TableCell>
  );
});

export const PlayerProfile = ({ playerId, handleClose, fromManipulation }) => {
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState(null);
  const [dob, setDob] = useState();
  const userId = playerId || sessionStorage.getItem("userId");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [event, setEvent] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [subEvent, setSubEvent] = useState([]);
  const [selectedSubEventType, setSelectedSubEventType] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [existingStrengthData, setExistingStrengthData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [heightError, setHeightError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [isPhoneNoInvalid, setIsPhoneNoInvalid] = useState(false);
  const [phoneNoErrorText, setPhoneNoErrorText] = useState("");
  const [isHeightInvalid, setIsHeightInvalid] = React.useState(false);
  const [isFirstNameInvalid, setIsFirstNameInvalid] = React.useState(false);
  const [isLastNameInvalid, setIsLastNameInvalid] = React.useState(false);
  const [isGenderInvalid, setIsGenderInvalid] = React.useState(false);
  const [isAddress1Invalid, setIsAddress1Invalid] = React.useState(false);
  const [isPinCodeInvalid, setIsPinCodeInvalid] = React.useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = React.useState(false);
  const [isWeightInvalid, setIsWeightInvalid] = React.useState(false);
  const [isDistrictInvalid, setIsDistrictInvalid] = React.useState(false);
  const { toast, contextHolder } = ToastMessage();
  const [password, setPassword] = useState("");
  const [updateButtonDisable, setUpdateButtonDisable] = useState(false);

  const fileInputRef = useRef("null");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        console.log(reader.result);
        updateProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditProfile = () => {
    fileInputRef.current.click();
  };
  const updateProfileImage = async (ImageData) => {
    try {
      const response = await api.get(`/personal_info/userId/${userId}`);
      console.log(response.data);
      const pId = response.data.personalInfoId;
      console.log(pId);
      const update = {
        ...response.data,
        image: ImageData,
      };
      console.log("before updated data", update);
      const updateImage = await api.put(`/personal_info/userId/${pId}`, update);
      console.log("updated Successfully", updateImage);
      //  console.log("ater updated data",response.data);
    } catch (err) {
      console.log("error update", err);
    }
  };

  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleDistrictChange = (e) => {
    // Remove non-alphabetic characters
    const value = e.target.value.replace(/[^a-zA-Z]/g, "");
    setCity(value);
  };

  const handleDistrictBlur = () => {
    const isValidDistrict = /^[a-zA-Z]+$/.test(city);

    setIsDistrictInvalid(!isValidDistrict);
  };

  const handleWeightChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setWeight(value);
  };

  const handleWeightBlur = () => {
    const isValidWeight = /^\d{1,3}$/.test(weight);
    const isEmptyWeight = weight?.trim() === "";

    setIsWeightInvalid(!isValidWeight || isEmptyWeight);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailBlur = () => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isEmptyEmail = email?.trim() === "";

    setIsEmailInvalid(!isValidEmail || isEmptyEmail);
  };

  const handlePinCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setPinCode(value);
  };

  const handlePinCodeBlur = () => {
    const isValidPinCode = /^\d{1,6}$/.test(pinCode);
    const isEmptyPinCode = pinCode?.trim() === "";

    setIsPinCodeInvalid(!isValidPinCode || isEmptyPinCode);
  };

  const handleAddress1Change = (e) => {
    setAddress(e.target.value);
  };

  const handleAddress1Blur = () => {
    const isValidAddress1 = address?.trim() !== "";

    setIsAddress1Invalid(!isValidAddress1);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleGenderBlur = () => {
    const isValidGender = gender?.trim() !== "";

    setIsGenderInvalid(!isValidGender);
  };

  const handleLastNameChange = (e) => {
    // Remove non-alphabetic characters
    const value = e.target.value.replace(/[^a-zA-Z]/g, "");
    setLastName(value);
  };

  const handleLastNameBlur = () => {
    const isValidLastName = /^[a-zA-Z]+$/.test(lastName);
    const isEmptyLastName = lastName?.trim() === "";

    setIsLastNameInvalid(!isValidLastName || isEmptyLastName);
  };

  const handleFirstNameChange = (e) => {
    // Remove non-alphabetic characters
    const value = e.target.value.replace(/[^a-zA-Z]/g, "");
    setFirstName(value);
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    const alpha = /[a-zA-Z]/;
    const numeric = /\d/;
    const spl = /[!@#$%^&*(),.?":{}|<>]/;
    if (
      !alpha.test(value) ||
      !numeric.test(value) ||
      !spl.test(value) ||
      value.length < 8
    ) {
      setPasswordError(
        "Password must be at-least 8 characters, One lowercase, One Uppercase, and One Special Character "
      );
    } else {
      setPasswordError("");
    }
  };

  // const handlePasswordBlue = (e) => {
  //   const value = e.target.value
  //   const alpha = /[a-zA-Z]/;
  //   const numeric = /\d/;
  //   const spl = /[!@#$%^&*(),.?":{}|<>]/;
  //   if (
  //     !alpha.test(value) ||
  //     !numeric.test(value) ||
  //     !spl.test(value) ||
  //     value.length < 8
  //   ) {
  //     setPasswordError(
  //       "Password must be at-least 8 characters, One lowercase, One Uppercase, and One Special Character "
  //     );
  //   } else {
  //     setPasswordError("");
  //   }
  // }

  const handleFirstNameBlur = () => {
    const isValidFirstName = /^[a-zA-Z]+$/.test(firstName);
    const isEmptyFirstName = firstName?.trim() === "";

    setIsFirstNameInvalid(!isValidFirstName || isEmptyFirstName);
  };

  const handleHeightChange = (e) => {
    // Remove non-numeric characters
    const value = e.target.value.replace(/\D/g, "");
    setHeight(value);

    // Check if the value exceeds the maximum height
    if (parseInt(value) > 250) {
      setHeightError("The Maximum Height Should Be 250 cm");
      setIsHeightInvalid(true);
    } else {
      setHeightError("");
      setIsHeightInvalid(false);
    }
  };

  const handleHeightBlur = () => {
    const isValidHeight = /^\d{1,3}$/.test(height);
    const isEmptyHeight = height?.trim() === "";

    if (!isValidHeight || isEmptyHeight || parseInt(height) > 250) {
      setIsHeightInvalid(true);
      setHeightError("Please enter a valid height up to 250 cm");
    } else {
      setIsHeightInvalid(false);
      setHeightError("");
    }
  };
  const handlePhoneNoBlur = () => {
    // Validate phone number on blur
    const isValid = validatePhoneNumber(phoneNo);
    setIsPhoneNoInvalid(!isValid);
    setPhoneNoErrorText(isValid ? "" : "Invalid phone number");
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Check if the phone number is 10 digits long and contains only numeric characters
    return /^\d{10}$/.test(phoneNumber);
  };

  const handlePhoneNoChange = (e) => {
    // Remove non-numeric characters
    const value = e.target.value.replace(/\D/g, "");
    setPhoneNo(value);
  };

  const formattedDOB = dob ? format(new Date(dob), "yyyy-MM-dd") : "";
  const [playerUserId, setPlayerUserId] = useState("");
  async function fetchData() {
    try {
      toast("loading", "Loading...");
      const profile = await api.get(`/personal_info/userId/${userId}`);
      const response = profile.data;
      console.log(response.data);
      console.log(response.data);
      setProfileImage(response.image);
      setFirstName(response.name);
      setLastName(response.fatherName);
      setGender(response.gender);
      setDob(response.dob);
      setAddress(response.street);
      setCity(response.city);
      setPinCode(response.pinCode);
      setPhoneNo(response.mobileNo);
      setEmail(response.email);
      setHeight(response.height);
      setWeight(response.weight);

      const playerUserId = await api.get(`/users/${userId}`);
      const password = playerUserId.data.pwd;
      console.log("Player", password);
      // setPassword(password);

      const strengthResponse = await api.get(`/strength/userId/${userId}`);
      const strengthData = strengthResponse.data;
      console.log(strengthData);
      setExistingStrengthData(strengthData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [userId, setProfileImage, setGender, event, subEvent]);

  async function getData() {
    try {
      const strengthResponse = await api.get(`/strength/userId/${userId}`);
      const strengthData = strengthResponse.data;
      // console.log(strengthData)
      setExistingStrengthData(strengthData);
    } catch (error) {
      console.log("error fetch in getData", error);
    }
  }
  useEffect(() => {
    getData();
  }, [existingStrengthData]);

  async function getEvent() {
    try {
      const eventResponse = await api.get("/event_type/all");
      const eventList = eventResponse.data;
      setEvent(eventList);
      console.log(eventList);

      for (let event of eventList) {
        const subEventResponse = await api.get(
          `/sub_events/eventId/${event.eventTypeId}`
        );
        const subEventList = subEventResponse.data;
        console.log(subEventList);
        // You may want to merge subEventList into a single array depending on your use case
        // For now, this appends sub-event list to the state
        setSubEvent((prevSubEvents) => [
          ...prevSubEvents,
          { eventId: event.eventTypeId, subEvents: subEventList },
        ]);
      }
    } catch (error) {
      console.error("Error fetching events or sub-events:", error);
    }
  }
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/strength/userId/${userId}`);
        setTableData(response.data);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleAdd = async () => {
    if (selectedEvent && selectedSubEventType) {
      const strength = {
        selectedEvent: selectedEvent,
        selectedSubEventType: selectedSubEventType,
        eventTypeId: selectedEvent?.eventTypeId || null,
        subEventsId: selectedSubEventType?.subEventId || null,
        userId: userId,
        createdDate: getCurrentSQLDate(),
      };

      // Check for duplicates in the current table data
      const isDuplicate = tableData.some(
        (item) =>
          item.eventTypeId === selectedEvent.eventTypeId &&
          item.subEventsId === selectedSubEventType.subEventId
      );

      if (isDuplicate) {
        // setErrorMessage('This sub-event has already been added.');
        toast("warning", `This sub-event has already added.`);

        return;
      }
      try {
        // Post new strength record
        const strengthPost = await api.post("/strength/add", strength);
        console.log("strengthPost successfully", strengthPost);

        // Fetch updated strength data
        const strengthResponse = await api.get(`/strength/userId/${userId}`);
        const updatedStrengthData = strengthResponse.data;

        // Update the state with the new data
        setTableData(updatedStrengthData);
        setErrorMessage(""); // Clear any previous error messages
      } catch (error) {
        console.error("Error adding strength data:", error);
        setErrorMessage("Failed to add the event. Please try again.");
      }
    }
  };

  const handleDelete = async (index) => {
    try {
      const deleteStrength = await api.delete(`/strength/${index}`);
      console.log("deleted successfully", deleteStrength);
    } catch (error) {
      console.error("Error deleting strength data:", error);
    }
  };

  const [isProfileExists, setIsProfileExists] = useState(true);
  useEffect(() => {
    async function personalInfo() {
      try {
        const response = await api.get("/users/" + userId);
        console.log(response.data);

        const personalResponse = await api.get("/personal_info/all");
        console.log(personalResponse.data);

        const id = personalResponse.data.find(
          (item) => item.userId === response.data.userId
        );
        console.log(id);
        setIsProfileExists(id.length > 0);
      } catch (err) {
        console.log(err);
      }
    }
    personalInfo();
    // setIsProfileExists(personalInfoId.length > 0);
  }, []);

  async function handleSubmit() {
    try {
      if (!firstName || !lastName || !gender) {
        // Create an array to store the names of missing fields
        const missingFields = [];
        // Check each field individually and add its name to the array if it's empty
        if (!firstName) missingFields.push("First Name");
        if (!lastName) missingFields.push("Last Name");
        if (!gender) missingFields.push("Gender");
        // if (!address) missingFields.push("Address");
        // if (!pinCode) missingFields.push("Pincode");
        if (!phoneNo) missingFields.push("Phone no");
        if (!email) missingFields.push("Email");
        // if (!city) missingFields.push("city");
        // if (!height) missingFields.push("Height");
        // if (!weight) missingFields.push("Weight");
        // if (!password) missingFields.push("Password");

        // Repeat this for each field...

        // Create the alert message based on the missing fields
        let alertMessage = "Please fill in fields: " + missingFields.join(", ");

        // Display the alert
        toast("error", alertMessage);
        return;
      }
      toast("loading", "Processing...");
      setUpdateButtonDisable(true);
      const response = await api.get("/users/" + userId);
      console.log(response.data);

      if (response.data.userId) {
        const personalDetails = {
          name: firstName,
          fatherName: lastName,
          gender: gender,
          dob: dob,
          street: address,
          city: city,
          pinCode: pinCode,
          mobileNo: phoneNo,
          email: email,
          height: height,
          weight: weight,
          createdDate: getCurrentSQLDate(),
          userId: userId,
          academyId: sessionStorage.getItem("academyId"),
          createdBy: sessionStorage.getItem("userId"),
          coachId: sessionStorage.getItem("userId"),
        };

        const updatePersonalDetails = {
          name: firstName,
          fatherName: lastName,
          gender: gender,
          dob: dob,
          street: address,
          city: city,
          pinCode: pinCode,
          mobileNo: phoneNo,
          email: email,
          height: height,
          weight: weight,
        };

        const personalResponse = await api.get("/personal_info/all");
        console.log(personalResponse.data);

        const id = personalResponse.data.find(
          (item) => item.userId === response.data.userId
        );

        if (password) {
          const updateUserAll = {
            ...response.data,
            pwd: password,
          };

          const updatePwd = await api
            .put(`/users/${userId}`, updateUserAll)
            .then((res) => res.data)
            .catch((err) => {});
          console.log("profile update successfully", updatePwd);
        }

        if (id && id.personalInfoId !== undefined) {
          const res = id.personalInfoId;
          console.log(res);
          try {
            const update = await api.put(`/personal_info/${res}`, {
              ...id,
              updatePersonalDetails,
            });
            if (password) {
              const updateUserAll = {
                ...response.data,
                pwd: password,
              };

              const updatePwd = await api
                .put(`/users/${userId}`, updateUserAll)
                .then((res) => res.data)
                .catch((err) => {});
              console.log("profile update successfully", update, updatePwd);
            }
            toast("success", "Profile Updated Successfully...");
            setTimeout(() => {
              navigate(handleClose());
            }, 2000);
            // setFirstName(updatePersonalDetails.firstName);
            // setLastName(updatePersonalDetails.fatherName);
            // setGender(updatePersonalDetails.gender);
            // setDob(updatePersonalDetails.dob);
            // setAddress(updatePersonalDetails.street);
            // setCity(updatePersonalDetails.city);
            // setPinCode(updatePersonalDetails.pinCode);
            // setPhoneNo(updatePersonalDetails.mobileNo);
            // setEmail(updatePersonalDetails.email);
            // setHeight(updatePersonalDetails.height);
            // setWeight(updatePersonalDetails.weight);
            // setPassword(updateUserAll.password);
            // console.log(updatePersonalDetails.name);
          } catch (error) {
            console.error("Error updating profile:", error);
          }
        } else {
          const responsePost = await api.post(
            "/personal_info/add",
            personalDetails
          );
          console.log("profile post successfully", responsePost);
          toast("success", "Profile Added Successfully...");
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUpdateButtonDisable(false);
    }
  }

  useEffect(() => {
    getEvent();
  }, []);

  const DataRow = existingStrengthData.map((esd, index) => {
    // Find the event and sub-event objects based on eventTypeId and subEventsId
    const selectedEvent =
      event.find((e) => e.eventTypeId === esd.eventTypeId) || {};
    const selectedSubEvent =
      subEvent
        .find((item) => item.eventId === esd.eventTypeId)
        ?.subEvents.find(
          (subEventItem) => subEventItem.subEventId === esd.subEventsId
        ) || {};

    return {
      id: index + 1,
      event: selectedEvent.eventType || "", // Use the eventType property from the selected event
      subEvent: selectedSubEvent.subEventName || "", // Use the subEventName property from the selected sub-event
      strengthId: esd.strengthId,
      action: (
        <Box onClick={() => handleDelete(esd.strengthId)}>
          <DeleteRounded color="error" />
        </Box>
      ),
    };
  });

  return (
    <Box className={/* !fromManipulation &&  */ "common-wrapper"}>
      {contextHolder}
      {/* <Paper className='content' elevation={4} sx={{ mt: 15, mb: 20, p: 3 }}> */}
      {fromManipulation && (
        <Box sx={{ mb: 2 }}>
          <CustomCommonButton icon={<ArrowLeft />} onClick={handleClose} />
        </Box>
      )}
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          padding: "30px",
          borderRadius: "var(--roundness)",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                color: "var(--app-bar-color)",
                fontFamily: "var(--font-family)",
              }}
            >
              Personal Info
            </Typography>
            <Grid
              container
              columns={{ xs: 1, sm: 1, md: 3, lg: 4 }}
              spacing={2}
            >
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl
                  variant="outlined"
                  sx={style.formControl1}
                  size="small"
                >
                  <InputLabel sx={style.label}>First Name</InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px" }}
                    sx={style.inputFiled}
                    id="firstName"
                    type="text"
                    label="First Name"
                    value={firstName}
                    inputProps={{
                      pattern: "^[a-zA-Z]+$",
                    }}
                    onChange={handleFirstNameChange}
                    // onBlur={handleFirstNameBlur}
                    error={isFirstNameInvalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <PersonIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Last Name</InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="lastName"
                    type="text"
                    label="Last Name"
                    value={lastName}
                    inputProps={{
                      pattern: "^[a-zA-Z]+$",
                    }}
                    onChange={handleLastNameChange}
                    onBlur={handleLastNameBlur}
                    error={isLastNameInvalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <PersonIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Gender</InputLabel>
                  <Select
                    style={{ borderRadius: "10px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="gender"
                    label="Gender"
                    value={gender || ""}
                    onChange={handleGenderChange}
                    onBlur={handleGenderBlur}
                    error={isGenderInvalid}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Transgender"}>Transgender</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Date of Birth</InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px", padding: "-20px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="date"
                    type="date"
                    value={formattedDOB}
                    onChange={(e) => setDob(e.target.value)}
                    label="Date"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Address </InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="address"
                    type="text"
                    label="Address "
                    value={address}
                    onChange={handleAddress1Change}
                    onBlur={handleAddress1Blur}
                    error={isAddress1Invalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <HomeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>City</InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="city"
                    type="text"
                    label="City"
                    value={city}
                    inputProps={{
                      pattern: "^[a-zA-Z]+$",
                    }}
                    onChange={handleDistrictChange}
                    onBlur={handleDistrictBlur}
                    error={isDistrictInvalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <LocationCityIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Pincode</InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="pincode"
                    type="text"
                    label="Pincode"
                    value={pinCode}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 6,
                    }}
                    onChange={handlePinCodeChange}
                    onBlur={handlePinCodeBlur}
                    error={isPinCodeInvalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <PinDropIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Phone no</InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="phoneno"
                    type="text"
                    label="Phone no"
                    value={phoneNo}
                    onChange={handlePhoneNoChange}
                    onBlur={handlePhoneNoBlur}
                    error={isPhoneNoInvalid}
                    //helperText={phoneNoErrorText}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 10,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <InstallMobileIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Email</InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="email"
                    type="email"
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    error={isEmailInvalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <EmailIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl
                  variant="outlined"
                  sx={style.formControl1}
                  error={passwordError}
                >
                  <InputLabel sx={style.label}> Password *</InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="password"
                    type={passwordVisible ? "text" : "password"}
                    label="Password"
                    name="pwd"
                    value={password}
                    onChange={handlePasswordChange}
                    // onBlur={handlePasswordBlue}
                    // error={isEmailInvalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          {passwordVisible ? (
                            <VisibilityOff />
                          ) : (
                            <VisibilitySharp />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {passwordError && (
                    <FormHelperText>{passwordError}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                color: "var(--app-bar-color)",
                fontFamily: "var(--font-family)",
                mt: 1,
              }}
            >
              Physical Info
            </Typography>
            <Grid
              container
              columns={{ xs: 1, sm: 1, md: 3, lg: 4 }}
              spacing={2}
            >
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Height (cm)</InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="height"
                    type="text"
                    label="Height (cm)"
                    value={height}
                    inputProps={{
                      pattern: "^[0-9]*$",
                      maxLength: 3,
                    }}
                    onChange={handleHeightChange}
                    onBlur={handleHeightBlur}
                    error={isHeightInvalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <HeightIcon />
                      </InputAdornment>
                    }
                  />
                  {heightError && (
                    <FormHelperText error>{heightError}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Weight (Kg)</InputLabel>
                  <OutlinedInput
                    style={{ borderRadius: "10px" }}
                    size="small"
                    sx={style.inputFiled}
                    id="weight"
                    type="text"
                    label="Weight (Kg)"
                    value={weight}
                    inputProps={{
                      pattern: "^[0-9]*$",
                      maxLength: 3,
                    }}
                    onChange={handleWeightChange}
                    onBlur={handleWeightBlur}
                    error={isWeightInvalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <MonitorWeightIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl sx={style.formControl1}>
                  {isProfileExists ? (
                    <Button
                      className="btn"
                      sx={{
                        background: "var(--app-bar-color)",
                        color: "#FFFFFF",
                        borderRadius: "10px",
                        height: 45,
                        fontFamily: "var(--font-family)",
                        "&:hover": { background: "#006392" },
                      }}
                      onClick={handleSubmit}
                    >
                      Add Profile
                    </Button>
                  ) : (
                    <Button
                      className="btn"
                      sx={{
                        background: "var(--app-bar-color)",
                        color: "#FFFFFF",
                        borderRadius: "10px",
                        height: 45,
                        fontFamily: "var(--font-family)",
                        "&:hover": { background: "#006392" },
                      }}
                      disabled={updateButtonDisable}
                      onClick={handleSubmit}
                    >
                      Update Profile
                    </Button>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                color: "var(--app-bar-color)",
                fontFamily: "var(--font-family)",
                mt: 1,
              }}
            >
              Events
            </Typography>
            <Grid
              container
              columns={{ xs: 1, sm: 1, md: 3, lg: 4 }}
              spacing={2}
            >
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Event</InputLabel>
                  <Select
                    style={{ borderRadius: "10px" }}
                    sx={style.inputFiled}
                    id="event"
                    label="Event"
                    size="small"
                    value={selectedEvent ? selectedEvent.eventTypeId : ""}
                    onChange={(e) =>
                      setSelectedEvent(
                        event.find(
                          (option) => option.eventTypeId === e.target.value
                        )
                      )
                    }
                  >
                    {event.map((option, index) => (
                      <MenuItem key={index} value={option.eventTypeId}>
                        {option.eventType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Sub Event</InputLabel>
                  <Select
                    style={{ borderRadius: "10px" }}
                    sx={style.inputFiled}
                    size="small"
                    id="subEvent"
                    label="Sub Event"
                    value={
                      selectedSubEventType
                        ? selectedSubEventType.subEventId
                        : ""
                    }
                    onChange={(e) => {
                      const selectedEventItem = subEvent.find(
                        (item) =>
                          item.eventId === (selectedEvent?.eventTypeId || "")
                      );
                      const selectedSubEventItem = selectedEventItem
                        ? selectedEventItem.subEvents.find(
                            (option) => option.subEventId === e.target.value
                          )
                        : null;
                      setSelectedSubEventType(selectedSubEventItem);
                    }}
                  >
                    {subEvent
                      .find(
                        (item) =>
                          item.eventId === (selectedEvent?.eventTypeId || "")
                      )
                      ?.subEvents.map((option, index) => (
                        <MenuItem key={index} value={option.subEventId}>
                          {option.subEventName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1} lg={1} sm={1}>
                <FormControl sx={style.formControl1}>
                  <Button
                    className="btn"
                    sx={{
                      background: "var(--app-bar-color)",
                      color: "#FFFFFF",
                      borderRadius: "10px",
                      height: 45,
                      fontFamily: "var(--font-family)",
                      "&:hover": { background: "#006392" },
                    }}
                    onClick={handleAdd}
                  >
                    Add Event
                  </Button>
                  {errorMessage && (
                    <FormHelperText error>{errorMessage}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          {/* <Box>
            <Divider
              orientation="vertical"
              sx={{
                m: 2,
                borderColor: "var(--app-bar-color)",
                borderBottomWidth: "45px",
                ml: 3,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              mt: 8,
              ml: 4,
              flexDirection: "column",
            }}
          >
            <div>
              <img
                src={`data:image/jpeg;base64,${profileImage}`}
                alt=""
                className="profileImg"
              />
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <Button
              className="btn"
              sx={{
                mt: 3,
                background: "var(--app-bar-color)",
                color: "#FFFFFF",
                color: "#FFFFFF",
                borderRadius: "10px",
                height: 45,
                fontFamily: "var(--font-family)",
                "&:hover": { background: "#006392" },
              }}
              onClick={handleEditProfile}
            >
              Edit Profile
            </Button>
          </Box> */}
        </Box>
        {/* <GridTable columns={GetData.player_profile} rows={DataRow} /> */}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <TableContainer
            sx={{ width: "100%", mt: 2, maxHeight: 200 }}
            component={Paper}
          >
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              stickyHeader
              style={{ fontFamily: "var(--font-family)" }}
            >
              <TableHead sx={{ background: "var(--app-bar-color)" }}>
                <TableRow>{columns}</TableRow>
              </TableHead>

              <TableBody>
                {DataRow.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">{row.event}</TableCell>
                    <TableCell align="center">{row.subEvent}</TableCell>
                    <TableCell align="center">
                      <Box onClick={() => handleDelete(row.strengthId)}>
                        <DeleteRounded color="error" />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* </Paper> */}
      </Paper>
    </Box>
  );
};
