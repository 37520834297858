import { CalendarMonth, Search } from "@mui/icons-material";
import { Box, Grid, Popover, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import CommonDropdown from "../../../Common/Components/CommonDropdown";
import CommonTexField from "../../../Common/Components/CommonTextField";
import ToastMessage from "../../../Common/Components/ToastMessage";
import { RadioBtnFilter } from "../../../Common/Components/dateFilter/RadioBtnFilter";
import Extension from "../../../Common/Extension";
import api from "../../../api/http-common";
import "./Analytic.css";
import Biometrics from "./Biometrics/Biometrics";
import CoachContainer from "./CoachContainer";
import PlayersContainer from "./PlayersContainer";
import RibbonContainer from "./RibbonContainer";
import FeelLevelTrack from "./TrackCharts/FeelLevelTrack";
import InfluencerTrack from "./TrackCharts/InfluencerTrack";
import PerformanceTrack from "./TrackCharts/PerformanceTrack";
import PreparationTrack from "./TrackCharts/PreparationTrack";
import SleepTimeTrack from "./TrackCharts/SleepTimeTrack";
import WaterTrack from "./TrackCharts/WaterTrack";

const Analytics = () => {
  const userId = parseInt(sessionStorage.getItem("userId"));
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const academyId = parseInt(sessionStorage.getItem("academyId"));

  const [searchQuery, setSearchQuery] = useState("");
  const [searchCoachQuery, setSearchCoachQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [players, setPlayers] = useState([]);
  const [performance, setPerformance] = useState([]);
  const [influence, setInfluence] = useState([]);
  const [dayDifference, setDayDifference] = useState(0);
  const [biometricData, setBiometricData] = useState({});
  const [selected_userId, setSelected_userId] = useState("");
  const [selectedCoach_userId, setSelectedCoach_userId] = useState("");
  const [allPerformance, setAllPerformance] = useState("");
  const [period, setPeriod] = useState("all");
  const [warmupHeaders, setWarmupHeaders] = useState([]);
  const [preparation, setPreparation] = useState([]);
  const [coach, setCoach] = useState([]);
  const [academyPlayers, setAcademyPlayers] = useState([]);
  const today = new Date();
  const [selectedUserData, setSelectedUserData] = useState({});
  const { toast, contextHolder } = ToastMessage();

  const currentDay = format(today, "yyyy-MM-dd");

  const monthly = format(
    new Date(today.getFullYear(), today.getMonth(), 1),
    "yyyy-MM-dd"
  );

  const yearly = format(new Date(today.getFullYear(), 0, 1), "yyyy-MM-dd");
  const weekly = format(
    new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 1
    ),
    "yyyy-MM-dd"
  );
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(monthly),
    endDate: new Date(),
    key: "selection",
  });

  const handleDateRangeChange = (dateRange) => {
    setSelectedRange(dateRange.selection);
    setPeriod("custom");
  };

  const handleUserSelection = (data) => {
    setSelected_userId(data.userId);
    setSelectedUserData(data);
  };

  const handleCoachSelection = async (data) => {
    setSelectedCoach_userId(data.userId);
    try {
      if (data.userId) {
        const subEventsResponse = await api.get(`/sub_events/all`);

        const strengthResponse = await api.get(
          `/strength/userId/${data.userId}`
        );

        const subEventsIds = strengthResponse.data.map(
          (item) => item.subEventsId
        );
        const events = subEventsIds.map((eventId) => {
          return subEventsResponse.data.find(
            (event) => event.subEventId === eventId
          );
        });

        setEvents(events);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    if (data.userId) {
      await api
        .get(`users/coachId/${data.userId}`)
        .then((res) => {
          setAcademyPlayers(res.data);
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    const getInitialData = async () => {
      toast("loading", "Loading...");
      if (roleId === 1) {
        await api
          .get(`users/academy/coach/${academyId}`)
          .then((res) => {
            setCoach(res.data);
          })
          .catch((err) => console.error(err));
      }

      if (roleId === 1 && selectedCoach_userId) {
        await api
          .get(`/users/coachId/${selectedCoach_userId}`)
          .then((res) => {
            setPlayers(res.data);
          })
          .catch((err) => console.error(err));
      }

      if (roleId === 1 && selectedCoach_userId) {
        await api
          .get(`users/coachId/${selectedCoach_userId}`)
          .then((res) => {
            setAcademyPlayers(res.data);
          })
          .catch((err) => console.error(err));
      }

      if (roleId === 2 && userId) {
        await api
          .get(`/users/coachId/${userId}`)
          .then((res) => {
            setPlayers(res.data);
          })
          .catch((err) => console.error(err));
      }
      try {
        if (roleId === 2) {
          const subEventsResponse = await api.get(`/sub_events/all`);

          const strengthResponse = await api.get(`/strength/userId/${userId}`);

          const subEventsIds = strengthResponse.data.map(
            (item) => item.subEventsId
          );
          const events = subEventsIds.map((eventId) => {
            return subEventsResponse.data.find(
              (event) => event.subEventId === eventId
            );
          });

          setEvents(events);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      
      if (selectedCoach_userId !== "") {
        await api
          .get(`/warmUpWorkoutDetailsController/all`)
          .then((res) => setWarmupHeaders(res.data))
          .catch((err) => console.error(err));
      }
    };
    getInitialData();
  }, [userId, selectedCoach_userId]);

  // console.log("warmupHeader", warmupHeaders);

  // console.log("preparation", preparation);

  useEffect(() => {
    const getPreparation = async () => {
      if(selected_userId !== ""){
        await api
        .get(
          `/warmUpWorkoutHeader/player/${selected_userId}/${format(
            selectedRange.startDate,
            "yyyy-MM-dd"
          )}/${format(selectedRange.endDate, "yyyy-MM-dd")}`
        )
        .then((res) => {
          const headerIDs = res.data.map(
            (item) => item.warmUpWorkoutHeaderId
          );

          const preparations = headerIDs.map((ids) => {
            return warmupHeaders.find(
              (value) => value?.warmUpWorkoutHeaderId === ids
            );
          });
          setPreparation(preparations);
        })
        .catch((err) => console.error(err));
      }
        
    };

    getPreparation();
  }, [selectedRange, selected_userId, selectedCoach_userId, warmupHeaders]);

  useEffect(() => {
    const getBiometrics = async () => {
      if(selected_userId !== ""){
        await api
        .get(
          `/biometric/findDate/${selected_userId}/${format(
            selectedRange.startDate,
            "yyyy-MM-dd"
          )}/${format(selectedRange.endDate, "yyyy-MM-dd")}`
        )
        .then((res) => {
          setBiometricData(res.data);
        })
        .catch((err) => console.error(err));
      }
        
    };
    getBiometrics();
  }, [selectedRange.endDate, selectedRange.startDate, selected_userId]);

  useEffect(() => {
    const getPerformance = async () => {
      if(selected_userId !== "" && selectedEvent)
        await api
          .get(
            `/performance/playerId/findDate/${selected_userId}/${format(
              selectedRange.startDate,
              "yyyy-MM-dd"
            )}/${format(selectedRange.endDate, "yyyy-MM-dd")}/${selectedEvent}`
          )
          .then((res) => {
            setPerformance(res.data);
          })
          .catch((err) => console.error(err));
    };
    getPerformance();
  }, [
    selectedEvent,
    selectedRange.endDate,
    selectedRange.startDate,
    selected_userId,
  ]);

  useEffect(() => {
    const getAllPerformance = async () => {
      if (selected_userId !== "" && selectedEvent) {
        await api
          .get(`/performanceBest/${selected_userId}/${selectedEvent}`)
          .then((res) => {
            setAllPerformance(res.data || []);
          })
          .catch((err) => console.error(err));
      }
    };
    getAllPerformance();
  }, [selectedEvent, selected_userId]);

  useEffect(() => {
    const getInfluence = async () => {
      if (selected_userId) {
        await api
          .get(
            `/influenceHeader/findDate/${selected_userId}/${format(
              selectedRange.startDate,
              "yyyy-MM-dd"
            )}/${format(selectedRange.endDate, "yyyy-MM-dd")}`
          )
          .then((res) => {
            setInfluence(res.data);
          })
          .catch((err) => console.error(err));
      }
    };
    getInfluence();
  }, [selectedRange.endDate, selectedRange.startDate, selected_userId]);

  useEffect(() => {
    setDayDifference(
      Extension.timeDifference(
        format(selectedRange.endDate, "yyyy-MM-dd"),
        format(selectedRange.startDate, "yyyy-MM-dd")
      )
    );
  }, [selectedRange.endDate, selectedRange.startDate]);

  useEffect(() => {
    if (Array.isArray(events) && events.length > 0) {
      setSelectedEvent(events[0].subEventId);
    }
  }, [events]);

  useEffect(() => {
    if (period === "monthly") {
      setSelectedRange({
        startDate: new Date(monthly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "weekly") {
      setSelectedRange({
        startDate: new Date(weekly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "yearly") {
      setSelectedRange({
        startDate: new Date(yearly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "today") {
      setSelectedRange({
        startDate: new Date(currentDay),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "all") {
      setSelectedRange({
        startDate: new Date("2022-01-01"),
        endDate: new Date(currentDay),
        key: "selection",
      });
    }
  }, [currentDay, monthly, period, weekly, yearly]);

  useEffect(() => {
    if (selectedUserData && period === "all") {
      setSelectedRange({
        startDate: new Date(selectedUserData?.createDate || "2022-01-01"),
        endDate: new Date(currentDay),
        key: "selection",
      });
    }
  }, [period, selectedUserData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const filteredPlayer = players.filter(
    (item) =>
      searchQuery === "" ||
      item.userName
        .toString()
        .toLowerCase()
        .includes(searchQuery.toString().toLowerCase()) ||
      item.loginId
        .toString()
        .toLowerCase()
        .includes(searchQuery.toString().toLowerCase())
  );
  const filteredCoach = coach.filter(
    (item) =>
      searchCoachQuery === "" ||
      item.userName
        .toString()
        .toLowerCase()
        .includes(searchCoachQuery.toString().toLowerCase()) ||
      item.loginId
        .toString()
        .toLowerCase()
        .includes(searchCoachQuery.toString().toLowerCase())
  );

  const [key, setKey] = useState(0);

  // useEffect with an empty dependency array to mimic componentDidMount
  useEffect(() => {
    // Update the key to force a re-render of the component
    setKey(prevKey => prevKey + 1);
  }, []); 
  console.log(key);

  return (
    <Box className="analytical-wrapper" key={key} >
      {contextHolder}
      {/* Filter Container */}
      <Grid
        container
        columnSpacing={2}
        rowSpacing={2}
        columns={{ xs: 1, sm: 1, md: 4, lg: 4 }}
        sx={{ mb: 2 }}
      >
        <Grid
          item
          sm={1}
          md={roleId === 1 ? 1.5 : 1}
          lg={roleId === 1 ? 1.5 : 1}
          xs={1}
        >
          <CommonTexField
            value={searchQuery}
            name="Search Player"
            onChange={(e) => setSearchQuery(e.target.value)}
            label="Search Player"
            type="text"
            position="start"
            comboFixWord={<Search />}
          />
        </Grid>
        <Grid
          item
          sm={1}
          md={roleId === 1 ? 0.9 : 1}
          lg={roleId === 1 ? 0.9 : 1}
          xs={1}
        >
          <CommonDropdown
            value={selectedEvent}
            onChange={(e) => setSelectedEvent(e.target.value)}
            array={events}
            menuValue={"subEventId"}
            display={"subEventName"}
            name="Search By Event"
            label="Search By Event"
          />
        </Grid>
        <Grid
          item
          sm={0.8}
          md={0.7}
          lg={0.7}
          xs={1}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <RadioBtnFilter handleRadioChange={(data) => setPeriod(data)} />
        </Grid>
        <Grid item sm={0.2} md={0.1} lg={0.1} xs={1}>
          <div role="button" onClick={handleClick} className="icon-button">
            <CalendarMonth />
          </div>
          {/* <CustomDateRangePicker /> */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <DateRange
              editableDateInputs
              minDate={new Date(selectedUserData?.createDate)}
              maxDate={new Date(currentDay)}
              onChange={handleDateRangeChange}
              moveRangeOnFirstSelection={false}
              ranges={[selectedRange]}
            />
          </Popover>
        </Grid>
        <Grid
          item
          sm={1}
          md={0.7}
          lg={0.7}
          xs={1}
          sx={{ textAlign: "center", margin: "auto" }}
        >
          <Typography
            variant="h5"
            sx={{
              fontFamily: "var(--font-family)",
              fontWeight: "var(--font-weight)",
              color: "var(--app-bar-color)",
            }}
          >
            {(roleId === 2 || roleId === 5) &&
              biometricData &&
              (players?.find(
                (item) => parseInt(item.userId) === selected_userId
              )?.userName ||
                "--")}

            {roleId === 1 &&
              biometricData &&
              (academyPlayers?.find(
                (item) => parseInt(item.userId) === selected_userId
              )?.userName ||
                "--")}
          </Typography>
        </Grid>
      </Grid>
      {/* Player container */}
      <Grid
        container
        spacing={2}
        columns={{ xs: 1, sm: 1, md: 3, lg: 3 }}
        sx={{ mb: 2 }}
      >
        {roleId === 1 && (
          <Grid item sm={1} md={1} lg={1} xs={1} pr={3}>
            <CoachContainer
              coaches={filteredCoach}
              handleUserSelection={handleCoachSelection}
            />
          </Grid>
        )}
        <Grid
          item
          sm={1}
          md={roleId === 1 ? 1 : 2}
          lg={roleId === 1 ? 1 : 2}
          xs={1}
        >
          <PlayersContainer
            players={filteredPlayer}
            handleUserSelection={handleUserSelection}
          />
        </Grid>

        <Grid item sm={1} md={1} lg={1} xs={1}>
          <RibbonContainer
            performance={allPerformance}
            events={events}
            selectedEvent={selectedEvent}
          />
        </Grid>
      </Grid>

      {/* Charts Containers */}
      <Grid container columns={{ xs: 1, sm: 1, md: 2, lg: 2 }} spacing={2}>
        <Grid item sm={1} md={1} lg={1} xs={1}>
          <Grid container columns={{ xs: 1, sm: 1, md: 5, lg: 5 }} spacing={2}>
            <Grid item sm={1} md={5} lg={5} xs={1}>
              <PerformanceTrack
                performance={performance}
                events={events}
                selectedEvent={selectedEvent}
              />
            </Grid>
            <Grid item sm={1} md={3} lg={3} xs={1}>
              <FeelLevelTrack influence={influence} />
            </Grid>
            <Grid item sm={1} md={2} lg={2} xs={1}>
              <InfluencerTrack
                influence={influence}
                dayDifference={dayDifference}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={1} md={1} lg={1} xs={1}>
          <Grid container columns={{ xs: 1, sm: 1, md: 5, lg: 5 }} spacing={2}>
            <Grid item sm={1} md={1.9} lg={1.7} xs={1}>
              <PreparationTrack preparation={preparation} />
            </Grid>
            <Grid item sm={1} md={3.1} lg={3.3} xs={1}>
              <Biometrics biometricData={biometricData} />
            </Grid>
            <Grid item sm={1} md={3} lg={3} xs={1}>
              <SleepTimeTrack
                influence={influence}
                dayDifference={dayDifference}
              />
            </Grid>
            <Grid item sm={1} md={2} lg={2} xs={1}>
              <WaterTrack influence={influence} dayDifference={dayDifference} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Analytics;
