import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CommonSpeedMeterIcon,
  CommonTimerIcon,
  commonPlayerRunIcon,
} from "../../../Common/IconsAndComps";
import api from "../../../api/http-common";

const RibbonContainer = ({ performance, events, selectedEvent }) => {
  const [unit, setUnit] = useState("");

  const selectedSubEvent = events?.find(
    (item) => parseInt(item.subEventId) === parseInt(selectedEvent)
  );

  useEffect(() => {
    const getUnit = async () => {
      const id = selectedSubEvent?.unitId;
      if(id !== undefined){
        await api
        .get(`/unit/${id}`)
        .then((res) => {
          setUnit(res?.data);
        })
        .catch((err) => console.log(err));
      }
      
    };
    getUnit();
  }, [selectedSubEvent]);

  const personal_best =
    performance && unit?.unit === "s"
      ? performance?.minPerformance
      : performance?.maxPerformance;

  const lowest =
    performance && unit?.unit === "s"
      ? performance?.maxPerformance
      : performance?.minPerformance;

  const average = (lowest + personal_best) / 2;

  return (
    <Grid
      container
      columnSpacing={5}
      columns={{ xs: 1, sm: 1, md: 3, lg: 3 }}
      sx={{ mt: 3 }}
    >
      <Grid item xs={1} sm={1} md={1} lg={1}>
        <div id="bottom">
          <Box className="ribbon-container">
            <h2 className="ribbon">
              <p
                className="ribbon-content"
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                {personal_best
                  ? `${personal_best} ${personal_best && unit?.unit}`
                  : "No data found"}
              </p>
            </h2>
            <div
              className="underpage"
              style={{
                fontSize: "var(--body-font-size)",
                fontFamily: "var(--font-family)",
              }}
            >
              Best &nbsp; {commonPlayerRunIcon}
            </div>
          </Box>
        </div>
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1}>
        <div id="bottom">
          <header className="ribbon-container">
            <h2 className="no-ribbon">
              <p
                className="no-ribbon-content"
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                {Number.isNaN(average)
                  ? "No data found"
                  : `${average.toFixed(2)} ${unit?.unit}`}
              </p>
            </h2>
            <div
              className="underpage"
              style={{
                fontSize: "var(--body-font-size)",
                fontFamily: "var(--font-family)",
              }}
            >
              Average &nbsp; {CommonTimerIcon}
            </div>
          </header>
        </div>
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1}>
        <div id="bottom">
          <header className="ribbon-container">
            <h2 className="no-ribbon">
              <p
                className="no-ribbon-content"
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                {lowest ? `${lowest} ${lowest && unit?.unit}` : "No data found"}
              </p>
            </h2>
            <div
              className="underpage"
              style={{
                fontSize: "var(--body-font-size)",
                fontFamily: "var(--font-family)",
              }}
            >
              Lowest &nbsp; {CommonSpeedMeterIcon}
            </div>
          </header>
        </div>
      </Grid>
    </Grid>
  );
};

export default RibbonContainer;
