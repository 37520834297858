import { North, South } from "@mui/icons-material";
import React from "react";

export const GetData = {
  performance: {
    columns: [
      { headerClassName: "thead", field: "id", headerName: "S.No", width: 100 },
      {
        headerClassName: "thead",
        field: "userName",
        headerName: "Player",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "subEventName",
        headerName: "Event",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "createDate",
        headerName: "Date",
        width: 150,
      },
      {
        headerClassName: "thead",
        field: "session",
        headerName: "Session",
        width: 100,
        renderCell: (params) => params.row.session,
      },
      {
        headerClassName: "thead",
        field: "performance",
        headerName: "Perf.",
        width: 100,
      },
      {
        headerClassName: "thead",
        field: "singularName",
        headerName: "UOM",
        width: 100,
      },
      {
        headerClassName: "thead",
        field: "arrow",
        headerName: "Perf. Status",
        width: 150,
        renderCell: (params) => params.row.arrow,
      },
      {
        headerClassName: "thead",
        field: "progress",
        headerName: "Progress",
        width: 150,
      },
      {
        headerClassName: "thead",
        field: "personalBest",
        headerName: "Personal Best",
        width: 150,
      },
      // {
      //   headerClassName: "thead",
      //   field: "stateRecordValue",
      //   headerName: "SR",
      //   width: 100,
      // },
      // {
      //   headerClassName: "thead",
      //   field: "nationalRecordValue",
      //   headerName: "NR",
      //   width: 100,
      // },
      // {
      //   headerClassName: "thead",
      //   field: "worldRecordValue",
      //   headerName: "WR",
      //   width: 100,
      // },
      {
        headerClassName: "thead",
        field: "favorite",
        headerName: "Favorite",
        width: 100,
        renderCell: (params) => params.row.favorite,
      },
      {
        headerClassName: "thead",
        field: "action",
        headerName: "Action",
        width: 100,
        renderCell: (param) => param.row.action,
      },
    ],
    playerColumn: [
      { headerClassName: "thead", field: "id", headerName: "S.No", flex: 1 },
      {
        headerClassName: "thead",
        field: "subEventName",
        headerName: "Event",
        flex: 1,
      },
      { headerClassName: "thead", field: "date", headerName: "Date", flex: 1 },
      {
        headerClassName: "thead",
        field: "session",
        headerName: "Session",
        flex: 1,
        renderCell: (params) => params.row.session,
      },
      {
        headerClassName: "thead",
        field: "performance",
        headerName: "Perf.",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "singularName",
        headerName: "UOM",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "arrow",
        headerName: (
          <span>
            <North color="success" /> <South color="error" />
          </span>
        ),
        flex: 1,
        renderCell: (params) => params.row.arrow,
      },
      {
        headerClassName: "thead",
        field: "progress",
        headerName: "Progress",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "recentPerf",
        headerName: "Recent Perf.",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "personalBest",
        headerName: "Personal Best",
        flex: 1,
      },
      { headerClassName: "thead", field: "sr", headerName: "SR", flex: 1 },
      { headerClassName: "thead", field: "nr", headerName: "NR", flex: 1 },
      { headerClassName: "thead", field: "wr", headerName: "WR", flex: 1 },
      {
        headerClassName: "thead",
        field: "action",
        headerName: "Action",
        flex: 1,
        renderCell: (param) => param.row.action,
      },
    ],
  },
  preparation: {
    columns: [
      { headerClassName: "thead", field: "id", headerName: "S.No", width: 150 },
      {
        headerClassName: "thead",
        field: "playerName",
        headerName: "Player",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "createdDate",
        headerName: "Date",
        width: 170,
        renderCell: (params) => params.row.date,
      },
      {
        headerClassName: "thead",
        field: "session",
        headerName: "Session",
        width: 150,
      },
      {
        headerClassName: "thead",
        field: "startTime",
        headerName: "Start Time",
        width: 150,
      },
      {
        headerClassName: "thead",
        field: "endTime",
        headerName: "End Time",
        width: 150,
      },
      {
        headerClassName: "thead",
        field: "percentage",
        headerName: "Percentage",
        width: 150,
        renderCell: (params) => params.row.percentage,
      },
      {
        headerClassName: "thead",
        field: "action",
        headerName: "Action",
        width: 120,
        renderCell: (param) => param.row.action,
      },
    ],
    playerColumns: [
      { headerClassName: "thead", field: "id", headerName: "S.No", flex: 1 },
      {
        headerClassName: "thead",
        field: "date",
        headerName: "Date",
        flex: 1,
        renderCell: (params) => params.row.date,
      },
      {
        headerClassName: "thead",
        field: "session",
        headerName: "Session",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "startTime",
        headerName: "Start Date.",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "endTime",
        headerName: "End Date",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "percentage",
        headerName: "Percentage",
        flex: 1,
        renderCell: (params) => params.row.percentage,
      },
      {
        headerClassName: "thead",
        field: "action",
        headerName: "Action",
        flex: 1,
        renderCell: (param) => param.row.action,
      },
    ],
  },

  medical: {
    columns: [
      { headerClassName: "thead", field: "id", headerName: "S.No", width: 150 },
      {
        headerClassName: "thead",
        field: "name",
        headerName: "Player",
        flex: 2,
      },
      {
        headerClassName: "thead",
        field: "date",
        headerName: "Date",
        width: 150,
        renderCell: (params) => params.row.date,
      },
      {
        headerClassName: "thead",
        field: "incident",
        headerName: "Incident",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "action",
        headerName: "Action",
        width: 100,
        renderCell: (param) => param.row.action,
      },
    ],
  },

  profile: {
    columns: [
      { headerClassName: "thead", field: "id", headerName: "S.No", flex: 1 },
      {
        headerClassName: "thead",
        field: "event",
        headerName: "Event",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "subEvent",
        headerName: "Sub Event",
        flex: 1,
      },
      {
        headerClassName: "thead",
        field: "action",
        headerName: "Action",
        flex: 1,
        renderCell: (param) => param.row.action,
      },
    ],
  },
  combinationAdd: [
    { headerClassName: "thead", field: "id", headerName: "S.No", width: 150 },
    {
      headerClassName: "thead",
      field: "exercise",
      headerName: "Exercise",
      flex: 2,
    },
    { headerClassName: "thead", field: "type", headerName: "Type", flex: 1 },
    {
      headerClassName: "thead",
      field: "unit",
      headerName: "Unit",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "noOfSet",
      headerName: "No. of Sets",
      width: 150,
    },
    { headerClassName: "thead", field: "reps", headerName: "Reps", width: 150 },
    { headerClassName: "thead", field: "rest", headerName: "Rest", width: 150 },
    {
      headerClassName: "thead",
      field: "activeTime",
      headerName: "Active Time",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (param) => param.row.action,
    },
  ],
  combination: [
    { headerClassName: "thead", field: "id", headerName: "S.No", width: 120 },
    {
      headerClassName: "thead",
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "combination",
      headerName: "Combination",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "activity",
      headerName: "Activity",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (param) => param.row.status,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (param) => param.row.action,
    },
  ],
  eventMaster: [
    { headerClassName: "thead", field: "id", headerName: "S.No", width: 180 },
    {
      headerClassName: "thead",
      field: "subName",
      headerName: "Sub Event Name",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "unit",
      headerName: "Unit",
      width: 180,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      width: 180,
      renderCell: (param) => param.row.action,
    },
  ],
  academyList: [
    { headerClassName: "thead", field: "id", headerName: "S.No", width: 150 },
    {
      headerClassName: "thead",
      field: "academyName",
      headerName: "Academy Name",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (param) => param.row.action,
    },
  ],

  academyDataList: [
    { headerClassName: "thead", field: "id", headerName: "S.No", width: 150 },
    {
      headerClassName: "thead",
      field: "loginId",
      headerName: "Login ID",
      width: 180,
    },
    {
      headerClassName: "thead",
      field: "userName",
      headerName: "User Name",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "role",
      headerName: "Role",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (param) => param.row.action,
    },
  ],
  record_master: [
    { headerClassName: "thead", field: "id", headerName: "S.No", width: 150 },
    {
      headerClassName: "thead",
      field: "event",
      headerName: "Event",
      width: 180,
    },
    {
      headerClassName: "thead",
      field: "subEvent",
      headerName: "Sub Event",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "unit",
      headerName: "Unit",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "gender",
      headerName: "Gender",
      width: 160,
    },
    {
      headerClassName: "thead",
      field: "world",
      headerName: "World",
      width: 160,
    },
    {
      headerClassName: "thead",
      field: "national",
      headerName: "National",
      width: 160,
    },
    {
      headerClassName: "thead",
      field: "state",
      headerName: "State",
      width: 160,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (param) => param.row.action,
    },
  ],
  player_grouping: [
    { headerClassName: "thead", field: "id", headerName: "S.No", width: 150 },
    {
      headerClassName: "thead",
      field: "group_name",
      headerName: "Group Name",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "player_count",
      headerName: "Count",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "players",
      headerName: "Players",
      width: 150,
      renderCell: (param) => param.row.players,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (param) => param.row.action,
    },
  ],
  add_player_grouping: [
    { headerClassName: "thead", field: "id", headerName: "S.No", width: 150 },
    {
      headerClassName: "thead",
      field: "playerName",
      headerName: "Player Name",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (param) => param.row.action,
    },
  ],
  player_profile: [
    { headerClassName: "thead", field: "id", headerName: "S.No", width: 120 },
    {
      headerClassName: "thead",
      field: "event",
      headerName: "Event",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "subEvent",
      headerName: "Sub Event",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (param) => param.row.action,
    },
  ],
  preparation_view: [
    {
      headerClassName: "thead",
      field: "id",
      headerName: "S.No",
      width: 140,
    },
    {
      headerClassName: "thead",
      field: "exercise_name",
      headerName: "Exercise Name",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "type",
      headerName: "Type",
      width: 140,
    }, {
      headerClassName: "thead",
      field: "unit",
      headerName: "Unit",
      width: 140,
    },
    {
      headerClassName: "thead",
      field: "assignSet",
      headerName: "Assign Set",
      width: 140,
    },
    {
      headerClassName: "thead",
      field: "actualSet",
      headerName: "Act Set",
      width: 140,
    },
    {
      headerClassName: "thead",
      field: "assignRep",
      headerName: "Assign Rep",
      width: 140,
    },
    {
      headerClassName: "thead",
      field: "actualRep",
      headerName: "Actual Rep",
      width: 140,
    },
    {
      headerClassName: "thead",
      field: "assignRest",
      headerName: "Assign Rest",
      width: 140,
    },
    {
      headerClassName: "thead",
      field: "actualRest",
      headerName: "Actual Rest",
      width: 140,
    },
    {
      headerClassName: "thead",
      field: "assignTime",
      headerName: "Assign Time",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "actualTime",
      headerName: "Actual Time",
      width: 150,
    },
  ],
  assignPreparationData: [
    {
      headerClassName: "thead",
      field: "id",
      headerName: "S.No",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "exercise",
      headerName: "Exercise",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "type",
      headerName: "Type",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "unit",
      headerName: "Unit",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "NoOfSet",
      headerName: "No. of Sets",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "reps",
      headerName: "Reps",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "activeTime",
      headerName: "Active Time",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "rest",
      headerName: "Rest",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (param) => param.row.action,
    },
  ],
  academyCoachData: [
    {
      headerClassName: "thead",
      field: "loginId",
      headerName: "Login ID",
      width: 180,
    },
    {
      headerClassName: "thead",
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "joinedDate",
      headerName: "Joined Date",
      width: 180,
      renderCell: (param) => param.row.joinedDate,
    },
    {
      headerClassName: "thead",
      field: "noOfPlayersAssigned",
      headerName: "No. of Players Assigned",
      width: 250,
      renderCell: (param) => param.row.noOfPlayersAssigned,
    },
    {
      headerClassName: "thead",
      field: "playersAssigned",
      headerName: "Players Assigned",
      width: 180,
      renderCell: (param) => param.row.playersAssigned,
    },
  ],
  playerColumns: [
    {
      headerClassName: "thead",
      field: "loginId",
      headerName: "Login ID",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "joinedDate",
      headerName: "Joined Date",
      width: 180,
      renderCell: (param) => param.row.joinedDate,
    },
    {
      headerClassName: "thead",
      field: "coachAssigned",
      headerName: "Coach Assigned",
      width: 180,
      renderCell: (param) => param.row.coachAssigned,
    },
    // {
    //   headerClassName: "thead",
    //   field: "moreInfo",
    //   headerName: "More Info",
    //   width: 150,
    //   renderCell: (param) => param.row.moreInfo,
    // },
  ],
  playerMigrateData: [
    {
      headerClassName: "thead",
      field: "loginId",
      headerName: "Login ID",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "coach",
      headerName: "Coach",
      flex: 1,
      renderCell: (param) => param.row.coach,
    },
  ],
  coachMigrateData: [
    {
      headerClassName: "thead",
      field: "loginId",
      headerName: "Login ID",
      width: 180,
    },
    {
      headerClassName: "thead",
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "coach",
      headerName: "Coach",
      width: 300,
      renderCell: (param) => param.row.coach,
    },
  ],
  view_player: [
    {
      headerClassName: "thead",
      field: "id",
      headerName: "Player ID",
      width: 150,
    },
    {
      headerClassName: "thead",
      field: "playerName",
      headerName: "Player Name",
      flex: 1,
    },
  ],
  event_user_data: [
    {
      headerClassName: "thead",
      field: "id",
      headerName: "S.No",
      width: 75,
    },
    {
      headerClassName: "thead",
      field: "event",
      headerName: "Event",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "subEvent",
      headerName: "Sub Event",
      flex: 1,
    },
    {
      headerClassName: "thead",
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (param) => param.row.action,
    },
  ],
};
