import {
  CalendarMonth,
  CheckCircleOutline,
  CloudUpload,
  Dangerous,
  Download,
  SettingsAccessibility,
} from "@mui/icons-material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Modal,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import { useReactToPrint } from "react-to-print";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import Style from "../../Common/Components/Styles";
import ToastMessage from "../../Common/Components/ToastMessage";
import { RadioBtnFilter } from "../../Common/Components/dateFilter/RadioBtnFilter";
import Extension from "../../Common/Extension";
import api from "../../api/http-common";
import activeIcon from "../../assets/active.png";
import madIcon from "../../assets/angry.png";
import boredIcon from "../../assets/bored.png";
import happinessIcon from "../../assets/happiness.png";
import noDataIcon from "../../assets/noData.gif";
import recoverIcon from "../../assets/recovered.png";
import sadIcon from "../../assets/sad.png";
import sickIcon from "../../assets/sick.png";
import stressedIcon from "../../assets/stressed.png";
import EnergyChart from "../common/EnergyChart";
import ProgressChart from "../common/ProgressChart";
import FeelLevelTrack from "./Analyticals/TrackCharts/FeelLevelTrack";
import HealthLevelTrack from "./Analyticals/TrackCharts/HealthLevelTrack";
import MoodLevelTracking from "./Analyticals/TrackCharts/MoodLevelTracking";
import SleepTimeTrack from "./Analyticals/TrackCharts/SleepTimeTrack";
import WaterTrack from "./Analyticals/TrackCharts/WaterTrack";
import logo from "../../assets/logo.png";
import ExcelJS from "exceljs";
import saveAs from "save-as";

const StyledTypography = styled(Typography)({
  display: "flex",
  alignItems: "center",
  // backgroundColor: "#80808052",
  fontSize: "50px",
  borderRadius: "50%",
});

const StyledFoodTypography = styled(Typography)({
  display: "block",
  marginBottom: "4px",
  backgroundColor: "#012a4a",
  color: "white",
  borderRadius: "5px",
  padding: "5px",
  textAlign: "center",
});

const StyledCard = styled(Card)({
  width: "100%",
  // minWidth: 275,
  overflowY: "auto",
  // maxHeight: "700px",
  margin: "0px 15px 15px 0px",
  background: "var(--influencerBackground)",
  border: "1px solid #2196f352",
  borderRadius: "12px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const Influencer = () => {
  const userId = sessionStorage.getItem("userId");
  const [selectPlayer, setSelectPlayer] = useState("");
  const [player, setPlayer] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("all");
  const { toast, contextHolder } = ToastMessage();
  const [selectedPlayerData, setSelectedPlayerData] = useState({});

  const academyId = sessionStorage.getItem("academyId");

  useEffect(() => {
    const getData = async () => {
      try {
        toast("loading", "Loading...");
        const response = await api.get("/users/coachId/" + userId);
        setPlayer(response.data);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
        setLoading(false);
      }
    };

    getData();
  }, []);

  // ------------------ Date Filter Start -----------------------

  const today = new Date();

  const currentDay = format(today, "yyyy-MM-dd");

  const monthly = format(
    new Date(today.getFullYear(), today.getMonth(), 1),
    "yyyy-MM-dd"
  );

  const yearly = format(new Date(today.getFullYear(), 0, 1), "yyyy-MM-dd");
  const weekly = format(
    new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 1
    ),
    "yyyy-MM-dd"
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [period, setPeriod] = useState("all");

  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(monthly),
    endDate: new Date(),
    key: "selection",
  });

  const handleDateRangeChange = (dateRange) => {
    setSelectedRange(dateRange.selection);
    setPeriod("custom");
  };

  const [biometric, setBiometric] = useState([]);

  useEffect(() => {
    const getInfluence = async () => {
      const API = `/influenceHeader/findDate/${selectPlayer}/${format(
        selectedRange.startDate,
        "yyyy-MM-dd"
      )}/${format(selectedRange.endDate, "yyyy-MM-dd")}`;
      console.log("API", API);
      await api
        .get(API)
        .then((res) => {
          setFilteredData(res.data);
        })
        .catch((err) => console.log(err));

      const biometricAPI = `/biometric/findDate/${selectPlayer}/${format(
        selectedRange.startDate,
        "yyyy-MM-dd"
      )}/${format(selectedRange.endDate, "yyyy-MM-dd")}`;
      console.log("API", API);
      await api
        .get(biometricAPI)
        .then((res) => {
          setBiometric(res.data);
        })
        .catch((err) => console.log(err));
    };
    getInfluence();
  }, [
    selectedRange.endDate,
    selectedRange.startDate,
    selectedOption,
    selectPlayer,
  ]);

  console.log("biometricData", biometric);

  useEffect(() => {
    console.log(period);
    if (period === "monthly") {
      setSelectedRange({
        startDate: new Date(monthly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "weekly") {
      setSelectedRange({
        startDate: new Date(weekly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "yearly") {
      setSelectedRange({
        startDate: new Date(yearly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "today") {
      setSelectedRange({
        startDate: new Date(currentDay),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (period === "all") {
      setSelectedRange({
        startDate: new Date("2010-01-01"),
        endDate: new Date(currentDay),
        key: "selection",
      });
    }
  }, [currentDay, monthly, period, weekly, yearly]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // ------------------ Date Filter End -----------------------

  // ------------------ Influencer Report Generate Start -----------------------

  const componentPdf = useRef();

  const [isVisible, setIsVisible] = useState(false);

  const handleBeforePrint = () => {
    setIsVisible(true);
  };

  const handleAfterPrint = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (isVisible) {
      generateInvoice();
    }
  }, [isVisible]);

  const generateInvoice = useReactToPrint({
    content: () => componentPdf.current,
    documentTitle: "Influencer Data",
    onAfterPrint: handleAfterPrint,
  });
  const handlePrint = () => {
    handleBeforePrint();
    setTimeout(() => {
      generateInvoice();
    }, 100);
  };

  const [pdfPlayer, setPdfPlayer] = useState({});

  useEffect(() => {
    if (selectPlayer !== "all") {
      const pdfData = player.find((user) => user.userId === selectPlayer);
      setPdfPlayer(pdfData);
    }
  }, [selectPlayer]);

  console.log("pdfPlayer", pdfPlayer);

  // ------------------ Influencer Report Generate End -----------------------

  // ------------------ Biometric Report Generate Start -----------------------

  const biometricComponentPdf = useRef();

  const [isBiometricVisible, setIsBiometricVisible] = useState(false);

  const handleBiometricBeforePrint = () => {
    setIsBiometricVisible(true);
  };

  const handleBiometricAfterPrint = () => {
    setIsBiometricVisible(false);
  };

  useEffect(() => {
    if (isBiometricVisible) {
      generateBiometricInvoice();
    }
  }, [isBiometricVisible]);

  const generateBiometricInvoice = useReactToPrint({
    content: () => biometricComponentPdf.current,
    documentTitle: "Influencer Data",
    onAfterPrint: handleBiometricAfterPrint,
  });
  const handleBiometricPrint = () => {
    handleBiometricBeforePrint();
    setTimeout(() => {
      generateBiometricInvoice();
    }, 100);
  };

  // ------------------ Biometric Report Generate End -----------------------

  function handleUserId(e) {
    setSelectPlayer(e.target.value);
    const player_data = player.find((item) => item.userId === e.target.value);
    setSelectedPlayerData(player_data);
    setSelectedRange({
      startDate: new Date(player_data?.createDate || "2023-01-01"),
      endDate: new Date(currentDay),
      key: "selection",
    });
  }

  useEffect(() => {
    if (player) {
      setSelectPlayer(player[0]?.userId);
      const player_data = player.find(
        (item) => item.userId === player[0]?.userId
      );
      setSelectedPlayerData(player_data);
      setSelectedRange({
        startDate: new Date(player_data?.createDate || "2023-01-01"),
        endDate: new Date(currentDay),
        key: "selection",
      });
    }
  }, [player]);

  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const clearTextField = () => {
    setSelectedFile(null);
  };

  const handleBulkUploadOpen = () => {
    setBulkUploadOpen(true);
  };
  const handleBulkUploadClose = () => {
    setBulkUploadOpen(false);
    clearTextField();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    if (file) {
      if (file.name.endsWith(".xlsx")) {
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
      }
    } else {
      setSelectedFile(null);
    }

    if (fileInputRef.current) {
      // Reset the input value to clear the selected file
      fileInputRef.current.value = "";
    }
  };

  async function handleBulkUpload() {
    console.log("selectedFile", selectedFile);
    if (selectedFile) {
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      const data = { file: selectedFile };
      await api
        .post(
          `/influenceHeader/bulkUpload/${academyId}/${userId}`,
          data,
          config
        )
        .then(() => {
          toast("success", "Bulk Upload Completed Successfully..");
          // window.location.reload();
        })
        .catch((err) => {
          toast("error", "Something went Wrong..");
          console.log(err);
        });
    }
    handleBulkUploadClose();
  }

  function setCellProperties(
    cell,
    value,
    isBold = false,
    color = "000000",
    size = 11,
    width = 200,
    horizontal = "center"
  ) {
    cell.value = value;
    cell.width = width;
    cell.font = { bold: isBold, color: { argb: color }, size: size };
    cell.alignment = { vertical: "middle", horizontal: horizontal };
  }

  const handleSampleExcel = async () => {
    const sampleData = [
      "Player Login ID", //A
      "Sleep", //B
      "Mood Level", //C
      "Energy Level", //D
      "Health", //E
      "Feel", //F
      "Breakfast", //G
      "Lunch", //H
      "Dinner", //I
      "Snack", //J
      "Water", //K
      "Created Date", //L
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Influence Data");
    const optionsSheet = workbook.addWorksheet("Options");

    worksheet.addRow(sampleData);

    const players_data = player.map((item) => item.userName);
    const players_logId = player.map((item) => item.loginId);

    setCellProperties(
      optionsSheet.getCell("A1"),
      "Player Login ID",
      true,
      "FF0000",
      12
    );
    setCellProperties(
      optionsSheet.getCell("C1"),
      "Player Name",
      true,
      "FF0000",
      12
    );

    players_logId.forEach((item, index) => {
      optionsSheet.getCell(`A${index + 2}`).value = item;
    });

    players_data.forEach((item, index) => {
      optionsSheet.getCell(`C${index + 2}`).value = item;
    });

    setCellProperties(
      worksheet.getCell("A2"),
      "Get From Option Sheet",
      true,
      "FF0000",
      12
    );

    const TRUE_FALSE = ["Yes", "No"];
    const HEALTH = ["Active", "Sick", "Just Recovered"];
    const FEEL = ["MAD", "STRESSED", "SAD", "BORED", "HAPPY"];
    const truefalseValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`"${TRUE_FALSE.join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Option",
      error: "Please select a valid option from the dropdown",
    };

    const validation = (list) => {
      return {
        type: "list",
        allowBlank: true,
        formulae: [`"${list.join(",")}"`],
        showErrorMessage: true,
        errorTitle: "Invalid Option",
        error: "Please select a valid option from the dropdown",
      };
    };

    ["G", "H", "I", "J"].forEach((col) => {
      worksheet.getCell(`${col}2`).dataValidation = truefalseValidation;
    });

    const percentages = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    const level = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const titles = ["Parameter", "Parameter Desc.", "Unit", "Range"];
    const value = [
      [
        "Sleep",
        "Sleep time of the player on the particular day",
        "hrs.",
        "01 to 12",
      ],
      [
        "Mood Level",
        "Mood level of the player on the particular day",
        "%",
        "0 to 100",
      ],
      [
        "Energy Level",
        "Energy level of the player on the particular day",
        "%",
        "0 to 100",
      ],
      [
        "Water",
        "Water consumption of the player on the particular day",
        "ltrs.",
        "01 to 10",
      ],
    ];

    ["F", "G", "H", "I"].forEach((col, colIndex) => {
      setCellProperties(
        optionsSheet.getCell(`${col}1}`),
        titles[colIndex],
        true,
        "FF0000",
        12
      );

      value.forEach((row, rowIndex) => {
        setCellProperties(
          optionsSheet.getCell(`${col}${rowIndex + 2}}`),
          row[colIndex],
          false,
          "000000",
          12
        );
      });
    });

    ["C", "D"].forEach((col) => {
      worksheet.getCell(`${col}2`).dataValidation = validation(percentages);
    });

    ["B", "K"].forEach((col) => {
      worksheet.getCell(`${col}2`).dataValidation = validation(level);
    });

    worksheet.getCell("A2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`Options!$A$2:$A$${players_logId.length + 1}`],
      showErrorMessage: true,
      errorTitle: "Invalid Value",
      error: "Please select a valid value from the list",
    };

    worksheet.getCell("E2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`"${HEALTH.join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Value",
      error: "Please select a valid value from the list",
    };

    worksheet.getCell("F2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`"${FEEL.join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Value",
      error: "Please select a valid value from the list",
    };

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Save the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "XIP Influence Bulk Upload Sample.xlsx");
  };

  // -------------------------------------

  const [biometricUploadOpen, setBiometricUploadOpen] = useState(false);
  const [selectedBiometricFile, setSelectedBiometricFile] = useState(null);
  const fileInputRefBiometric = useRef(null);

  const clearBiometricTextField = () => {
    setSelectedBiometricFile(null);
  };

  const handleBiometricBulkUploadOpen = () => {
    setBiometricUploadOpen(true);
  };
  const handleBiometricBulkUploadClose = () => {
    setBiometricUploadOpen(false);
    clearBiometricTextField();
  };

  const handleBiometricFileChange = (e) => {
    const file = e.target.files[0];
    console.log("file1", file);
    if (file) {
      if (file.name.endsWith(".xlsx")) {
        setSelectedBiometricFile(file);
      } else {
        setSelectedBiometricFile(null);
      }
    } else {
      setSelectedBiometricFile(null);
    }

    if (fileInputRefBiometric.current) {
      // Reset the input value to clear the selected file
      fileInputRefBiometric.current.value = "";
    }
  };

  async function handleBiometricBulkUpload() {
    console.log("selectedBiometricFile", selectedBiometricFile);
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const data = { file: selectedBiometricFile };
    const fileUP = await api
      .post(`/biometric/bulkUpload/${academyId}/${userId}`, data, config)
      .then((res) => toast("success", "Bulk Upload Completed Successfully.."))
      .catch((err) => {
        toast("error", "Something went Wrong..");
        console.log(err);
      });
    handleBiometricBulkUploadClose();
    // window.location.reload()
  }
  // dfe
  const handleBiometricSampleExcel = async () => {
    const sampleData = [
      "Player Login ID", //A
      "Date", //B
      "Height(cm)", //C
      "Weight(kg)", //D
      "Ankle(cm)", //E
      "Hip(cm)", //F
      "Resting Heart Rate(BPM)", //G
      "Targeted Heart Rate(BPM)", //H
      "Body Temperature Before(F)", //I
      "Body Temperature After(F)", //J
      "BP Before(MmHg)", //K
      "BP After(MmHg)", //l
      "BMI(kg/m2)",
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Influence Data");
    const optionsSheet = workbook.addWorksheet("Options");

    const players_data = player.map((item) => item.userName);
    const players_logId = player.map((item) => item.loginId);

    worksheet.addRow(sampleData);

    players_logId.forEach((item, index) => {
      optionsSheet.getCell(`A${index + 2}`).value = item;
    });

    players_data.forEach((item, index) => {
      optionsSheet.getCell(`C${index + 2}`).value = item;
    });

    worksheet.getCell("A2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`Options!$A$2:$A$${players_logId.length + 1}`],
      showErrorMessage: true,
      errorTitle: "Invalid Value",
      error: "Please select a valid value from the list",
    };

    // const details = [
    //   "Weight(kg) should be within 90kg",
    //   "Height(cm) should be within 250cm",
    //   "Ankle(cm) range between 20cm to 50cm",
    //   "Hip(cm) range between 30cm to 70cm",
    //   "Heart Rate(BPM) range between 20BPM to 50BPM",
    //   "Temperature(°F) range between 97°F to 99°F",
    //   "Blood Pressure(MmHg) range between 90MmHg to 120MmHg",
    // ];

    const titles = ["Parameter", "Parameter Desc.", "Unit", "Range"];
    const value = [
      [
        "Weight",
        "Weight of the player on that particular day",
        "kg",
        "max 90kg",
      ],
      [
        "Height",
        "Height of the player on that particular day",
        "cm",
        "max 250cm",
      ],
      [
        "Ankle",
        "Knee to ankle length of the player on that particular day",
        "cm",
        "20cm to 50cm",
      ],
      [
        "Hip",
        "Hip to knee length of the player on that particular day",
        "cm",
        "30cm to 70cm",
      ],
      [
        "BP Before(MmHg)",
        "BP before the trail",
        "MmHg",
        "90 to 120",
      ],
      [
        "BP After(MmHg)",
        "BP after the trail",
        "MmHg",
        "90 to 120",
      ],
      [
        "Resting Heart Rate(BPM)",
        "Resting heart rate of the player",
        "BPM",
        "60 to 120",
      ],
      [
        "Targeted Heart Rate(BPM)",
        "Targeted heart rate of the player",
        "BPM",
        "60 to 120",
      ],
      [
        "Body Temperature Before(F)",
        "Body temperature of the player before trail",
        "F",
        "97 to 99",
      ],
      [
        "Body Temperature After(F)",
        "Body temperature of the player after trail",
        "F",
        "97 to 99",
      ],
      [
        "BMI(kg/m2)",
        "BMI calculation for the player based on his height and weight",
        "(kg/m2)",
        "18 to 30",
      ],
    ];

    ["F", "G", "H", "I"].forEach((col, colIndex) => {
      setCellProperties(
        optionsSheet.getCell(`${col}1}`),
        titles[colIndex],
        true,
        "FF0000",
        12
      );

      value.forEach((row, rowIndex) => {
        setCellProperties(
          optionsSheet.getCell(`${col}${rowIndex + 2}}`),
          row[colIndex],
          false,
          "000000",
          12
        );
      });
    });

    // details.forEach((item, index) => {
    //   setCellProperties(
    //     optionsSheet.getCell(`E${index + 1}`),
    //     item,
    //     false,
    //     "000000",
    //     12,
    //     500,
    //     "start"
    //   );
    // });

    setCellProperties(
      optionsSheet.getCell("A1"),
      "Player Login ID",
      true,
      "FF0000",
      12
    );
    setCellProperties(
      optionsSheet.getCell("C1"),
      "Player Name",
      true,
      "FF0000",
      12
    );

    setCellProperties(
      worksheet.getCell("A2"),
      "Get From Option Sheet",
      true,
      "FF0000",
      12
    );

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Save the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "XIP Biometric Bulk Upload Sample.xlsx");
  };

  const coachName = sessionStorage.getItem("userName");

  const [dayDifference, setDayDifference] = useState(0);

  useEffect(() => {
    setDayDifference(
      Extension.timeDifference(
        format(selectedRange.endDate, "yyyy-MM-dd"),
        format(selectedRange.startDate, "yyyy-MM-dd")
      )
    );
  }, [selectedRange.endDate, selectedRange.startDate]);

  // const location = useLocation();
  // sessionStorage.setItem('location',location.pathname)

  return (
    <Box className="common-wrapper">
      {/* <Paper> */}
      {contextHolder}
      <Box
        sx={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          padding: "10px",
        }}
      >
        <Grid
          container
          spacing={2}
          columns={{ xs: 1, sm: 1, md: 1, lg: 4, xl: 4 }}
          pb={3}
        >
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <CommonDropdown
              // firstOption={"All"}
              // search={true}
              label={"Filter By Player"}
              value={selectPlayer}
              onChange={handleUserId}
              array={player}
              menuValue={"userId"}
              display={"userName"}
            />
          </Grid>
          <Grid
            item
            xs={1}
            md={1}
            lg={1}
            xl={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RadioBtnFilter handleRadioChange={(data) => setPeriod(data)} />
            <Box sx={{ ml: 2 }}>
              <div role="button" onClick={handleClick} className="icon-button">
                <CalendarMonth />
              </div>
              {/* <CustomDateRangePicker /> */}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <DateRange
                  editableDateInputs
                  onChange={handleDateRangeChange}
                  minDate={
                    new Date(selectedPlayerData?.createDate || "2023-01-01")
                  }
                  maxDate={new Date(currentDay)}
                  moveRangeOnFirstSelection={false}
                  ranges={[selectedRange]}
                />
              </Popover>
            </Box>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "var(--font-family)",
                  color: "var(--app-bar-color)",
                  fontWeight: "var(--font-weight)",
                  fontSize: "var(--header-font-size)",
                  margin: "0 auto",
                  borderBottom: "1px solid var(--app-bar-color)",
                  borderRadius: "2px",
                  // mb: 3,
                }}
              >
                Number Of Entries:{" "}
                {Array.isArray(filteredData) &&
                  filteredData &&
                  filteredData?.length}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                height: "100%",
              }}
            >
              <Box pr={2}>
                <Box sx={{ position: "relative", height: "100%" }}>
                  <CustomCommonButton
                    icon={<SettingsAccessibility />}
                    onClick={handleBulkUploadOpen}
                    tooltipText="Influencer Bulk Upload"
                  />
                  <Box
                    sx={{ position: "absolute", bottom: "-5px", right: "4px" }}
                  >
                    <CloudUpload
                      sx={{
                        backgroundColor: "unset",
                        color: "white",
                        opacity: 0.5,
                        fontSize: "18px",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ position: "relative", height: "100%" }}>
                  <CustomCommonButton
                    icon={<FingerprintIcon />}
                    onClick={handleBiometricBulkUploadOpen}
                    tooltipText="Biometric Bulk Upload"
                  />
                  <Box
                    sx={{ position: "absolute", bottom: "-5px", right: "4px" }}
                  >
                    <CloudUpload
                      sx={{
                        backgroundColor: "unset",
                        color: "white",
                        opacity: 0.5,
                        fontSize: "18px",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ ml: 2 }}>
                <Box sx={{ position: "relative", height: "100%" }}>
                  <CustomCommonButton
                    tooltipText="Influencer Report"
                    icon={<SettingsAccessibility />}
                    onClick={handlePrint}
                  />
                  <Box
                    sx={{ position: "absolute", bottom: "-5px", right: "3px" }}
                  >
                    <Download
                      sx={{
                        backgroundColor: "unset",
                        color: "white",
                        opacity: 0.5,
                        fontSize: "18px",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ ml: 2 }}>
                <Box sx={{ position: "relative", height: "100%" }}>
                  <CustomCommonButton
                    tooltipText="Biometric Report"
                    icon={<FingerprintIcon />}
                    onClick={handleBiometricPrint}
                  />
                  <Box
                    sx={{ position: "absolute", bottom: "-5px", right: "3px" }}
                  >
                    <Download
                      sx={{
                        backgroundColor: "unset",
                        color: "white",
                        opacity: 0.5,
                        fontSize: "18px",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            md={1}
            lg={0.5}
            xl={0.5}
            sx={{ display: "flex", justifyContent: "center" }}
          ></Grid>
        </Grid>
      </Box>
      <Modal keepMounted open={bulkUploadOpen} onClose={handleBulkUploadClose}>
        <Box sx={Style.widthPopupStyle}>
          <Grid
            container
            columns={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}
            spacing={2}
          >
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <Typography variant="h4" sx={Style.titleText}>
                Influencer Bulk Upload
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <CustomCommonButton
                text={"Upload File"}
                onClick={() => document.getElementById("file-upload").click()}
                icon={<CloudUpload />}
                fullWidth={"100%"}
              />
              <input
                type="file"
                id="file-upload"
                onChange={handleFileChange}
                accept=".xlsx"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={3}
              lg={3}
              xl={3}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="p"
                gutterBottom
                sx={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                {selectedFile
                  ? `Selected File: ${selectedFile.name}`
                  : "No File Selected"}
              </Typography>
            </Grid>

            <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
              <CustomCommonButton
                text={"Sample Excel"}
                onClick={handleSampleExcel}
                icon={<Download />}
                fullWidth={"100%"}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
              <CustomCommonButton
                text={"Upload"}
                onClick={handleBulkUpload}
                disabled={!selectedFile}
                fullWidth={"100%"}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={biometricUploadOpen}
        onClose={handleBiometricBulkUploadClose}
      >
        <Box sx={Style.widthPopupStyle}>
          <Grid
            container
            columns={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}
            spacing={2}
          >
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <Typography variant="h4" sx={Style.titleText}>
                Biometric Bulk Upload
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <CustomCommonButton
                text={"Upload File"}
                onClick={() => document.getElementById("file-upload1").click()}
                icon={<CloudUpload />}
                fullWidth={"100%"}
              />
              <input
                type="file"
                id="file-upload1"
                onChange={handleBiometricFileChange}
                accept=".xlsx"
                style={{ display: "none" }}
                ref={fileInputRefBiometric}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={3}
              lg={3}
              xl={3}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="p"
                gutterBottom
                sx={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                {selectedBiometricFile
                  ? `Selected File: ${selectedBiometricFile.name}`
                  : "No File Selected"}
              </Typography>
            </Grid>

            <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
              <CustomCommonButton
                text={"Sample Excel"}
                onClick={handleBiometricSampleExcel}
                icon={<Download />}
                fullWidth={"100%"}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
              <CustomCommonButton
                text={"Upload"}
                onClick={handleBiometricBulkUpload}
                disabled={!selectedBiometricFile}
                fullWidth={"100%"}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Box sx={{ ml: 1, mr: 1 }}>
        <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 3, lg: 3 }}>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Grid
              container
              spacing={2}
              columns={{ xs: 1, sm: 1, md: 1, lg: 1 }}
              sx={{ height: "75vh" }}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <FeelLevelTrack influence={filteredData} />
              </Grid>

              <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
                <WaterTrack
                  influence={filteredData}
                  dayDifference={dayDifference}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
                <Box className="biometric-container">
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontWeight: "var(--font-weight)",
                      fontSize: "var(--header-font-size)",
                      mb: 3,
                    }}
                  >
                    Average Energy
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "80%",
                    }}
                  >
                    <ProgressChart label="Energy" filteredData={filteredData} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={0.9}
            lg={0.9}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box sx={{ overflowY: "auto", width: "100%", height: "74vh" }}>
              {loading ? (
                <StyledCard key={player.influenceHeaderId}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mt: "60%",
                        }}
                      >
                        <Box>
                          <CircularProgress
                            /* sx={{color:'blue'}} */ size="3rem"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </StyledCard>
              ) : selectPlayer && filteredData.length > 0 ? (
                filteredData.map((player) => (
                  <StyledCard key={player.influenceHeaderId}>
                    <CardContent
                      sx={{ height: "680px" /* , maxWidth: '500px'  */ }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box>
                            <Typography
                              variant="h4"
                              style={{
                                fontWeight: 700,
                                textDecoration: "underline",
                                fontSize: "var(--header-font-size)",
                                fontFamily: "var(--font-family)",
                              }}
                            >
                              {Extension.convertDateFormatToDMY(
                                player.createdDate
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: 500,
                              padding: "10px",
                              borderRadius: "8px",
                            }}
                            gutterBottom
                          >
                            <StyledTypography variant="body2">
                              {player.feel.toLowerCase() === "happy" ? (
                                <img
                                  style={{
                                    width: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                  }}
                                  src={happinessIcon}
                                  alt=""
                                />
                              ) : player.feel.toLowerCase() === "mad" ? (
                                <img
                                  style={{
                                    width: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                  }}
                                  src={madIcon}
                                  alt=""
                                />
                              ) : player.feel.toLowerCase() === "sad" ? (
                                <img
                                  style={{
                                    width: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                  }}
                                  src={sadIcon}
                                  alt=""
                                />
                              ) : player.feel.toLowerCase() === "stressed" ? (
                                <img
                                  style={{
                                    width: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                  }}
                                  src={stressedIcon}
                                  alt=""
                                />
                              ) : player.feel.toLowerCase() === "bored" ? (
                                <img
                                  style={{
                                    width: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                  }}
                                  src={boredIcon}
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </StyledTypography>

                            <StyledTypography variant="body2">
                              {player.health.toLowerCase() === "active" ? (
                                <img
                                  style={{
                                    width: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                  }}
                                  src={activeIcon}
                                  alt=""
                                />
                              ) : player.health.toLowerCase() ===
                                "just recovered" ? (
                                <img
                                  style={{
                                    width: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                  }}
                                  alt=""
                                  src={recoverIcon}
                                />
                              ) : player.health.toLowerCase() === "sick" ? (
                                <img
                                  style={{
                                    width: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                  }}
                                  src={sickIcon}
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </StyledTypography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Box
                            style={{
                              backgroundColor: "rgb(1 42 74 / 90%)",
                              padding: "15px",
                              borderRadius: "8px",
                              mt: 1,
                              color: "white",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              overflow: "hidden",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "var(--body-font-size)",
                                  fontFamily: "var(--font-family)",
                                }}
                              >
                                Total Time in Bed
                              </Typography>
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  marginTop: "5px",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <Typography
                                    variant="h3"
                                    sx={{
                                      fontWeight: 700,
                                      fontSize: "var(--sleep-font-size)",
                                      fontFamily: "var(--font-family)",
                                    }}
                                  >
                                    {player.sleep}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      marginRight: "10px",
                                      marginLeft: "3px",
                                      fontSize: "var(--sleep-hr-font-size)",
                                      fontFamily: "var(--font-family)",
                                    }}
                                  >
                                    hrs
                                  </Typography>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <Typography
                                    variant="h3"
                                    sx={{
                                      fontWeight: 700,
                                      fontSize: "var(--sleep-font-size)",
                                      fontFamily: "var(--font-family)",
                                    }}
                                  >
                                    00
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      marginRight: "10px",
                                      marginLeft: "3px",
                                      fontSize: "var(--sleep-hr-font-size)",
                                      fontFamily: "var(--font-family)",
                                    }}
                                  >
                                    min
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <EnergyChart energyLevel={player.energyLevel} />
                        </Grid>
                        <Grid item xs={12}>
                          <Box style={{ padding: "10px", borderRadius: "8px" }}>
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{
                                fontWeight: 700,
                                fontSize: "var(--header-font-size)",
                                fontFamily: "var(--font-family)",
                              }}
                            >
                              Mood Level
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "8px",
                                mt: 1,
                                width: "100%",
                              }}
                            >
                              <div className="progress">
                                <div
                                  className="progress-done"
                                  style={{
                                    opacity: 1,
                                    width: `${player.moodLevel}%`,
                                    fontSize: "var(--body-font-size)",
                                    fontFamily: "var(--font-family)",
                                  }}
                                >
                                  {Number(player.moodLevel).toFixed(2)}%
                                </div>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            style={{
                              padding: "10px",
                              borderRadius: "8px",
                              fontSize: "var(--font-size)",
                              fontFamily: "var(--font-family)",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                marginBottom: "8px",
                                fontWeight: 700,
                                fontSize: "var(--header-font-size)",
                                fontFamily: "var(--font-family)",
                              }}
                            >
                              Food
                            </Typography>
                            <Grid container spacing={2}>
                              {player.breakfast === true ||
                              player.lunch === true ||
                              player.dinner === true ||
                              player.snack === true ? (
                                <>
                                  {player.breakfast === true ? (
                                    <Grid item sx={6} md={4}>
                                      <StyledFoodTypography
                                        variant="body2"
                                        style={{
                                          fontSize: "var(--body-font-size)",
                                          fontFamily: "var(--font-family)",
                                        }}
                                      >
                                        Breakfast
                                      </StyledFoodTypography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  {player.lunch === true ? (
                                    <Grid item sx={6} md={4}>
                                      <StyledFoodTypography
                                        variant="body2"
                                        style={{
                                          fontSize: "var(--body-font-size)",
                                          fontFamily: "var(--font-family)",
                                        }}
                                      >
                                        Lunch
                                      </StyledFoodTypography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  {player.dinner === true ? (
                                    <Grid item sx={6} md={4}>
                                      <StyledFoodTypography
                                        variant="body2"
                                        style={{
                                          fontSize: "var(--body-font-size)",
                                          fontFamily: "var(--font-family)",
                                        }}
                                      >
                                        Dinner
                                      </StyledFoodTypography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  {player.snack === true ? (
                                    <Grid item sx={6} md={4}>
                                      <StyledFoodTypography
                                        variant="body2"
                                        style={{
                                          fontSize: "var(--body-font-size)",
                                          fontFamily: "var(--font-family)",
                                        }}
                                      >
                                        Snack
                                      </StyledFoodTypography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <Grid item xs={12}>
                                  <StyledFoodTypography
                                    variant="body2"
                                    style={{
                                      fontSize: "var(--body-font-size)",
                                      fontFamily: "var(--font-family)",
                                    }}
                                  >
                                    No Food
                                  </StyledFoodTypography>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </StyledCard>
                ))
              ) : (
                <StyledCard key={player.influenceHeaderId}>
                  <CardContent
                    sx={{
                      minHeight: "71vh",
                      maxHeight: "80vh",
                      maxWidth: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={noDataIcon}
                      style={{
                        width: "70%",
                        height: "70%",
                        display: "block",
                        margin: "0 auto",
                        // marginTop: "35%",
                      }}
                      alt=""
                    />
                  </CardContent>
                </StyledCard>
              )}
            </Box>
          </Grid>
          <Grid item xs={1} sm={1} md={1.1} lg={1.1}>
            <Grid
              container
              spacing={2}
              columns={{ xs: 1, sm: 1, md: 1, lg: 1 }}
              sx={{ height: "75vh" }}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <MoodLevelTracking influence={filteredData} />
              </Grid>

              <Grid item xs={1} sm={1} md={0.6} lg={0.6}>
                <SleepTimeTrack influence={filteredData} />
              </Grid>
              <Grid item xs={1} sm={1} md={0.4} lg={0.4}>
                <HealthLevelTrack influence={filteredData} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div
        ref={componentPdf}
        style={{
          display: isVisible ? "block" : "none",
        }}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={12} md={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  flexDirection: "row",
                  fontSize: "var(--default-a4-font-size)",
                }}
              >
                <img
                  src={logo}
                  style={{
                    width: "var(--default-a4-font-size)",
                    height: "var(--default-a4-font-size)",
                    marginRight: "8px",
                    width: "40px",
                    height: "40px",
                  }}
                  alt="logo"
                />{" "}
                <Typography
                  sx={{
                    // fontSize: "var(--default-a4-font-size)",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "2px",
                    fontSize: "var(--default-a4-header-font-size)",
                  }}
                >
                  XI Performer
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={4}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "var(--font-family)",
                    textDecoration: "underline",
                    fontSize: "var(--default-a4-header-font-size)",
                  }}
                >
                  Player Info{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Player Name: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {pdfPlayer !== undefined ? pdfPlayer.userName : ""}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Player ID: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {pdfPlayer !== undefined ? pdfPlayer.loginId : ""}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Coach Name: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {coachName}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date Joined: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {pdfPlayer !== undefined
                    ? new Date(pdfPlayer.createDate).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                    : ""}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={12}
              md={4}
              lg={4}
              sx={{ borderBottom: "1px solid #dddddd" }}
            ></Grid>
            <Grid item xs={6} sm={12} md={12} lg={4}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "var(--font-family)",
                    textDecoration: "underline",
                    fontSize: "var(--default-a4-header-font-size)",
                  }}
                >
                  Influencer Data{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={12} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date Generated: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {format(selectedRange.startDate, "dd-MMM-yyyy")} to{" "}
                  {format(selectedRange.endDate, "dd-MMM-yyyy")}{" "}
                </Typography>
              </Box>
            </Grid>

            {/* <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "var(--font-family)" }}
                >
                  Date Exiting :{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "var(--font-family)" }}
                >
                  {" "}
                </Typography>
              </Box>
            </Grid> */}
            <Grid
              item
              xs={6}
              sm={12}
              md={4}
              lg={4}
              sx={{ borderBottom: "1px solid #dddddd" }}
            ></Grid>
          </Grid>
          <table style={{ width: "100%", marginTop: "10px" }}>
            <thead>
              <tr style={{ fontFamily: "var(--font-family)" }}>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  S.No
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Breakfast
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Lunch
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Snack
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Dinner
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Water (ltrs)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Sleep (hrs)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Mood Level (%)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Energy Level (%)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Health
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr
                    key={item.influenceHeaderId}
                    style={{ fontFamily: "var(--font-family)" }}
                  >
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.createdDate}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.breakfast == true ? (
                        <CheckCircleOutline color="success" />
                      ) : (
                        <Dangerous color="error" />
                      )}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.lunch == true ? (
                        <CheckCircleOutline color="success" />
                      ) : (
                        <Dangerous color="error" />
                      )}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.snack == true ? (
                        <CheckCircleOutline color="success" />
                      ) : (
                        <Dangerous color="error" />
                      )}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.dinner == true ? (
                        <CheckCircleOutline color="success" />
                      ) : (
                        <Dangerous color="error" />
                      )}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.water != null ? item.water : "--"}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.sleep}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.moodLevel.toFixed(1)}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.energyLevel.toFixed(1)}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.health}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    style={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                    colSpan={11}
                  >
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      </div>
      <div
        ref={biometricComponentPdf}
        style={{
          display: isBiometricVisible ? "block" : "none",
        }}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={12} md={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  flexDirection: "row",
                  fontSize: "var(--default-a4-font-size)",
                }}
              >
                <img
                  src={logo}
                  style={{
                    width: "var(--default-a4-font-size)",
                    height: "var(--default-a4-font-size)",
                    marginRight: "8px",
                    width: "40px",
                    height: "40px",
                  }}
                  alt="logo"
                />{" "}
                <Typography
                  sx={{
                    // fontSize: "var(--default-a4-font-size)",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "2px",
                    fontSize: "var(--default-a4-header-font-size)",
                  }}
                >
                  XI Performer
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={4}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-header-font-size)",
                    textDecoration: "underline",
                  }}
                >
                  Player Info{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Player Name: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {pdfPlayer !== undefined ? pdfPlayer.userName : ""}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Player ID: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {pdfPlayer !== undefined ? pdfPlayer.loginId : ""}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Coach Name: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {coachName}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date Joined: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {pdfPlayer !== undefined
                    ? new Date(pdfPlayer.createDate).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                    : ""}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={12}
              md={4}
              lg={4}
              sx={{ borderBottom: "1px solid #dddddd" }}
            ></Grid>
            <Grid item xs={6} sm={12} md={12} lg={4}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "var(--font-family)",
                    textDecoration: "underline",

                    fontSize: "var(--default-a4-header-font-size)",
                  }}
                >
                  Biometric Data{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={12} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date Generated: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {format(selectedRange.startDate, "dd-MMM-yyyy")} to{" "}
                  {format(selectedRange.endDate, "dd-MMM-yyyy")}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={12}
              md={4}
              lg={4}
              sx={{ borderBottom: "1px solid #dddddd" }}
            ></Grid>
          </Grid>
          <table style={{ width: "100%", marginTop: "10px" }}>
            <thead>
              <tr
                style={{
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--default-a4-font-size)",
                }}
              >
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  S.No
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Height<br></br>(cm)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Weight<br></br>(kg)
                </th>
                <th>BMI</th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Hip to knee length<br></br>(cm)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Knee to ankle length<br></br>(cm)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  BP bfore trial<br></br>(mm)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  BP after trail<br></br>(mm)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Heart rate before trial<br></br>(bpm)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Heart rate after trial<br></br>(bpm)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Temprature before trial<br></br>(°F)
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Temprature after trial<br></br>(°F)
                </th>
              </tr>
            </thead>
            <tbody>
              {biometric.length > 0 ? (
                biometric.map((item, index) => (
                  <tr
                    key={item.influenceHeaderId}
                    style={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.date}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.height.toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.weight.toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.bmi}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.hip.toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.ankle.toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.bpbeforeTrail}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.bpafterTrail}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.restingHeartRate}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.targetedHeartRate}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.temperatureBefore}
                    </td>
                    <td
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {item.temperatureAfter}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    style={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                    colSpan={13}
                  >
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      </div>
      {/* </Paper> */}
    </Box>
  );
};

export default Influencer;
