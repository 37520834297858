import {
  Download,
  EditNote,
  HealthAndSafety,
  Send,
  Upload,
} from "@mui/icons-material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { format } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Chrono } from "react-chrono";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import { GridTable } from "../common/GridTable";
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useReactToPrint } from "react-to-print";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CommonTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import Style from "../../Common/Components/Styles";
import Extension from "../../Common/Extension";
import { CommonAddIcon } from "../../Common/IconsAndComps";
import ToastMessage from "../../Common/Components/ToastMessage";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";
import logo from "../../assets/logo.png";

const style = {
  formControl: {
    width: { md: "100%", sm: "100%", xs: "100%", lg: "100%" },
    mt: 1,
    fontFamily: "var(--font-family)",
  },
  formControl1: {
    width: { md: "100%", sm: "100%", xs: "100%", lg: "100%" },
    mt: 2,
    fontFamily: "var(--font-family)",
  },
  formControl3: {
    width: { md: "15%", sm: "10%", xs: "40%", lg: "16%" },
    m: 1,
    fontFamily: "var(--font-family)",
  },
  formControl4: {
    width: { md: "100%", sm: "100%", xs: "100%", lg: "100%" },
    mt: 1,
    fontFamily: "var(--font-family)",
  },
  formControl5: {
    width: { md: "10%", sm: "15%", xs: "22%", lg: "5%" },
    m: 1,
    fontFamily: "var(--font-family)",
  },

  label: {
    color: "#000",
    fontWeight: 700,
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "#000",
      fontSize: "15px",
      fontFamily: "var(--font-family)",
    },
  },
  inputFiled: {
    color: "#000",
    background: "#FFFFFF",
    borderRadius: "10px",
    fontSize: "20px",
    fontFamily: "var(--font-family)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover > .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
};

const MedicalHistory = () => {
  const userId = +sessionStorage.getItem("userId");
  const [selectplayer, setSelectPlayer] = useState("All");
  const [status, setStatus] = useState("");
  const [player, setPlayer] = useState([]);
  const [playerDetails, setPlayerDetails] = useState([]);
  const [incident, setIncident] = useState("");
  const [description, setDescription] = useState("");
  const [updateDes, setUpdateDes] = useState("");
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
  const [editDataId, setEditDataId] = useState(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [todayDate, setTodayDate] = useState(formattedDate);
  const [updateDate, setUpdateDate] = useState(todayDate);
  const [selectedDate, setSelectedDate] = useState(formattedDate);
  const { toast, contextHolder } = ToastMessage();

  console.log("todayDate", todayDate);

  console.log("selectedDate", selectedDate);

  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const getData = useCallback(async () => {
    try {
      toast("loading", "Loading...");
      const response = await api.get("/users/coachId/" + userId);
      const playerId = response.data.map((id) => id.userId);
      setPlayer(response.data);

      let players = [];
      for (let ids of playerId) {
        const res = await api.get(`/medicalHeader/player/${ids}`);
        players.push(res.data);
        // console.log(res.data);
      }
      const playerData = players.flat();

      const MId = playerData.map((response) => response.medicalHeaderId);

      let headerDetail = [];
      for (let i of MId) {
        const resp = await api.get("/medicalDetail/header/" + i);
        headerDetail.push(resp.data);
      }
      const hData = headerDetail.flat();

      const mergedData = playerData.map((data) => {
        const description = hData.filter(
          (ids) => ids.medicalHeaderId === data.medicalHeaderId
        );
        return {
          data,
          description,
        };
      });
      console.log(mergedData);
      setDetailsSet(mergedData);
      const mergedUser = mergedData.map((maindata) => {
        const user = response.data.filter(
          (id) => parseInt(id.userId) === parseInt(maindata.data.playerId)
        );
        return {
          maindata,
          user,
        };
      });
      console.log(mergedUser);
      setPlayerDetails(mergedUser);
      setFilteredData(mergedUser);
      setSelectPlayer("");
    } catch (err) {
      console.log("error", err);
    }
  }, [userId]);

  useEffect(() => {
    getData();
  }, [getData]);

  let headerId = null;
  async function addIncidentType() {
    if (incident && description && selectplayer) {
      setSubmitButtonDisable(true);
      try {
        toast("success", "Added Successfully...");
        const medical = {
          incident: incident,
          playerId: selectplayer,
          academyId: sessionStorage.getItem("academyId"),
          createdBy: sessionStorage.getItem("userId"),
          coachId: sessionStorage.getItem("userId"),
          createdDate: selectedDate,
          name: "",
          session: "",
          status: "",
          modifiedBy: userId,
          modifiedDate: getCurrentSQLDate(),
        };

        const response = await api.post("/medicalHeader/add", medical);
        headerId = response.data.medicalHeaderId;
        console.log(response.data);
        setSelectedDate(response.data.createdDate);
        const describe = {
          medicalHeaderId: headerId,
          description: description,
          status: "Sick",

          date: selectedDate,
        };
        console.log(medical);
        console.log(describe);
        const res = await api.post("/medicalDetail/add", describe);
        console.log(res.data);
        setSelectPlayer("");
        setIncident("");
        setDescription("");

        // alert("Added Successfully");
        handleClose1();
        getData();
      } catch (err) {
        console.log(err);
      } finally {
        setSubmitButtonDisable(false);
      }
    } else {
      toast("error", "Please Fill All The Details...");
    }
  }

  async function addUpdateDetails() {
    if (updateDes && status) {
      setSubmitButtonDisable(true);
      try {
        const updateData = {
          description: updateDes,
          status,
          date: updateDate,
          medicalHeaderId: editDataId,
        };
        const response = await api.post("/medicalDetail/add", updateData);
        console.log(response.data);
        setSelectedDate(response.data.date);
        getData();
        setUpdateDes("");
        setStatus("");
        handleClose2();
        toast("success", "Updated Successfully...");
      } catch (err) {
        console.log("error", err);
      } finally {
        setSubmitButtonDisable(false);
      }
    } else {
      toast("error", "Please Fill All The Details...");
    }
  }

  const [filteredData, setFilteredData] = useState([]);
  function handleUserId(e) {
    const userId = e.target.value;
    setSelectPlayer(userId);
  }

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedData, setSelectedData] = useState("");

  const handleOpen = (data) => {
    setOpenDelete(true);
    setSelectedData(data);
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  let medDetIds = [];
  console.log("selectedData", selectedData);

  async function deleteData() {
    const detail = selectedData;
    try {
      medDetIds.push(detail.description.map((id) => id.medicalDetailId));
      const medDetailId = medDetIds.flat();
      const mhId = detail.data.medicalHeaderId;
      await api.delete("/medicalHeader/" + mhId);
      for (let i of medDetailId) {
        await api.delete("/medicalDetail/" + i);
      }
      getData();
      handleClose();
      toast("success", "Medical Detail Deleted Successfully..");
    } catch (err) {
      console.log("error", err);
    }
  }

  let healthIconStyles = { background: "#06D6A0" };

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setSelectPlayer("");
    setIncident("");
    setSelectedDate(todayDate);
    setDescription("");
    setOpen1(false);
  };

  const [createdDate, setCreatedDate] = useState("");

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = (id) => {
    setEditDataId(id.medicalHeaderId);
    console.log(id);
    setOpen2(true);
    setCreatedDate(id.createdDate);
  };
  const handleClose2 = () => {
    setUpdateDes("");
    setStatus("");
    setOpen2(false);
  };

  // console.log('filteredData',filteredData);

  const details = playerDetails.filter(
    (id) => parseInt(id.maindata.data.medicalHeaderId) === parseInt(editDataId)
  );
  const DataRow = filteredData.map((item, index) => ({
    id: index + 1,
    name: item.user.map((u) => u.userName),
    action: (
      <div>
        <EditNote
          color="success"
          onClick={() => handleOpen2(item.maindata.data)}
        />
        <DeleteRoundedIcon
          color="error"
          onClick={() => handleOpen(item.maindata)}
        />
      </div>
    ),
    date: Extension.convertDateFormatToDMY(item.maindata.data["createdDate"]),
    incident: item.maindata.data.incident,
  }));

  const [detailsSet, setDetailsSet] = useState([]);
  const chrono_details = details?.map((item) =>
    item.maindata.description.map((res) => {
      return {
        cardTitle: res?.status,
        cardDetailedText: Extension.convertDateFormatToDMY(res?.date),
        cardSubtitle: res?.description,
      };
    })
  );

  const STATUS_ARRAY = [
    { name: "Sick", value: "Sick" },
    { name: "Just recovered", value: "Just recovered" },
    { name: "Active", value: "Active" },
  ];
  const componentPdf = useRef();
  const [name, setName] = useState("All");
  const [pdfPlayer, setPdfPlayer] = useState({});
  useEffect(() => {
    if (name !== "all") {
      const pdfData = player.find((user) => user.userId === name);
      setPdfPlayer(pdfData);
    }
  }, [name]);
  const [isVisible, setIsVisible] = useState(false);

  const handleBeforePrint = () => {
    setIsVisible(true);
  };

  const handleAfterPrint = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (isVisible) {
      generateInvoice();
    }
  }, [isVisible]);
  const handlePlayer = (e) => {
    const userId = e.target.value;
    setName(userId);
    const filterData = playerDetails.filter((player) =>
      player.user.find((u) => parseInt(u.userId) === parseInt(userId))
    );
    console.log(filterData);
    setFilteredData(filterData);
    if (e.target.value === "All") {
      setFilteredData(playerDetails);
    }
  };

  const generateInvoice = useReactToPrint({
    content: () => componentPdf.current,
    documentTitle: "Performance Data",
    onAfterPrint: handleAfterPrint,
  });
  const handlePrint = () => {
    handleBeforePrint();
    setTimeout(() => {
      generateInvoice();
    }, 100);
  };
  const today = new Date();
  const currentDay = format(today, "yyyy-MM-dd");
  const monthly = format(
    new Date(today.getFullYear(), today.getMonth(), 1),
    "yyyy-MM-dd"
  );
  const yearly = format(new Date(today.getFullYear(), 0, 1), "yyyy-MM-dd");
  const weekly = format(
    new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 1
    ),
    "yyyy-MM-dd"
  );
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(monthly),
    endDate: new Date(),
    key: "selection",
  });
  console.log("hi", filteredData);

  const coachName = sessionStorage.getItem("userName");
  return (
    <Box className="common-wrapper">
      <CommonAlertPopup
        handleYes={deleteData}
        handleNo={handleClose}
        titleText={<p>Confirmation</p>}
        contentText={<p>Do you want to Delete?</p>}
        open={openDelete}
        handleClose={handleClose}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Yes"}
      />
      {contextHolder}
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <CustomCommonButton
            icon={CommonAddIcon}
            text="Health Update"
            onClick={handleOpen1}
          />
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
          <Box sx={{ mr: 4, width: "300px" }}>
            {" "}
            {/* Add margin-right to create space */}
            <CommonDropdown
              firstOption={"All"}
              search={true}
              label={"Filter By Player"}
              value={name}
              onChange={handlePlayer}
              array={player}
              menuValue={"userId"}
              display={"userName"}
              sx={{
                maxWidth: "100px", // Adjust the minimum width as needed
                fontSize: "16px", // Adjust the font size as needed
                padding: "10px", // Adjust padding for larger clickable area
              }}
            />
          </Box>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <Box sx={{ position: "relative", height: "100%" }}>
              <CustomCommonButton
                icon={
                  <HealthAndSafety
                    sx={{ fontSize: "40px", padding: "4px", opacity: 0.5 }}
                  />
                }
                onClick={handlePrint}
                tooltipText="Report"
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-5px",
                  left: "50px",
                  width: "100%",
                }}
              >
                <Download
                  sx={{
                    backgroundColor: "unset",
                    color: "white",
                    opacity: 0.5,
                    fontSize: "18px",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Modal keepMounted open={open1} onClose={handleClose1}>
        <Box sx={Style.widthPopupStyle}>
          <Grid
            container
            columns={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}
            spacing={2}
          >
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <Typography variant="h4" sx={Style.titleText}>
                Health Update
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CommonDropdown
                label={"Select Player"}
                value={selectplayer}
                onChange={handleUserId}
                array={player}
                menuValue={"userId"}
                display={"userName"}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CommonTextField
                // firstOption={"All"}
                // search={true}
                label="Incident"
                name="Incident"
                value={incident}
                onChange={(e) => setIncident(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <FormControl variant="outlined">
                <InputLabel
                  sx={style.label}
                  style={{
                    fontSize: "var(--font-size)",
                    fontFamily: "var(--font-family)",
                  }}
                >
                  Date
                </InputLabel>
                <OutlinedInput
                  style={{
                    borderRadius: 12,
                    fontSize: "var(--font-size)",
                    fontFamily: "var(--font-family)",
                  }}
                  sx={{
                    // ...style.inputFiled,
                    fontSize: "var(--font-size)",
                    fontFamily: "var(--font-family)",
                  }}
                  id="date"
                  type="date"
                  size="small"
                  className="date-style"
                  format="dd-mm-yyyy"
                  value={selectedDate}
                  onChange={(e) => {
                    // setTodayDate(e.target.value);
                    setSelectedDate(e.target.value);
                  }}
                  label="Date"
                  inputProps={{
                    max: todayDate,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <CommonTextField
                label="Description"
                name="Description"
                multiline={true}
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <CustomCommonButton
                  text={"Submit"}
                  onClick={addIncidentType}
                  disabled={submitButtonDisable}
                  icon={<Upload />}
                  // fullWidth={"100%"}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={Style.widthPopupStyle}>
          {details.map((item, index) => (
            <div key={index}>
              <Grid
                container
                columns={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}
                spacing={2}
              >
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      textTransform: "uppercase",
                      fontFamily: "var(--font-family)",
                      fontWeight: "var(--font-weight)",
                    }}
                  >
                    {item?.maindata?.data?.incident || "Not Updated Yet..."}
                  </Typography>
                  {item.maindata.description[0] && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "var(--font-size)",
                          fontFamily: "var(--font-family)",
                          mr: 2,
                        }}
                      >
                        {
                          item.maindata.description[
                            item.maindata.description.length - 1
                          ].description
                        }
                      </Typography>
                      <Chip
                        className="common-font-style"
                        variant="outlined"
                        color={
                          item.maindata.description[
                            item.maindata.description.length - 1
                          ].status === "Sick"
                            ? "error"
                            : item.maindata.description[
                                item.maindata.description.length - 1
                              ].status === "Just recovered"
                            ? "warning"
                            : "success"
                        }
                        sx={{
                          textTransform: "uppercase",
                          fontFamily: "var(--font-family)",
                          fontWeight: "var(--font-weight)",
                        }}
                        label={
                          item.maindata.description[
                            item.maindata.description.length - 1
                          ].status
                        }
                      />
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "var(--app-bar-color)",
                      textTransform: "uppercase",
                      fontFamily: "var(--font-family)",
                      fontWeight: "var(--font-weight)",
                    }}
                  >
                    Timeline
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={3}
                  lg={3}
                  xl={3}
                  className="time-line-component"
                  sx={{ ml: 2 }}
                >
                  <Chrono
                    items={chrono_details.flat()}
                    mode={"VERTICAL_ALTERNATING"}
                    className="chorno-style"
                    theme={{
                      primary: "#155ECC",
                      secondary: "#155ECC",
                      cardBgColor: "#F2F2F2",
                      titleColor: "black",
                    }}
                    cardHeight={70}
                    hideControls
                    activeItemIndex={null}
                    disableToolbar={true}
                    highlightCardsOnHover={true}
                    disableInteraction={true}
                    disableClickOnCircle={true}
                  />
                  {/* <VerticalTimeline>
>>>>>>> 6ada90739e4e3830278bf21f74875a96f482de5e
                    {item.maindata.description.map((desc, index) => (
                      <VerticalTimelineElement
                        key={index}
                        date={desc.date}
                        dateClassName="date-component"
                        iconStyle={healthIconStyles}
                        icon={<MedicalInformationIcon />}
                      >
                        <p
                          id="description"
                          contClassName="cont"
                          style={{
                            fontSize: "var(--font-size)",
                            fontFamily: "var(--font-family)",
                          }}
                        >
                          {desc.description !== null ? desc.description : null}
                        </p>
                      </VerticalTimelineElement>
                    ))}
                  </VerticalTimeline> */}
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1.5} xl={1.5}>
                  <CommonDropdown
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    array={STATUS_ARRAY}
                    menuValue="value"
                    display="name"
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1.5} lg={1.5}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      sx={style.label}
                      style={{
                        fontSize: "var(--font-size)",
                        fontFamily: "var(--font-family)",
                      }}
                    >
                      Date
                    </InputLabel>
                    <OutlinedInput
                      style={{
                        borderRadius: 12,
                        fontSize: "var(--font-size)",
                        fontFamily: "var(--font-family)",
                      }}
                      sx={{
                        ...style.inputFiled,
                        fontSize: "var(--font-size)",
                        fontFamily: "var(--font-family)",
                      }}
                      id="date"
                      type="date"
                      size="small"
                      className="date-style"
                      value={updateDate}
                      onChange={(e) => setUpdateDate(e.target.value)}
                      label="Date"
                      inputProps={{
                        min: createdDate,
                        max: todayDate,
                      }}
                    />
                    {console.log(
                      "date",
                      new Date().toISOString().split("T")[0]
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={1} sm={1} md={2} lg={3} xl={3}>
                  <CommonTextField
                    label="Description"
                    name="Incident"
                    value={updateDes}
                    onChange={(e) => setUpdateDes(e.target.value)}
                  />
                </Grid>

                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <CustomCommonButton
                    text={"Send"}
                    onClick={addUpdateDetails}
                    disabled={submitButtonDisable}
                    icon={<Send />}
                    fullWidth={"100%"}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
        </Box>
      </Modal>
      <GridTable rows={DataRow} columns={GetData.medical.columns} />
      <div
        ref={componentPdf}
        style={{
          display: isVisible ? "block" : "none",
        }}
      >
        <Box sx={{ marginTop: "40px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexDirection: "row",
              fontSize: "var(--default-a4-font-size)",
            }}
          >
            <img
              src={logo}
              style={{
                width: "var(--default-a4-font-size)",
                height: "var(--default-a4-font-size)",
                marginRight: "8px",
                width: "40px",
                height: "40px",
              }}
              alt="logo"
            />{" "}
            <Typography
              sx={{
                // fontSize: "var(--default-a4-font-size)",
                display: "flex",
                alignItems: "center",
                marginTop: "2px",
                fontSize: "var(--default-a4-header-font-size)",
              }}
            >
              XI Performer
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {name !== "All" ? (
              <>
                <Grid item xs={6} sm={12} md={12} lg={4}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "var(--font-family)",
                        textDecoration: "underline",
                        fontSize: "var(--default-a4-header-font-size)",
                      }}
                    >
                      Player Info{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Player Name: &nbsp;{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {" "}
                      {pdfPlayer !== undefined ? pdfPlayer.userName : ""}{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Player ID: &nbsp;{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {" "}
                      {pdfPlayer !== undefined ? pdfPlayer.loginId : ""}{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Date Joined: &nbsp;{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {" "}
                      {pdfPlayer !== undefined
                        ? new Date(pdfPlayer.createDate).toLocaleString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            }
                          )
                        : ""}{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Coach Name: &nbsp;{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {" "}
                      {coachName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ borderBottom: "1px solid #dddddd" }}
                ></Grid>
              </>
            ) : (
              ""
            )}

            <Grid item xs={6} sm={12} md={12} lg={4}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "var(--font-family)",
                    textDecoration: "underline",
                    fontSize: "var(--default-a4-header-font-size)",
                  }}
                >
                  Medical History Data{" "}
                </Typography>
              </Box>
            </Grid>
            {/* <Box sx={{ display: "flex",flexDirection:'column' }}> */}
            <Grid item xs={6} sm={12} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date Generated: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {format(selectedRange.startDate, "dd-MMM-yyyy")} to{" "}
                  {format(selectedRange.endDate, "dd-MMM-yyyy")}{" "}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "var(--font-family)" }}
                >
                  Date Exiting: &nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "var(--font-family)" }}
                >
                  {" "}
                </Typography>
              </Box>
            </Grid> */}

            {/* </Box> */}

            <Grid
              item
              xs={6}
              sm={12}
              md={4}
              lg={4}
              sx={{ borderBottom: "1px solid #dddddd" }}
            ></Grid>
          </Grid>
          <table style={{ width: "100%", marginTop: "10px" }}>
            <thead>
              <tr
                style={{
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--default-a4-font-size)",
                }}
              >
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  S.No
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Incident Type
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Incident History
                </th>
                <th
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Updated Dates
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((row, index) => (
                  <tr
                    key={row.maindata.data.medicalHeaderId}
                    style={{
                      fontFamily: "var(--font-family)",
                      textAlign: "left",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {row.maindata.data.createdDate}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {row.maindata.data.incident}
                    </td>

                    <td
                      style={{
                        textAlign: "left",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {row.maindata.description.map((desc, idx) => (
                          <div key={idx} style={{ marginBottom: "15px" }}>
                            <div
                              style={{
                                marginBottom: "2px",
                                marginTop: "5px",
                                fontSize: "var(--default-a4-font-size)",
                              }}
                            >
                              <strong
                                style={{
                                  fontFamily: "var(--font-family)",
                                  fontSize: "var(--default-a4-font-size)",
                                }}
                              >
                                Date
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                              </strong>{" "}
                              {desc.date}
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                              <strong
                                style={{
                                  fontFamily: "var(--font-family)",
                                  fontSize: "var(--default-a4-font-size)",
                                }}
                              >
                                Status
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                              </strong>{" "}
                              {desc.status}
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                              <strong
                                style={{
                                  fontFamily: "var(--font-family)",
                                  fontSize: "var(--default-a4-font-size)",
                                }}
                              >
                                Description :
                              </strong>{" "}
                              {desc.description}
                            </div>

                            {idx < row.maindata.description.length - 1 && (
                              <hr />
                            )}
                          </div>
                        ))}
                      </div>
                    </td>

                    <td style={{ textAlign: "center" }}>
                      {row.maindata.description.length > 0 && (
                        <div
                          style={{
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--default-a4-font-size)",
                          }}
                        >
                          {
                            row.maindata.description[
                              row.maindata.description.length - 1
                            ].date
                          }
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={6}
                    style={{
                      textAlign: "center",
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      </div>
    </Box>
  );
};

export default MedicalHistory;
