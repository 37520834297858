import { DeleteRounded, PostAdd } from "@mui/icons-material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CloseIcon from "@mui/icons-material/Close";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import ToastMessage from "../../Common/Components/ToastMessage";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";

const style = {
  formControl: {
    width: { md: "100%", sm: "100%", xs: "100%", lg: "100%" },
    mt: 1,
    fontFamily: "var(--font-family)",
  },
  formControl1: {
    width: { md: "100%", sm: "100%", xs: "100%", lg: "100%" },
    mt: 2,
    fontFamily: "var(--font-family)",
  },
  formControl3: {
    width: { md: "15%", sm: "10%", xs: "40%", lg: "16%" },
    m: 1,
    fontFamily: "var(--font-family)",
  },
  formControl4: {
    width: { md: "100%", sm: "100%", xs: "100%", lg: "100%" },
    mt: 1,
    fontFamily: "var(--font-family)",
  },
  formControl5: {
    width: { md: "10%", sm: "15%", xs: "22%", lg: "5%" },
    m: 1,
    fontFamily: "var(--font-family)",
  },

  label: {
    color: "#000",
    fontWeight: 700,
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "#000",
      fontSize: "15px",
      fontFamily: "var(--font-family)",
    },
  },
  inputFiled: {
    color: "#000",
    background: "#FFFFFF",
    borderRadius: "10px",
    fontSize: "20px",
    fontFamily: "var(--font-family)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover > .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
};

const column = [
  "S.No",
  "Exercise",
  "Type",
  "Unit",
  "No of Set",
  "Reps",
  "Active Time",
  "Rest",
  "Action",
];
export const AssignPreparation = () => {
  const userId = sessionStorage.getItem("userId");
  const academyId = sessionStorage.getItem("academyId");
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [todayDate, setTodayDate] = useState(formattedDate);
  const [userGroup, setUserGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [activity, setActivity] = useState([]);
  const [activityValue, setActivityValue] = useState("");
  const [session, setSession] = useState("");
  const [sets, setSets] = useState("");
  const [combinationList, setCombinationList] = useState([]);
  const [combinationValue, setCombinationValue] = useState("");
  const [groupPlayerList, setGroupPlayerList] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [warmupList, setWarmupList] = useState([]);
  const [exercise, setExercise] = useState([]);
  const [rep, setRep] = useState("");
  const [rest, setRest] = useState("");
  const [time, setTime] = useState("");
  const { toast, contextHolder } = ToastMessage();

  var initialExercise = {
    warmUpId: "",
    noOfSet: "1",
    noOfRep: "0",
    noOfRest: "0",
    noOfTime: "0",
  };
  const [exerciseValue, setExerciseValue] = useState(initialExercise);

  const getGroup = async () => {
    try {
      const response = await api.get("/userGroup/coach/" + userId);
      setUserGroup(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const getGroupPlayerList = async (userGroupId) => {
    try {
      const responce = await api
        .get(`/groupingUser/userGroup/${userGroupId}`)
        .then((res) => res.data);
      const playerIds = responce.map((player) => player.userId);
      setGroupPlayerList(playerIds);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getPlayerList = async () => {
    try {
      const responce = await api
        .get(`/users/coachId/${userId}`)
        .then((res) => res.data);
      setPlayerList(responce);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const groupChange = (event, newValue) => {
    setSelectedGroup(newValue);
    if (newValue) {
      getGroupPlayerList(newValue.userGroupId);
    }
  };

  async function getActivity() {
    try {
      const response = await api.get(`/activity/createdBy/isPublic/${userId}`);

      const responseActivity = await api.get(
        `/warmUpCombination/createdBy/isPublic/${userId}`
      );

     console.log('combination', responseActivity.data)
      // const response = await api.get(`/activity/createdBy/${userId}`);
      const currentUser = responseActivity.data
      .flatMap((user) =>
        response.data.filter((combination) => combination.activityId === user.activityId)
      )
      .reduce((acc, user) => {
        if (!acc.find((u) => u.activityId === user.activityId)) {
          acc.push(user);
        }
        return acc;
      }, []);
      console.log('currentUser',currentUser);
      setActivity(currentUser);
      console.log('activity', activity);
    } catch (err) {
      console.log("Error", err);
    }
  }

  const getExcerise = async () => {
    try {
      const responce = await api.get(`/warmUp/all`);
      setExercise(responce.data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const loadCombination = async (event) => {
    setActivityValue(event.target.value);
    console.log("activityID", event.target.value);
    try {
      const responce = await api.get(
        `/warmUpCombination/activity/${event.target.value}`
      );

      const combinationFilter = responce.data.filter(
        (user) =>
          user.createdBy == userId ||
          (user.createdBy != userId && user.isPublic === true) ||
          user.academyId === academyId
      );

      setCombinationList(combinationFilter);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const combinationChange = async (event) => {
    setCombinationValue(event.target.value);
    setWarmupList([]);
    try {
      const responce = await api
        .get(`/warmUpDetails/warmUpCombination/${event.target.value}`)
        .then((res) => res.data);
      if (responce) {
        for (let item of responce) {
          const warmup = await api
            .get(`/warmUp/${item.warmUpId}`)
            .then((res) => res.data);
          setWarmupList((prevoius) => [
            ...prevoius,
            {
              ...warmup,
              assignSet: item.noOfSet,
              rep: item.rep,
              rest: item.rest,
              time: item.time,
            },
          ]);
        }
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const [preparationAssigned, setPreparationAssigned] = useState(false);
  const handlePreparationAdd = async () => {
    let duplicateCheck = false;
    let preparationAssigned = false;

    console.log("groupPlayerList", groupPlayerList);
    for (const item of groupPlayerList) {
      try {
        const response = await api.get(
          `/warmUpWorkoutHeader/${item}/${session}/${todayDate}`
        );
        console.log("response", response.data);

        if (combinationValue) {
          // console.log('combinationValue', combinationValue);
          toast("loading", "Loading...");
          handleNoOfUses();
          toast("success", "Added Successfully...");
        }
        if (response?.data) {
          duplicateCheck = true;
          preparationAssigned = true;

          const singlePlayer = playerList.find(
            (value) => value.userId === item
          );
          console.log("singlePlayer", singlePlayer);
          // Show toast message
          if (singlePlayer) {
            toast(
              "warning",
              `Already the Preparation was assigned for ${singlePlayer.userName}`
            );
          }
        }
      } catch (error) {
        console.error(`Error`, error);
      }
    }

    if (!duplicateCheck) {
      for (const item of groupPlayerList) {
        var warmUpWorkoutHeader = {
          activityId: activityValue,
          coachId: userId,
          playerId: item,
          session: session,
          academyId: academyId,
          isPublic: "",
          createdBy: userId,
          createdDate: todayDate,
        };
        console.log(warmUpWorkoutHeader);
        try {
          navigate("/preparation");
          const response = await api.post(
            `/warmUpWorkoutHeader/add`,
            warmUpWorkoutHeader
          );

          if (response.data) {
            // console.log("Workout header", response.data);
            const modifiedWarmupList = warmupList.map((item, index) => ({
              ...item,
              warmUpWorkoutHeaderId: response.data.warmUpWorkoutHeaderId,
              sequence: index + 1,
              assignRep: item.rep,
              assignRest: item.rest,
              assignTime: item.time,
              actualSet: 0,
              actualRep: 0,
              actualRest: 0,
              actualTime: 0,
            }));
            for (let workoutDetails of modifiedWarmupList) {
              try {
                const workoutDetailsResponse = await api.post(
                  `/warmUpWorkoutDetailsController/add`,
                  workoutDetails
                );
              } catch (err) {
                console.log("Error", err);
              }
            }
          }
        } catch (err) {
          console.log("Error", err);
        }
      }
    }

    // if (!preparationAssigned) {
    //   toast("success", "Added Successfully...");
    //   navigate("/preparation");
    // }
  };

  const handleNoOfUses = async () => {
    const exCombination = combinationList.find(
      (item) => item.warmUpCombinationId === combinationValue
    );
    // console.log('exCOmbination', exCombination);
    const noOfUses =
      !exCombination?.noOfUses || Number.isNaN(exCombination?.noOfUses)
        ? 1
        : parseInt(exCombination?.noOfUses) + 1;
    const data = {
      ...exCombination,
      noOfUses,
    };
    // console.log('noOfUses', noOfUses);
    // console.log('data', data);
    await api
      .put(`/warmUpCombination/${combinationValue}`, data)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
    console.log(data);
  };

  useEffect(() => {
    getGroup();
    getActivity();
    getPlayerList();
    getExcerise();
  }, []);

  function addSingleExcerise() {
    var alredyExist = warmupList.filter((item) => {
      return item.warmUpId === exerciseValue.warmUpId;
    });
    if (alredyExist.length > 0) {
      toast("warning", "The exercise is already exists!...");
      setExerciseValue(initialExercise);
    } else {
      var singleExercise = exercise
        .map((item) => {
          if (item.warmUpId === exerciseValue.warmUpId) {
            return {
              ...item,
              assignSet: exerciseValue.noOfSet,
              rep: exerciseValue.noOfRep,
              time: exerciseValue.noOfTime,
              rest: exerciseValue.noOfRest,
            };
          }
          return item;
        })
        .filter((item) => item.warmUpId === exerciseValue.warmUpId);
      setWarmupList((previous) => [...previous, singleExercise[0]]);
      setExerciseValue(initialExercise);
    }
  }

  const handleDelete = (index) => {
    const updatedWarmupList = [...warmupList];
    updatedWarmupList.splice(index, 1);
    setWarmupList(updatedWarmupList);
  };

  const columns = column.map((item, index) => {
    return (
      <TableCell
        key={index}
        sx={{
          color: "#FFFFF0",
          fontWeight: 700,
          fontSize: "var(--font-size)",
          fontFamily: "var(--font-family)",
        }}
        align="center"
      >
        {item}
      </TableCell>
    );
  });

  const rows = warmupList.map((item, index) => {
    return {
      id: index + 1,
      exercise: item.warmUpName,
      type: item.type || "--",
      unit: item.unit || "--",
      NoOfSet: item.assignSet || 0,
      reps: item.rep || 0,
      activeTime: item.time || 0,
      rest: item.rest || 0,
      action: (
        <Box onClick={() => handleDelete(index)}>
          <DeleteRounded color="error" />
        </Box>
      ),
    };
  });

  const capitalizeFirstLetter = (word, index) => {
    const lowercaseWords = ["and", "to", "m", "for"];
    return lowercaseWords.includes(word.toLowerCase()) && index !== 0
      ? word.toLowerCase()
      : word.charAt(0).toUpperCase() + word.slice(1);
  };
  const navigate = useNavigate();

  const navigatePath = () => navigate("/preparation");
  return (
    <>
      <Box
        className="common-wrapper"
        // sx={{ display: "left", width: "100%", justifyContent: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            width: "30%",
            flexDirection: "column",
          }}
        >
          {preparationAssigned && (
            <Alert
              icon={<WarningIcon />}
              severity="error"
              action={
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ mr: 1 }}
                    onClick={() => setPreparationAssigned(false)}
                  >
                    Undo
                  </Button>
                  <IconButton
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => setPreparationAssigned(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              }
            >
              This player already has a preparation assigned.
            </Alert>
          )}
        </Box>
        {/*<Paper className="content" elevation={4} sx={{ mt: 15, mb: 20, p: 3 }}>*/}
        <Box>
          <CustomCommonButton
            onClick={navigatePath}
            text="BACK"
            icon={<ArrowCircleLeftIcon />}
          />
        </Box>
        <Grid
          container
          spacing={2}
          columns={{ sx: 1, sm: 1, md: 6, lg: 6, xl: 6 }}
        >
          <Grid item xs={1} md={1} lg={1.5} xl={1.5}>
            <FormControl sx={{ ...style.formControl1 }}>
              <Autocomplete
                sx={{
                  ...style.inputFiled,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                  borderRadius: "10px", // Set the border radius here
                }}
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                  fontWeight: 700,
                }}
                id="user-autocomplete"
                options={userGroup}
                getOptionLabel={(option) => option.groupName}
                value={selectedGroup}
                onChange={groupChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Group Name"
                    variant="outlined"
                    sx={{
                      ...style.inputFiled,
                      fontWeight: 700,
                      borderRadius: "10px",
                      fontSize: "var(--font-size)",
                      fontFamily: "var(--font-family)", // Set the border radius here as well if needed
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "black",
                        fontSize: "var(--font-size)",
                        fontFamily: "var(--font-family)",
                        fontWeight: 700,
                      },
                    }}
                    // InputProps={{
                    //   endAdornment: null, // Remove the dropdown icon
                    // }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} lg={1.5} xl={1.5}>
            <FormControl sx={style.formControl1}>
              <InputLabel
                sx={style.label}
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Player
              </InputLabel>
              <Select
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                sx={style.inputFiled}
                multiple
                value={groupPlayerList}
                onChange={(event) => {
                  setGroupPlayerList(event.target.value);
                }}
                label="Player"
                variant="outlined"
                fullWidth
                renderValue={(selected) => (
                  <div>
                    {selected.map((userId) => (
                      <Chip
                        key={userId}
                        label={
                          playerList.find((player) => player.userId === userId)
                            ?.userName || ""
                        }
                      />
                    ))}
                  </div>
                )}
              >
                {playerList.map((player) => (
                  <MenuItem
                    style={{
                      fontSize: "var(--font-size)",
                      fontFamily: "var(--font-family)",
                    }}
                    key={player.userId}
                    value={player.userId}
                  >
                    {player.userName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <FormControl variant="outlined" sx={style.formControl1}>
              <InputLabel
                sx={style.label}
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Activity
              </InputLabel>
              <Select
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                sx={style.inputFiled}
                value={activityValue}
                id="activity"
                label="Activity"
                onChange={loadCombination}
              >
                {activity.length > 0
                  ? activity.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            fontSize: "var(--font-size)",
                            fontFamily: "var(--font-family)",
                          }}
                          key={index}
                          value={item.activityId}
                        >
                          {item.activityName.charAt(0).toUpperCase() +
                            item.activityName.slice(1)}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <FormControl variant="outlined" sx={style.formControl1}>
              <InputLabel
                sx={style.label}
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Combination
              </InputLabel>
              <Select
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                sx={style.inputFiled}
                value={combinationValue}
                onChange={combinationChange}
                id="combination"
                label="Combination"
              >
                {combinationList.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        fontSize: "var(--font-size)",
                        fontFamily: "var(--font-family)",
                      }}
                      key={index}
                      value={item.warmUpCombinationId}
                    >
                      {item.warmUpCombinationName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <FormControl variant="outlined" sx={style.formControl1}>
              <InputLabel
                sx={style.label}
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Date
              </InputLabel>
              <OutlinedInput
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                sx={{
                  ...style.inputFiled,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                id="date"
                type="date"
                value={todayDate}
                onChange={(e) => setTodayDate(e.target.value)}
                label="Date"
                inputProps={{
                  min: new Date().toISOString().split("T")[0], // Disable past dates
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={0.8}>
            <FormControl variant="outlined" sx={{ ...style.formControl }}>
              <InputLabel
                sx={style.label}
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Session
              </InputLabel>
              <Select
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                sx={style.inputFiled}
                value={session}
                onChange={(event) => setSession(event.target.value)}
                id="session"
                label="Session"
              >
                <MenuItem
                  style={{
                    fontSize: "var(--font-size)",
                    fontFamily: "var(--font-family)",
                  }}
                  key={1}
                  value={"Morning"}
                >
                  Morning
                </MenuItem>
                <MenuItem
                  style={{
                    fontSize: "var(--font-size)",
                    fontFamily: "var(--font-family)",
                  }}
                  key={2}
                  value={"Evening"}
                >
                  Evening
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} lg={1.8} xl={1.8}>
            <FormControl variant="outlined" sx={{ ...style.formControl }}>
              <InputLabel
                sx={style.label}
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Exercise
              </InputLabel>
              <Select
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                sx={style.inputFiled}
                value={exerciseValue.warmUpId}
                onChange={(e) => {
                  setExerciseValue((previous) => {
                    return { ...previous, warmUpId: e.target.value };
                  });
                }}
              >
                {exercise.map((item, index) => (
                  <MenuItem
                    style={{
                      fontSize: "var(--font-size)",
                      fontFamily: "var(--font-family)",
                    }}
                    key={index}
                    value={item.warmUpId}
                  >
                    {item.warmUpName
                      .split(/\s+/)
                      .map((word, i) => window.capitalizeFirstLetter(word, i))
                      .join(" ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} lg={0.7} xl={0.7}>
            <FormControl variant="outlined" sx={style.formControl4}>
              <InputLabel
                sx={style.label}
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                No.of Sets
              </InputLabel>
              <OutlinedInput
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                sx={style.inputFiled}
                id="set"
                type="number"
                label="No of Sets"
                value={exerciseValue.noOfSet}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (
                    inputValue === "" ||
                    (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 99)
                  ) {
                    setExerciseValue((previous) => {
                      return { ...previous, noOfSet: inputValue };
                    });
                    setSets(inputValue === "" ? "" : parseInt(inputValue));
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} lg={0.7} xl={0.7}>
            <FormControl variant="outlined" sx={{ ...style.formControl }}>
              <InputLabel
                sx={style.label}
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Reps
              </InputLabel>
              <OutlinedInput
                sx={style.inputFiled}
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                id="rep"
                type="number"
                label="Reps"
                value={exerciseValue.noOfRep}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (
                    inputValue === "" ||
                    (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 99)
                  ) {
                    setExerciseValue((previous) => {
                      return { ...previous, noOfRep: inputValue };
                    });
                    setRep(inputValue === "" ? "" : parseInt(inputValue));
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} lg={0.5} xl={0.5}>
            <FormControl variant="outlined" sx={{ ...style.formControl }}>
              <InputLabel
                sx={style.label}
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Rest
              </InputLabel>
              <OutlinedInput
                sx={style.inputFiled}
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                id="rest"
                type="number"
                label="Rest"
                value={exerciseValue.noOfRest}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (
                    inputValue === "" ||
                    (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 99)
                  ) {
                    setExerciseValue((previous) => {
                      return { ...previous, noOfRest: inputValue };
                    });
                    setRest(inputValue === "" ? "" : parseInt(inputValue));
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} lg={0.5} xl={0.5}>
            <FormControl variant="outlined" sx={{ ...style.formControl }}>
              <InputLabel
                sx={style.label}
                style={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Active Time
              </InputLabel>
              <OutlinedInput
                sx={style.inputFiled}
                style={{
                  borderRadius: 12,
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
                id="Active Time"
                type="number"
                label="Active Time"
                value={exerciseValue.noOfTime}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (
                    inputValue === "" ||
                    (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 99)
                  ) {
                    setExerciseValue((previous) => {
                      return { ...previous, noOfTime: inputValue };
                    });
                    setTime(inputValue === "" ? "" : parseInt(inputValue));
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={1}
            md={1}
            lg={0.5}
            xl={0.5}
            sx={{ display: "flex", alignItems: "end" }}
          >
            {/* <FormControl sx={{ mt: 1 }}> */}
            <CustomCommonButton
              tooltipText="Add Exercise"
              onClick={() => {
                addSingleExcerise();
              }}
              disabled={
                !selectedGroup ||
                !activityValue ||
                !combinationValue ||
                !session ||
                !exerciseValue.noOfSet ||
                !exerciseValue.warmUpId
                // exerciseValue.noOfSet <= 0
              }
              variant="contained"
              icon={<PostAdd sx={{ fontSize: "40px" }} />}
            />
          </Grid>
          <Grid
            item
            xs={1}
            md={1}
            lg={0.5}
            xl={0.5}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <CustomCommonButton
              tooltipText="Add Preparation"
              onClick={handlePreparationAdd}
              disabled={
                !selectedGroup ||
                !activityValue ||
                !combinationValue ||
                !session
              }
              icon={<PlaylistAddIcon sx={{ fontSize: "40px" }} />}
            />
          </Grid>
        </Grid>
        <GridTable
          rows={rows}
          columns={GetData.assignPreparationData}
          height={"63vh"}
        />
        {contextHolder}
      </Box>
    </>
  );
};
