import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Login } from "./auth/Login";
import UserAnalytics from "./component/Admin/UserAnalytics";
import UserCreation from "./component/Admin/UserCreation";
import UserManipulation from "./component/Admin/UserManipulation";
import UserMigration from "./component/Admin/UserMigration";
import PlayerAchivements from "./component/Player/PlayerAchivements";
import PlayerInfluencers from "./component/Player/PlayerInfluencers";
import { PlayerPerformance } from "./component/Player/PlayerPerformance";
import { PlayerPreparation } from "./component/Player/PlayerPreparation";
import AcademyAdd from "./component/SuperAdmin/AcademyAdd";
import AcademyAnalytic from "./component/SuperAdmin/AcademyAnalytic";
import { AcademyEdit } from "./component/SuperAdmin/AcademyEdit";
import { AcademyList } from "./component/SuperAdmin/AcademyList";
import { EventMaster } from "./component/SuperAdmin/EventMaster";
import RecordMaster from "./component/SuperAdmin/RecordMaster";
import Accolades from "./component/coach/Accolades";
import Analytics from "./component/coach/Analyticals/Analytics";
import { AssignPreparation } from "./component/coach/AssignPreparation";
import { Combination } from "./component/coach/Combination";
import CombinationAdd from "./component/coach/CombinationAdd";
import CombinationEdit from "./component/coach/CombinationEdit";
import { Exercise } from "./component/coach/Exercise";
import Grouping from "./component/coach/Grouping";
import GroupingEdit from "./component/coach/GroupingEdit";
import Influencer from "./component/coach/Influencer";
import MedicalHistory from "./component/coach/MedicalHistory";
import { Performance } from "./component/coach/Performance";
import { PlayerGrouping } from "./component/coach/PlayerGrouping";
import { Preparation } from "./component/coach/Preparation";
import { AdminProfile } from "./component/common/AdminProfile";
import { PlayerProfile } from "./component/common/PlayerProfile";
import { PreparationView } from "./component/common/PreparationView";
import { Profile } from "./component/common/Profile";
import ZeeAppBar from "./component/common/ZeeAppBar";
import "./utils/utils";

function App() {
  return (
    <div className="root">
      <ZeeAppBar />
      <Routes>
        <Route path="/" element={<Login />} />
        {/* For Super Admin  Flow */}
        <Route path="/academyList" element={<AcademyList />} />
        <Route path="/academyAdd" element={<AcademyAdd />} />
        <Route path="/academyEdit/:academyId" element={<AcademyEdit />} />
        {/* For Admin User Flow */}

        <Route path="/userManipulation" element={<UserManipulation />} />
        <Route path="/UserMigration" element={<UserMigration />} />
        <Route path="/UserAnalytics" element={<UserAnalytics />} />
        <Route path="/adminprofile" element={<AdminProfile />} />
        <Route path="/AcademyAnalytic" element={<AcademyAnalytic />} />
        <Route path="/EventMaster" element={<EventMaster />} />
        <Route path="/playerprofile" element={<PlayerProfile />} />
        <Route path="/userCreation" element={<UserCreation />} />
        <Route path="/RecordMaster" element={<RecordMaster />} />
        {/*  For Coach User Flow */}
        <Route path="/performance" element={<Performance />} />
        <Route path="/preparation" element={<Preparation />} />
        <Route path="/Combination" element={<Combination />} />
        <Route path="/WorkourMaster" element={<Exercise />} />
        <Route path="/analytic" element={<Analytics />} />
        <Route path="/playerGrouping" element={<PlayerGrouping />} />
        <Route path="/medicalHistory" element={<MedicalHistory />} />
        <Route path="/Influencer" element={<Influencer />} />
        <Route path="/assignPreparation" element={<AssignPreparation />} />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/preparationView/:warmUpWorkoutHeaderId"
          element={<PreparationView />}
        />
        <Route path="/grouping" element={<Grouping />} />
        <Route path="/Accolades" element={<Accolades />} />
        <Route path="/groupingedit/:userGroupId" element={<GroupingEdit />} />
        <Route
          path="/CombinationEdit/:warmUpCombinationId"
          element={<CombinationEdit />}
        />
        <Route path="/CombinationAdd" element={<CombinationAdd />} />
        <Route path="/adminprofile" element={<AdminProfile />} />

        {/*  For Player User Flow */}
        <Route path="/playerPreparation" element={<PlayerPreparation />} />
        <Route path="/playerPerformance" element={<PlayerPerformance />} />
        <Route path="/playerAchivements" element={<PlayerAchivements />} />
        <Route path="/playerInfluencers" element={<PlayerInfluencers />} />
      </Routes>
    </div>
  );
}

export default App;
