import { Box, Typography } from "@mui/material";
import React from "react";
import { ChartComponent } from "../../../../Common/Components/chart/ChartComponent";
import PropTypes from "prop-types";

const PreparationTrack = ({ preparation }) => {
  // console.log(preparation);

  const actualTotal = preparation?.reduce((total, data) => {
    return total + data?.actualSet;
  }, 0);

  const assignTotal = preparation?.reduce((total, data) => {
    return total + data?.assignSet;
  }, 0);

  // Calculate percentage
  const percentage = (actualTotal / assignTotal) * 100;
  const series = [
    Number.isNaN(percentage)
      ? 0
      : percentage > 100
      ? 100
      : percentage.toFixed(2),
  ];
  const options = {
    chart: {
      height: 550,
      type: "radialBar",
      toolbar: {
        show: false, // Hide the toolbar if not needed
      },
      offsetX: 0, // Adjust the left offset
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "60%",
        },
        size: "80%",
        dataLabels: {
          style: {
            fontFamily: "var(--font-family)",
            fontSize: "var(--body-font-size)",
          },
          showOn: "always",
          name: {
            show: false,
          },
          value: {
            show: true,
            color: "var(--chart-bar-color)",
            fontSize: "34px",
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    colors: ["var(--chart-bar-color)", "#EBEBEB"],
    labels: ["Completed", "Pending"],
    legend: {
      show: false,
      position: "right",
      float: true,
      itemMargin: {
        horizontal: 10,
      },
      markers: {
        radius: 12,
      },
    },
  };

  return (
    <Box className="preparation-container">
      <Typography
        variant="h5"
        sx={{
          fontFamily: "var(--font-family)",
          fontWeight: "var(--font-weight)",
          fontSize: "var(--header-font-size)",
          // mb: 2,
        }}
      >
        Preparation
      </Typography>
      <Box sx={{display:"flex", justifyContent:"center", width:"100%"}}>
      <ChartComponent
        options={options}
        series={series}
        type={"radialBar"}
        height={300}
      />
      </Box>
      <div className="legend-container">
        <div className="legend">
          <div className="completed"></div>
          <p style={{ fontFamily: "var(--font-family)",fontSize: "var(--body-md-font-size)", marginRight:"10px"}}>Completed</p>
        </div>
        <div className="legend">
          <div className="inprogress"></div>
          <p style={{ fontFamily: "var(--font-family)",fontSize: "var(--body-md-font-size)", }}>Pending</p>
        </div>
      </div>
    </Box>
  );
};

export default PreparationTrack;

PreparationTrack.propTypes = {
  preparation: PropTypes.array.isRequired,
};
