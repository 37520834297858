import { Box, Typography } from "@mui/material";
import React from "react";
import { ChartComponent } from "../../../../Common/Components/chart/ChartComponent";
import Extension from "../../../../Common/Extension";

const MoodLevelTracking = ({ influence }) => {
  const series = [
    {
      name: "Mood",
      data: influence?.map((data) => parseInt(data.moodLevel)),
    },
  ];
  console.log(series);
  const chartOption = {
    series: series,
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 0.5,
      },
      markers: {
        size: 5,
      },
      colors: ["var(--chart-bar-color)", "var(--chart-dark-color)"],
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        fontFamily: "var(--font-family)",
        fontSize: "var(--font-size)",
        color: "var(--font-color)", // Legend item color
        labels: {
          colors: "var(--font-color)",
          useSeriesColors: false,
        },
        itemMargin: {
          horizontal: 20,
        },
        itemTextTransform: "rotate-45",
      },
      yaxis: {
        min: 10,
        // max: 10,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      xaxis: {
        categories: influence?.map((data) =>
          Extension.convertDateFormatToDMY(data.createdDate)
        ),
      },
      noData: {
        text: "There Is No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "black",
          fontSize: "18px",
          fontFamily: "var(--font-family)",
        },
      },
    },
  };

  return (
    <Box className="biometric-container">
      <Typography
        variant="h5"
        sx={{
          fontFamily: "var(--font-family)",
          fontWeight: "var(--font-weight)",
          fontSize: "var(--header-font-size)",
          mb: 3,
        }}
      >
        Mood Level Tracking
      </Typography>
      <ChartComponent
        options={chartOption.options}
        series={chartOption.series}
        type={"line"}
        height={230}
      />
    </Box>
  );
};

export default MoodLevelTracking;
