const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// export const AppbarColor = local_theme === "light" ? "#000000" : "#3C5D90";
export const AppbarColor = "#0C2244";
export const auction_color = "#FF5E5B";
export const scheme_expiry_color = "#82642C";

export const smallFont = "12px";
export const sherifFont = "";
export const cambriaFont = "cambria";
export const ArialFont = "sands";
export const boldFont = 800;
class Styles {
  popupStyle() {
    return {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      minWidth: 400,
      // height: 460, // Adjust the height as needed
      bgcolor: "background.paper",
      // border: '2px solid #000',
      borderRadius: 3, // Add border radius to create a curved border
      clipPath: "ellipse(80% 80% at 40% 48%)", // Add a clip path for a curved background
      boxShadow: (theme) => theme.shadows[5],
      p: 5,
    };
  }
  widthPopupStyle() {
    return {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 700,
      // height: 460, // Adjust the height as needed
      bgcolor: "background.paper",
      // border: '2px solid #000',
      borderRadius: 3, // Add border radius to create a curved border
      clipPath: "ellipse(80% 80% at 40% 48%)", // Add a clip path for a curved background
      boxShadow: (theme) => theme.shadows[5],
      p: 3,
    };
  }
  commonButton() {
    return {
      mb: { md: 0, sm: 0, xs: 2 },
      color: "black",
      width: { md: "auto", sm: "auto", xs: "100%" },
      textTransform: "capitalize",
      fontFamily: "var(--font-family)",
      fontSize: "var(--font-size)",
      fontWeight: "var(--font-weight)",
      // display:'flex',
      // alignItems:'center'
    };
  }
  titleText() {
    return {
      mb: { md: 0, sm: 0, xs: 2 },
      color: "var(--app-bar-color)",
      fontWeight: "var(--font-weight)",
      fontFamily: "var(--font-family)",
      fontSize: "var(--header-font-size)",
      width: { md: "auto", sm: "auto", xs: "100%" },
    };
  }
  MenuProps() {
    return {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          alignItems: "left",
          width: 250,
          backgroundColor: "var(--text-field)",
          color: "var(--font-color)",
          fontSize: "var(--font-size)",
          fontFamily: "var(--font-family)",
        },
      },
    };
  }
  heightPaper() {
    return {
      height: "90vh",
      // maxHeight:'180vh',
      overflowY: "auto",
      p: 2,
    };
  }
  dropDown() {
    return {
      backgroundColor: "var(--text-field)",
      fontFamily: "var(--font-family)",
      fontSize: "var(--font-size)",
      color: "var(--font-color)",
    };
  }
}
const Style = new Styles();
export default Style;
