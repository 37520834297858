import { Avatar, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PlayerPNG from "../../../assets/player.png";

const PlayersContainer = ({ players, handleUserSelection }) => {
  const [selectedPlayer, setSelectedPlayer] = useState("");

  const handleClick = useCallback(
    (e) => {
      setSelectedPlayer(e);
      handleUserSelection(e);
    },
    [handleUserSelection]
  );

  useEffect(() => {
    if (Array.isArray(players) && players.length > 0 && !selectedPlayer) {
      handleClick(players[0]?.userId);
      // setSelectedPlayer(players[0]);
      handleUserSelection(players[0]);
    }
  }, [handleClick, players, selectedPlayer]);

  function replaceNameWithInitials(input) {
    // Use a regular expression to find the text within curly braces
    return input.replace(/{([^}]+)}/g, function (match, name) {
      // Split the name into words
      const words = name.trim().split(/\s+/);
      // Get the initials from the words
      const initials = words.map((word) => word.charAt(0)).join("");
      // Return the initials wrapped in parentheses
      return `${initials}`;
    });
  }

  return (
    <div className="player-container">
      {Array.isArray(players) && players.length <= 0 && (
        <div className="images">
          <Avatar
            alt="No Img"
            src="NI"
            className="player-image"
            sx={{
              height: 85,
              width: 85,
            }}
          />
          <p>No Players</p>
          <p>Assigned Yet</p>
        </div>
      )}
      {players?.map((item, index) => (
        <div className="images" key={item.userId}>
          <Avatar
            alt={replaceNameWithInitials(item.userName)}
            src={replaceNameWithInitials(item.userName)}
            className={
              item === selectedPlayer ? "player-selected" : "player-image"
            }
            sx={{
              height: 85,
              width: 85,
              border:
                parseInt(selectedPlayer) === parseInt(item.userId) &&
                "2px solid rgb(32, 240, 9)",
            }}
            onClick={() => handleClick(item)}
          />
          <p>{item.loginId}</p>
          <Tooltip title={item.userName} placement="top">
            <p className="cut-text">{item.userName}</p>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default PlayersContainer;
