import { MoreHoriz } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useCallback, useEffect, useState } from "react";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";
import ToastMessage from "../../Common/Components/ToastMessage";
import Extension from "../../Common/Extension";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import "./Styles/AcademyCoach.css";

export default function AcademyPlayers({ query, handlePlayerProfile, value }) {
  const [posts, setPosts] = useState([]);
  const [status, setStatus] = useState("all");
  const [open, setOpen] = React.useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [coaches, setCoaches] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState("");
  const { toast, contextHolder } = ToastMessage();

  const academyId = parseInt(sessionStorage.getItem("academyId"));
  const userId = parseInt(sessionStorage.getItem("userId"));

  const getCoach = useCallback(async () => {
    try {
      // toast("loading", "Loading...");
      await api
        .get("users/academy/player/" + academyId)
        .then((response) => {
          setPosts(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log("Error", err);
    }
  }, [academyId]);

  useEffect(() => {
    getCoach();
  }, [getCoach]);

  const updateValue = async (post) => {
    if (!selectedPlayer.active && !selectedCoach) {
      toast("error", "Kindly select a coach before active the player");
      return;
    }
    toast("loading", "Loading...");
    const selected_player = await api
      .get(`users/${selectedPlayer.userId}`)
      .then((res) => res.data)
      .catch((err) => {});
    const data = {
      ...selected_player,
      coachId: !selectedPlayer.active ? selectedCoach : 0,
      active: !selectedPlayer.active,
      modifyDate: Extension.getCurrentSQLDate(),
      exitingDate: selectedPlayer.active ? Extension.getCurrentSQLDate() : null,
    };
    // console.log(data);
    await api
      .put(`users/${data.userId}`, data)
      .catch((err) => console.error(err));
    if (selectedPlayer.active) {
      const assignRes = await api
        .get(`/playerAssign/player/isactive/${data.userId}`)
        .then((res) => res.data)
        .catch((err) => console.error(err));
      const assignData = {
        ...assignRes,
        endDate: Extension.getCurrentSQLDate(),
        active: !selectedPlayer.active,
        modifiedBy: userId,
        modifiedDate: Extension.getCurrentSQLDate(),
      };
      // console.log(assignData);

      await api
        .put(`/playerAssign/${assignData?.playerAssignDetailId}`, assignData)
        .catch((err) => console.error(err));
    }
    if (!selectedPlayer.active) {
      const assignData = {
        playerId: selectedPlayer.userId,
        coachId: selectedCoach,
        startDate: Extension.getCurrentSQLDate(),
        active: !selectedPlayer.active,
        academyId: academyId,
        createdBy: userId,
        createdDate: Extension.getCurrentSQLDate(),
        modifiedBy: userId,
        modifiedDate: Extension.getCurrentSQLDate(),
      };
      await api
        .post(`/playerAssign/add`, assignData)
        .catch((err) => console.error(err));
    }
    getCoach();
    handleClosePopup();
    setSelectedCoach("");
    toast(
      "success",
      `Player ${
        selectedPlayer.active ? "Terminated" : "Activated"
      } Successfully`
    );
  };

  const makeStyle = (active) => {
    if (active === true) {
      return {
        cursor: "pointer",
        minWidth: 100,
        background: "rgb(145 254 159 / 47%)",
        color: "green",
      };
    } else {
      return {
        cursor: "pointer",
        minWidth: 100,
        background: "#ffadad8f",
        color: "red",
      };
    }
  };

  const handleOpenPopup = async (post) => {
    setSelectedPlayer(post);
    setOpen(true);
  };

  const handleClosePopup = () => {
    setSelectedPlayer({});
    setOpen(false);
  };

  const handleShowPlayerProfile = (data) => {
    handlePlayerProfile(true, data);
  };

  const filteredRows = posts.filter(
    (item) =>
      (item.userName.toLowerCase().includes(query) ||
        item.userId.toString().includes(query)) &&
      (status === "all" || item.active === status)
  );
console.log(posts);
// console.log(coaches);
  useEffect(() => {
    const getUsers = async () => {
      await api
        .get(`/users/academy/coach/active/${academyId}`)
        .then((response) => {
          setCoaches(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUsers();
  }, [academyId]);

  const playerRows = filteredRows.map((post, index) => {
    const date = new Date(post.createDate);
    const reqdate =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    return {
      id: index + 1,
      loginId: post.loginId,
      name: post.userName,
      joinedDate: reqdate,
      coachAssigned:
        coaches.length > 0
          ? coaches?.find((item) => item.userId === post.coachId)?.userName ||
            "--"
          : coaches.userName || "--",
      moreInfo: <MoreHoriz onClick={() => handleShowPlayerProfile(post)} />,
      status: (
        <button
          className="status"
          onClick={() => handleOpenPopup(post)}
          style={makeStyle(post.active)}
        >
          {post.active ? "Active" : "Terminated"}
        </button>
      ),
    };
  });

  const newColumn = [
    {
      headerClassName: "thead",
      field: "status",
      headerName: (
        <div className="rowheader">
          {/* <p>Status</p> */}
          <FormControl variant="standard" sx={{ minWidth: 40 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              style={{
                color: "white",
                fontFamily: "var(--font-family)",
                fontWeight: "bold",
              }}
            >
              <MenuItem
                style={{ fontFamily: "var(--font-family)" }}
                value={"all"}
              >
                All Status
              </MenuItem>
              <MenuItem
                style={{ fontFamily: "var(--font-family)" }}
                value={true}
              >
                Active
              </MenuItem>
              <MenuItem
                style={{ fontFamily: "var(--font-family)" }}
                value={false}
              >
                Terminated
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      ),
      width: 180,
      renderCell: (param) => param.row.status,
    },
    {
      headerClassName: "thead",
      field: "moreInfo",
      headerName: "More Info",
      width: 150,
      renderCell: (param) => param.row.moreInfo,
    },
  ];

  const playerColumns = [...GetData.playerColumns, ...newColumn];

  return (
    <>
      {contextHolder}
      <GridTable rows={playerRows} columns={playerColumns} />

      <CommonAlertPopup
        confirmButtonText={"Yes"}
        cancelButtonText={"No"}
        titleText={<p>Confirmation</p>}
        contentText={
          !selectedPlayer.active ? (
            <p>
              Kindly select a coach before{" "}
              <b>{!selectedPlayer.active ? "active" : "terminate"}</b> the
              player
              <br />
              <div className="dropdown-style">
                <CommonDropdown
                  label={"Select A Coach"}
                  value={selectedCoach}
                  onChange={(e) => setSelectedCoach(e.target.value)}
                  array={coaches}
                  menuValue={"userId"}
                  display={"userName"}
                />
              </div>
              Do You Want to Assign <b>{selectedPlayer?.userName}</b> to the
              coach?{" "}
              <b>
                {
                  coaches?.find((item) => item?.userId === selectedCoach)
                    ?.userName
                }
                ?
              </b>
            </p>
          ) : (
            <p>
              Do You Want to{" "}
              <b>{!selectedPlayer?.active ? "active" : "terminate"}</b> the
              player <b>{selectedPlayer?.userName}?</b>
            </p>
          )
        }
        handleYes={updateValue}
        handleNo={handleClosePopup}
        open={open}
        handleClose={handleClosePopup}
      />
    </>
  );
}
