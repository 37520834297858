// import React from 'react';
// import { Doughnut } from 'react-chartjs-2';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// ChartJS.register(ArcElement, Tooltip, Legend);

// const EnergyChart = ({ energyLevel }) => {
//   const filledPercentage = Math.min(Math.max(energyLevel, 0), 100);
//   const remainingPercentage = 100 - filledPercentage;

//   const data = {
//     labels: ["",'Energy Level'],
//     datasets: [
//       {
//         data: [100 - filledPercentage, filledPercentage],
//         backgroundColor: ['transparent', '#1a90ff'],
//         hoverBackgroundColor: ['transparent', 'rgb(26 115 232)'],
//         borderColor: ['transparent', 'rgb(26 115 232 / 23%)'],
//         borderWidth: 10,
//       },
//     ],
//   };

//   const options = {
//     cutout: '60%',
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         display: false,
//       },
//       tooltip: {
//         enabled: true,
//       },
//       title: {
//         display: true,
//         text: `Energy ${filledPercentage}%`,
//         position: 'bottom',
//         font: {
//           size: 16,
//           weight: 'bold',
//         },
//       },
//     },
//     elements: {
//       center: {
//         display: true,
//         text: `${filledPercentage}%`,
//         color: 'black',
//         fontStyle: 'Arial',
//         font: {
//           size: 16,
//           weight: 'bold',
//         },
//       },
//       arc: {
//         borderRadius: 20,
//         backgroundColor: 'rgba(128, 128, 128, 0.3)',
//         boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
//       },
//     },
//   };

//   return (
//     <>
//       <Doughnut data={data} options={options} />
//     </>
//   );
// };

// export default EnergyChart;


import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const EnergyChart = ({ energyLevel }) => {
  const filledPercentage = Math.min(Math.max(energyLevel, 0), 100);
  const remainingPercentage = 100 - filledPercentage;
  const data = {
    labels: ["",'Energy Level'],
    datasets: [
      {
        data: [100 - filledPercentage, filledPercentage],
        backgroundColor: ['transparent', '#012a4a'],
        hoverBackgroundColor: ['transparent', '#012a4a'],
        borderColor: ['#012a4ab3', '#012a4ab3'],
        // borderRadius: [0,20],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: '60%',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      title: {
        display: true,
        text: `Energy ${filledPercentage}%`,
        position: 'bottom',
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
    elements: {
      center: {
        display: true,
        text: `${filledPercentage}%`,
        color: 'black',
        fontStyle: 'Arial',
        font: {
          size: 16,
          weight: 'bold',
        },
      },
      arc: {
        // borderRadius: 20, // Set the desired border radius
        // borderColor: 'rgba(128, 128, 128, 0.3)', // Set the desired color for the box shadow
        // backgroundColor: 'rgba(0, 0, 0, 0)',
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
      },
    },
  };

  return (
    <>
      <Doughnut data={data} options={options} />
    </>
  );
};

export default EnergyChart;
